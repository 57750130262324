import { Component, Input, OnInit, OnDestroy, HostListener, TemplateRef, ChangeDetectorRef } from '@angular/core';
import { DataService } from '../../data.service';
import { TranslateService } from '../../translate.service';
import { BFService } from '../bench-feedback.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import * as _ from 'lodash';

@Component({
	selector: 'result-page',
	templateUrl: './result.component.html',
	providers: [BsModalService, BsModalRef]
})
export class ResultPage implements OnInit, OnDestroy {
	@Input() locked;
	behaviorCount: number = 0;
	currentBehavior: number = 0;
	currentRound: number = 0;
	behaviours: Array<any> = null;
	// questions: Array<any> = null;
	isNotified:boolean = false;
	isAddMore:boolean = false;
	isDone:boolean = false;
	isResult:boolean = false;
	isLoading: boolean = true;
	rList:any = [{
		"name": "",
		"email": "",
		"role": "",
		"link": ""
	}];
	delRowNo:number = null;
	modalRef: BsModalRef;
	config = {
		backdrop: false,
		ignoreBackdropClick: true
	};
	totRes:number = 0;
	totRespondents:number = 0;
	totLM: number = 0;
	totOther: number = 0;
	lastNotify:any = {
		"date": null,
		"days": null,
		"msg": "",
		"show": true
	}
	activity:any = null;
	respondents:any = null;
	isMobileView: boolean = false;
	defaultRows: any = 3;
	// commentsData: any = [];
	averageRating: number = 0;
	showComments: any = [false, false, false];
	resultData: any = null;
	resultLn1: any = "";
	resultLn2: any = "";

	@HostListener('window:resize', ['$event'])
	onResize(event) {
		this.isMobileView = window.innerWidth <= 767 ? true : false;
	}

	constructor(
		public dataService: DataService, 
		private ts: TranslateService, 
		public bfs: BFService, 
		private modalService: BsModalService,
		private chdRef: ChangeDetectorRef)
	{
		//
	}

	async ngOnInit() {
		const tDate = new Date();

		if(!this.dataService.popup.data.activity || !this.dataService.coachee) return;

		this.bfs.rList_name = [];
		this.bfs.rList_email = [];
		this.bfs.rList_role = [];

		this.dataService.popup.bfbkStep = 5;
		this.dataService.popup.buttons.show1 = false;
		this.dataService.popup.buttons.show2 = false;
		this.dataService.popup.buttons.disabled = false;
		this.lastNotify.date = this.dataService.popup.data.activity.lastNotified;
		this.currentRound = this.dataService.popup.data.activity.round;

		if(this.lastNotify.date) {
			this.lastNotify.days = this.dataService.dateDiff_inDays(this.lastNotify.date, tDate);

			if(this.lastNotify.days == 0) {
				this.lastNotify.show = false;
				this.lastNotify.msg = this.ts.translateMe('notisent_today');
			} else if(this.lastNotify.days > 0) {
				this.lastNotify.show = true;
				this.lastNotify.msg = this.ts.translateMe('lastnoti_sent') + ' ' + this.lastNotify.days + ' ' + this.ts.translateMe('days_ago');
			} else {
				this.lastNotify.show = true;
				this.lastNotify.msg = this.ts.translateMe('notinot_sent');
			}
		} else {
			this.lastNotify.show = true;
			this.lastNotify.msg = this.ts.translateMe('notinot_sent');
		}

		if(this.dataService.popup.data.activity.complete == 'Completed') {
			this.lastNotify.msg = this.ts.translateMe('notinot_allowed');
		}

		if(this.dataService.popup && this.dataService.popup.data) {
			this.activity = this.dataService.popup.data.activity;
			this.respondents = this.activity.respondents;
			// this.rList = this.respondents ? this.respondents.list : [];
		}

		this.resultData = this.getAnonymisedResults().then((res)=>{
			let result: any = res;
			this.isLoading = false;

			if(result.behaviours) {
				this.behaviours = result.behaviours;
				result.behaviours[this.currentBehavior].show = true;
				this.behaviorCount = result.behaviours.length;

				if (result.status) {
					this.totLM = result.status.lm_response_count || 0;
					this.totOther = result.status.other_response_count || 0;
					this.totRes = result.status.total_response_count || 0;
					this.totRespondents = result.status.respondent_count || 0;

					let isLocked: boolean = this.dataService.popup.data.activity.locked;

					if(!this.isResult) {
						this.isResult = isLocked && (this.totRes >= this.dataService.minRespondents);
					}

					this.resultLn1 = this.ts.translateMe('resultLn1');
					this.resultLn2 = this.ts.translateMe('resultLn2');

					this.resultLn1 = this.resultLn1.replace('#lm_cnt#', this.totLM);
					this.resultLn1 = this.resultLn1.replace('#othr_cnt#', this.totOther);

					this.resultLn1 = this.resultLn1.replace('#resp_cnt#', this.totRes);
					this.resultLn1 = this.resultLn1.replace('#tot_resp#', this.totRespondents);

					this.resultLn2 = this.resultLn2.replace('#min_resp#', this.dataService.minRespondents);
				}

				if (this.currentRound == 1) {
					for (let i = 0; i < this.behaviours.length; i++) {
						for (let j = 0; j < this.behaviours[i].rounds.length; j++) {
							let dbRound: any = result.behaviours[i].rounds[j];

							if (parseInt(dbRound.round_number) == this.currentRound) {
								this.behaviours[i].rounds = [dbRound];
							}
						}
					}
				}

				this.prepareChartSeriesData(this.behaviours);				
			}

			if(this.chdRef) {
				this.chdRef.detectChanges();
			}
		});
	}

	getAnonymisedResults() {
		return new Promise(resolve => {
			this.dataService.getAnonymisedResults(this.activity.id, this.currentRound)
			.subscribe(response => {
				resolve(response);
			});
		});
	}


	checkRespondents() {}

	add_respondents() {
		this.isAddMore = true;
		this.isDone = false;
		// this.dataService.popup.buttons.disabled = true;
		// this.dataService.popup.buttons.show1 = false;
		// this.dataService.popup.buttons.show2 = false;

		setTimeout(function() {
			let amrBox: any = document.getElementsByClassName("bfb__body bfb__body--normal")[0];
			amrBox.scrollTop = 270;
		});
	}

	addMore() {
		this.isDone = false;
		this.rList.push({
			"name": "",
			"email": "",
			"role": "",
			"link": ""
		});

		let idx:number = this.bfs.rList_name.length;
		this.bfs.rList_name[idx] = this.rList[idx].name;
		this.bfs.rList_email[idx] = this.rList[idx].email;
		this.bfs.rList_role[idx] = this.rList[idx].role;

		setTimeout(function(){
			const lstbox: any = document.getElementsByClassName('result-addMore__box')[0];
			lstbox.scrollTo(0, lstbox.scrollHeight);			
		});
	}

	do_viewLock() {
		var jsonData:any = {
			"activityId": this.dataService.popup.data.activity.id,
			"authToken": this.dataService.authToken
		}

		this.dataService.lockView(jsonData).subscribe((result) => {
			this.isResult = true;
			this.ngOnInit();
			// this.dataService.emitData('save-proceed', this.dataService.popup.data.activity);
		},
		error => {
			//console.log(error);
		});
	}

	saveRespondent() {
		var resp:any = [];

		for(var i = 0; i < this.bfs.rList_name.length; i++) {
			if(this.bfs.rList_name[i] && this.bfs.rList_email[i] && this.bfs.rList_role[i]) {
				resp.push({
					"name": this.bfs.rList_name[i], 
					"email": this.bfs.rList_email[i], 
					"role": this.bfs.rList_role[i],
					"link": null
				});
			}
		}

		var jsonData:any = {
			"activityId": this.dataService.popup.data.activity.id,
			"authToken": this.dataService.authToken,
			"submitted": true,
			"respondents": resp
		}

		// console.log(jsonData);
		this.dataService.saveMoreRespondents(jsonData).subscribe((result) => {
			this.dataService.popup.data.activity = result;
			this.activity = result;

			this.rList = [{
				"name": "",
				"email": "",
				"role": "",
				"link": ""
			}];

			this.bfs.rList_name = [];
			this.bfs.rList_email = [];
			this.bfs.rList_role = [];

			this.respondents = this.activity.respondents;
			this.totRespondents = this.respondents.list.length;
			this.isDone = true;

			setTimeout(function() {
				this.isDone = false;
			}.bind(this), 3000);
		},
		error => {
			// console.log(error);
			this.isDone = false;
		});
	}

	// showComments(round: any) {
	// 	round.show = !round.show;
	// }

	getCommentCnts(comments:any) {
		let cmtcnt:number = 0;
		
		for(let i=0; i<comments.length; i++) {
			if(comments[i].comment) {
				cmtcnt++;
			}
		}

		return cmtcnt;
	}

	prepareChartSeriesData(resultData: any) {
		if (resultData && resultData.length > 0) {
			for (let i = 0; i < resultData.length; i++) {
				if (resultData[i].rounds && resultData[i].rounds.length > 0) {
					for (let j = 0; j < resultData[i].rounds.length; j++) {
						let roundData = resultData[i].rounds[j].scores;
						let lmData:any = [];
						let selfData:any = [];

						if (roundData) {
							let orgRating = Object.assign([], roundData.other);

							if(!orgRating || orgRating.length==0) {
								orgRating = [0];
							}

							roundData.line_manager.forEach(function(lmd, idx) {
								lmData.push([0, parseInt(lmd)]);
							});

							if(roundData.self) {
								roundData.self.forEach(function(slf, idx) {
									selfData.push([0, parseInt(slf)]);
								});
							}

							for (let i = 0; i < orgRating.length; i++) {
								orgRating[i] = typeof(orgRating[i])=="string" ? parseInt(orgRating[i]) : orgRating[i];
							}

							roundData.originalRatings = Object.assign([], roundData.other);
							roundData.averageRating = (_.sum(orgRating) / _.size(orgRating)).toFixed(1);
							roundData.highestRating = _.max(orgRating);
							roundData.lowestRating = _.min(orgRating);
							orgRating.splice(_.indexOf(orgRating, roundData.highestRating), 1);
							orgRating.splice(_.indexOf(orgRating, roundData.lowestRating), 1);
							roundData.secondHighestRating = _.max(orgRating);
							roundData.secondLowestRating = _.min(orgRating);

							let seriesData = [{
								'name': this.ts.translateMe('allRespondents'),
								'data': [[
									roundData.lowestRating,
									roundData.secondLowestRating,
									roundData.averageRating,
									roundData.secondHighestRating,
									roundData.highestRating
								]]
							}, {
								'name': this.ts.translateMe('lineManager'),
								'data': lmData
							}, {
								'name': this.ts.translateMe('Self'),
								'data': selfData,
								'color': '#69c113',
								'type': 'scatter'
							}];

							roundData.chartSeriesData = seriesData;
						}
					}
				}
			}
		}
	}

	showPreviousBehavior() {
		this.currentBehavior--;
		for (let i = 0; i < this.behaviours.length; i++) {
			this.behaviours[i].show = false;
		}
		this.behaviours[this.currentBehavior].show = true;
	}

	showNextBehavior() {
		this.currentBehavior++;
		for (let i = 0; i < this.behaviours.length; i++) {
			this.behaviours[i].show = false;
		}
		this.behaviours[this.currentBehavior].show = true;
	}

	do_notify() {
		if(this.lastNotify.days == 0 || this.dataService.popup.data.activity.complete == 'Completed') return false;

		var jsonData:any = {
			"activityId": this.dataService.popup.data.activity.id,
			"authToken": this.dataService.authToken
		}

		// console.log(jsonData);
		this.dataService.notifyRespondents(jsonData).subscribe((result) => {
			this.isNotified = true;
		},
		error => {
			// console.log(error);
		});
	}

	ngOnDestroy() {
		this.currentBehavior = 0;

		if (this.behaviours && this.behaviours.length > 0) {
			for (let i = 0; i < this.behaviours.length; i++) {
				this.behaviours[i].show = false;
				if (this.behaviours[i].rounds && this.behaviours[i].rounds.length > 0) {
					for (let j = 0; j < this.behaviours[i].rounds.length; j++) {
						this.behaviours[i].rounds[j].show = false;
					}
				}
			}
			
			this.behaviours[this.currentBehavior].show = true;
		}
	}
}