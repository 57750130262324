import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { DataService } from '../../data.service';
import { BFService } from '../bench-feedback.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

declare var alertify:any;

@Component({
	selector: 'identify-page',
	templateUrl: './ir.component.html',
	providers: [BsModalService, BsModalRef]
})
export class IdentifyRespondent implements OnInit {
	activity:any = null;
	respondents:any = null;
	rList:any = [];
	defaultRows: number = 5;
	showTestForm:boolean = false;
	oneLineMgr: boolean = false;
	delApiCall:boolean = false;

	@Input() locked;
	
	modalRef: BsModalRef;
	message: string;
	config = {
		backdrop: false,
		ignoreBackdropClick: true
	};
	delRowNo:number = null;

	constructor(public dataService: DataService, public bfs: BFService, private modalService: BsModalService) {
		//
	}

	ngOnInit() {
		var self = this;
		this.dataService.popup.bfbkStep = 2;
		this.dataService.saveStatus = null;

		if(this.locked) {
			this.dataService.popup.buttons.show1 = false;
			this.dataService.popup.buttons.show2 = false;
		} else {
			this.dataService.popup.buttons.show1 = true;
			this.dataService.popup.buttons.show2 = true;
		}

		this.bfs.rList_name = [];
		this.bfs.rList_email = [];
		this.bfs.rList_role = [];

		if(this.dataService.popup && this.dataService.popup.data) {
			this.activity = this.dataService.popup.data.activity;
			this.respondents = this.activity.respondents;
			this.rList = this.respondents ? this.respondents.list : [];
		}

		for(var i=0; i<this.rList.length; i++) {
			this.delApiCall = true;
			this.bfs.rList_name[i] = this.rList[i].name;
			this.bfs.rList_email[i] = this.rList[i].email;
			this.bfs.rList_role[i] = this.rList[i].role;
		}

		if(this.rList.length == 0) {
			for(var i=0; i<this.defaultRows; i++) {
				this.rList.push({
					"name": "",
					"email": "",
					"role": "",
					"link": ""
				})
			}
		} else if(this.rList.length < this.defaultRows) {
			for(var z=this.rList.length; z<this.defaultRows; z++) {
				this.rList.push({
					"name": "",
					"email": "",
					"role": "",
					"link": ""
				})
			}
		}

		if(!this.locked) {
			setTimeout(function(){
				self.checkRespondents();
			}, 500);
		}
	}

	checkRespondents() {
		let inValid:boolean = false;

		this.oneLineMgr = false;
		this.dataService.popup.buttons.disabled = true;
		
		for(var i = 0; i < this.bfs.rList_name.length; i++) {
			this.bfs.rList_name[i] = this.dataService.cleanInputs(this.bfs.rList_name[i]);
			this.bfs.rList_email[i] = this.dataService.cleanInputs(this.bfs.rList_email[i]);

			if((this.bfs.rList_name[i]=='' || this.bfs.rList_email[i]=='' || this.bfs.rList_role[i]=='') ||
				(!this.bfs.rList_name[i] || !this.bfs.rList_email[i] || !this.bfs.rList_role[i])) {
				//this.dataService.popup.buttons.disabled = true;
				//return false;
				inValid = true
			}

			if(this.bfs.rList_role[i] == 'LineManager') {
				this.oneLineMgr = true;
			}
		}

		var valueArr = this.bfs.rList_email.map(function(item){ return item });
		var isDuplicate = valueArr.some(function(item, idx){ 
			return valueArr.indexOf(item) != idx 
		});

        // let totRes = this.bfs.rList_email.length;
		// Required min. 5 respondents other than LineManager
		if((this.bfs.rList_name.length < 5) || !this.oneLineMgr || inValid || isDuplicate) {
			this.dataService.popup.buttons.disabled = true;
		} else {
			this.dataService.popup.buttons.disabled = false;
		}
	}

	addMore() {
		this.rList.push({
			"name": "",
			"email": "",
			"role": "",
			"link": ""
		});

		let idx:number = this.bfs.rList_name.length;
		this.bfs.rList_name[idx] = "";
		this.bfs.rList_email[idx] = "";
		this.bfs.rList_role[idx] = "";

		this.checkRespondents();
	}

	delRespondent() {
		let rowNo:number = this.delRowNo;

		if(this.delApiCall) {
			var jsonData = {
				"activityId": this.activity.id,
				"authToken": this.dataService.authToken,
				"email": this.bfs.rList_email[rowNo]
			}

			this.dataService.delRespondent(jsonData).subscribe();
		}

		this.bfs.rList_name.splice(rowNo, 1);
		this.bfs.rList_email.splice(rowNo, 1);
		this.bfs.rList_role.splice(rowNo, 1);

		if(this.rList.length>5) {
			this.rList.splice(rowNo, 1);
		}

		this.delRowNo = null;
		this.checkRespondents();
	}

	confirmDelete(rowNo:number, template: TemplateRef<any>) {
		this.delRowNo = rowNo;

		if(!this.bfs.rList_name[rowNo] && !this.bfs.rList_email[rowNo]) {
			this.delApiCall = false;
			this.delRespondent();
		} else {
			this.modalRef = this.modalService.show(template, this.config);
		}
	}

	confirm(): void {
		this.delRespondent();
		this.modalRef.hide();
	}

	decline(): void {
		this.delRowNo = null;
		this.modalRef.hide();
	}
}