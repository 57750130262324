import { Component, Input, OnInit, asNativeElements } from '@angular/core';
import { DataService } from '../../../data.service';
import { LOAD_NEW_PATHWAY, TimelineComponent } from '../timeline.component';
import { TLService } from '../timeline.service';
import { TranslateService } from '../../../translate.service';

import * as moment from 'moment';
import * as momentTimezone from 'moment-timezone';
import * as _ from 'lodash';

import { first } from 'rxjs/operators';
import { TranslatePipe } from '../../../translate.pipe';
import { time } from 'highcharts';

declare var jQuery: any;
declare var $: any;
declare var cordova: any;

const LOAD_NEXT_VIEW_ONLINE_SESSION = 'load-next-viewonline-session';
const LOAD_BACK_VIEW_ONLINE_SESSION = 'load-back-viewonline-session';

@Component({
  selector: 'app-timeline-links',
  templateUrl: 'hyper-links.component.html',
  providers: [TranslatePipe]
})
export class HyperLinksComponent implements OnInit {
  @Input() s;
  @Input() glinks: boolean;
  @Input() allActivities: any;
  @Input() groups: boolean;
  viewOnlineSessions: Array<any> = [];
  groupOnlineSessionIndex = 0;
  session: any;

  constructor(public dataService: DataService, public tls: TLService, private timeLineComp: TimelineComponent,
    private translatePipe: TranslatePipe, private ts: TranslateService) {
  }

  ngOnInit() {
    this.dataService.getEmitter(LOAD_NEXT_VIEW_ONLINE_SESSION).subscribe((session: any) => {
      this.nextViewOnlineClicked(session);
    });

    this.dataService.getEmitter(LOAD_BACK_VIEW_ONLINE_SESSION).subscribe((session: any) => {
      this.backViewOnlineClicked(session);
    });
  }

  calcTimeUntil(timeUntil: any) {
    const currentDate = moment();
    const actualDate = moment(timeUntil);
    if (actualDate > currentDate) {
      return actualDate.format('DD MMM, YYYY');
    }
    return false;
  }

  calcTimeToFrom(timeFrom: any, timeTo: any) {
    if (timeTo && timeFrom) {
      return moment(timeFrom).format('DD MMM, YYYY') + '-' + moment(timeTo).format('DD MMM, YYYY');
    } else if (timeTo && timeFrom === null) {
      return moment(timeTo).format('DD MMM, YYYY');
    } else if (timeTo === null && timeFrom) {
      return moment(timeFrom).format('DD MMM, YYYY');
    }
    return false;
  }

  backViewOnlineClicked(session: any) {
    if (!this.groups) {
      this.getAllViewOnlineActivities(session);
      const i = this.viewOnlineSessions.findIndex((s) => {
        if (s.id) {
          return (s.id === session.id);
        } else {
          if (s.link) {
            return (s.name === session.name && s.link === session.link);
            // } else if (s.id) {
            //     return (s.id === session.id && s.name === session.name && s.sessionType === session.sessionType);
          } else {
            return (s.name === session.name && s.sessionType === session.sessionType);
          }
        }

      });

      this.viewOnline(this.viewOnlineSessions[i - 1], false, (i - 1));
    } else {
      this.getGroupedViewOnlineIndex(session);
      this.groupOnlineSessionIndex--;
      this.viewOnline(session, false, this.groupOnlineSessionIndex);
    }
  }

  nextViewOnlineClicked(session: any) {
    if (!this.groups) {
      this.getAllViewOnlineActivities(session);
      const i = this.viewOnlineSessions.findIndex((s) => {
        if (s.id) {
          return (s.id === session.id);
        } else {
          if (s.link) {
            return (s.name === session.name && s.link === session.link);
            // } else if (s.id) {
            //     return (s.id === session.id && s.name === session.name && s.sessionType === session.sessionType);
          } else {
            return (s.name === session.name && s.sessionType === session.sessionType);
          }
        }
      });

      this.viewOnline(this.viewOnlineSessions[i + 1], false, (i + 1));
    } else {
      this.getGroupedViewOnlineIndex(session);
      this.groupOnlineSessionIndex++;
      this.viewOnline(session, false, this.groupOnlineSessionIndex);
    }
  }

  getAllViewOnlineActivities(session: any) {
    this.viewOnlineSessions = [];
    if (this.viewOnlineSessions.length === 0 && this.allActivities) {
      if (!this.groups) {
        for (let i = 0; i < this.allActivities.length; i++) {
          if (this.allActivities[i].link || this.dataService.pulseType(this.allActivities[i].sessionType, 'pc') ||
            this.dataService.pulseType(this.allActivities[i].type, 'pc') ||
            (this.dataService.pulseType(this.allActivities[i].sessionType, 'pa') && this.allActivities[i].status === 'booked')
          ) {
            this.viewOnlineSessions.push(this.allActivities[i]);
          }
        }
      }
    }
  }

  getGroupedViewOnlineIndex(session: any) {
    this.viewOnlineSessions = [];
    if (this.allActivities && this.allActivities.length > 0) {
      for (let i = 0; i < this.allActivities.length; i++) {
        const filter = this.allActivities[i].activities.filter((act: any) => {
          if (act.id) {
            return (act.id === session.id);
          } else {
            if (act.link) {
              return (act.name === session.name && act.link === session.link);
              // } else if (act.id) {
              //     return (act.id === session.id && act.name === session.name && act.sessionType === session.sessionType);
            } else {
              return (act.name === session.name && act.sessionType === session.sessionType);
            }
          }
        });

        if (filter && filter.length > 0) {
          for (let j = 0; j < this.allActivities[i].activities.length; j++) {
            if (this.allActivities[i].activities[j].link || this.dataService.pulseType(this.allActivities[i].activities[j].type, 'pc') ||
              (this.dataService.pulseType(this.allActivities[i].activities[j].type, 'ps') ||
                this.dataService.pulseType(this.allActivities[i].activities[j].type, 'pa') &&
                this.allActivities[i].activities[j].status === 'booked')) {
              this.viewOnlineSessions.push(this.allActivities[i].activities[j]);
            }
          }
        }
      }
    }

    if (this.viewOnlineSessions && this.viewOnlineSessions.length > 0) {
      for (let i = 0; i < this.viewOnlineSessions.length; i++) {
        if (this.viewOnlineSessions[i].id) {
          if (this.viewOnlineSessions[i].id === session.id) {
            this.groupOnlineSessionIndex = i;
            break;
          }
        } else {
          if (this.viewOnlineSessions[i].link && (this.viewOnlineSessions[i].name === session.name &&
            this.viewOnlineSessions[i].link === session.link)) {
            this.groupOnlineSessionIndex = i;
            break;
            // } else if (this.viewOnlineSessions[i].id && this.viewOnlineSessions[i].id === session.id &&
            // this.viewOnlineSessions[i].name === session.name && this.viewOnlineSessions[i].sessionType === session.sessionType) {
            //     this.groupOnlineSessionIndex = i;
            //     break;
          } else if (this.viewOnlineSessions[i].name === session.name && this.viewOnlineSessions[i].sessionType === session.sessionType) {
            this.groupOnlineSessionIndex = i;
            break;
          }
        }
      }
    }
  }

  openMBIR(session: any) {
    this.dataService.saveStatus = null;
    this.dataService.popup.title = 'my_ldrshift';        // Title name as per the language json key
    this.dataService.popup.type = 'my-objectives';
    this.dataService.popup.data = session;
    this.dataService.popup.view = true;
    this.dataService.popup.class = 'bs-popup-size__myObjectives';
    this.dataService.popup.footer = true;
    this.dataService.popup.buttons.caption1 = this.ts.translateMe('submit');
    this.dataService.popup.buttons.caption2 = this.ts.translateMe('cancel');
    this.dataService.popup.buttons.show1 = true;
    this.dataService.popup.buttons.show2 = true;
    this.dataService.popup.buttons.align = 'left';
    this.dataService.popup.buttons.disabled = false;
  }

  openMBIR_v2(session: any) {
    this.dataService.saveStatus = null;
    this.dataService.popup.title = 'my_ldrshift_v2';        // Title name as per the language json key
    this.dataService.popup.type = 'my-objectives_v2';
    this.dataService.popup.data = session;
    this.dataService.popup.view = true;
    this.dataService.popup.class = 'bs-popup-size__myObjectives';
    this.dataService.popup.footer = true;
    this.dataService.popup.buttons.caption1 = this.ts.translateMe('submit');
    this.dataService.popup.buttons.caption2 = this.ts.translateMe('cancel');
    this.dataService.popup.buttons.show1 = true;
    this.dataService.popup.buttons.show2 = true;
    this.dataService.popup.buttons.align = 'left';
    this.dataService.popup.buttons.disabled = false;
  }

  viewOnline(session: any, firstOpen: boolean, sessionIndex) {
    let likeCommentData: any = null;
    if (!session) {
      return;
    }

    if (firstOpen) {
      if (this.groups) {
        this.getGroupedViewOnlineIndex(session);
      } else {
        this.getAllViewOnlineActivities(session);
      }
    }

    if (sessionIndex === null || sessionIndex === undefined) {
      sessionIndex = this.viewOnlineSessions.findIndex((s) => {
      if (s.link) {
        return (s.name === session.name && s.link === session.link);
      } else if (s.id) {
        return (s.id === session.id && s.name === session.name && s.sessionType === session.sessionType);
      } else {
        return (s.name === session.name && s.sessionType === session.sessionType);
      }
      });
    }

    if (this.groups && sessionIndex !== -1) {
      session = this.viewOnlineSessions[sessionIndex];
      if (!session) {
      return;
      }
    }

    if (session.rateable) {
      return this.dataService.postRequestContentRating('getContentRating', { 'contentName': session ? session.name : '' })
      .subscribe(result => {
        const data: any = result.data;     // JSON.parse(result._body).data;
        if (data && data.length) {
        const dataByActivityId = data.filter(x => x.custom.activityId === session.id)[0];

        if (dataByActivityId && dataByActivityId.comments) {
          for (let i = 0; i < dataByActivityId.comments.length; i++) {
          dataByActivityId.comments[i].commentDate = momentTimezone.tz(dataByActivityId.comments[i].commentedOn,
            this.dataService.coachee.timezone).format('Do MMMM YYYY');
          }
        }

        if (dataByActivityId) {
          likeCommentData = {
          'likeStatus': dataByActivityId.likedStatus,
          'comments': dataByActivityId.comments
          };
        }

        }

        this.showContent(session, firstOpen, sessionIndex, likeCommentData);
      });
    } else {
      this.showContent(session, firstOpen, sessionIndex, likeCommentData);
    }
  }

  showContent(session: any, firstOpen: boolean, sessionIndex, likeCommentData: any) {
    const myFrame: any = document.getElementById('frmViewOnline');
    const sTitle: string = session.name ? session.name.trim() : '';
    const options = 'location=no,toolbarcolor=' + this.dataService.toolbarColor + ', closebuttoncaption=' +
      this.dataService.toolbarCloseText + ',closebuttoncolor=' + this.dataService.toolbarCloseColor +
      ', hideurlbar=yes, hidenavigationbuttons=yes, toolbarposition=top, toolbartranslucent=no';
    let isVideo = false;

    if (!myFrame) {
      return;
    }

    if (this.dataService.pulseType(session.type, null)) {
      if (session.cloudfrontUrl) {
      localStorage.removeItem('pulseAuthentication');
      }
      this.dataService.authenticateUser(session).subscribe(res => {
      let slink: any = '';
      const coLink = session.linkCoName ? session.linkCoName : session.coName;
      if (session.pulseServer && !session.pulseServer.endsWith('/')) {
        session.pulseServer += '/';
      }
      if (session.coName) {
        slink = session.pulseServer + 'Wizer/MyGateway/' + coLink + '?fromGateway=true';
      } else if (session.cloudfrontUrl) {
        if (res.CreateUserWithJWTResult && res.CreateUserWithJWTResult.success) {
        slink = session.cloudfrontUrl + '?token=' + res.CreateUserWithJWTResult.userToken;
        } else {
        slink = session.cloudfrontUrl;
        }
      } else {
        slink = session.pulseServer + 'Wizer/';
      }

      slink = slink.trim();

      if (slink && this.dataService.isLiveUrl(slink)) {
        if (this.dataService.isApp) {
        if (cordova) {
          const ref = cordova.InAppBrowser.open(slink, '_blank', options);
        }
        } else {
        if (session.redirectExternally) {
          myFrame.setAttribute('src', '');
          window.open(slink, '_blank');
        } else {
          // myFrame.document.writeln('Loading...');
          myFrame.setAttribute('src', slink);
        }

        this.popupModal(sTitle, slink, isVideo, session, firstOpen, sessionIndex, likeCommentData);
        }
      } else {
        alert('Invalid Request URL');
      }
      });
    } else {
      if (session.type === 'Shadow') {
      this.viewOnlineShadowActivities(session, myFrame, firstOpen, sessionIndex, likeCommentData);
      } else {
      let slink: string = session.link ? session.link.trim() : null;

      // if (slink && this.dataService.isLiveUrl(slink)) {
      if (slink.substring(0, 4) === 'www.') {
        slink = 'http://' + slink;
      }

      if (session.redirectExternally) {
        myFrame.setAttribute('src', '');
        window.open(slink, '_blank');
      }

      // we append serverUrl here
      const fileExtension = slink.split('.').pop();
      let loadSecureContentDirectly = false;

      if ((session.authenticate_user || session.authenticateUser) &&
        this.dataService.directlyLoadSecureContent.indexOf(fileExtension) !== -1) {
        loadSecureContentDirectly = true;
      }

      // Remove the multiple question mark in the url
      if (slink.indexOf('?') > -1) {
        if (this.dataService.serviceUrl) {
        slink = slink + '&serverurl=' + encodeURI(this.dataService.serviceUrl);
        if (loadSecureContentDirectly) {
          slink = slink + '&auth=' + this.dataService.getAuthentication();
        }
        } else if (loadSecureContentDirectly) {
        slink = slink + '&auth=' + this.dataService.getAuthentication();
        }
      } else {
        if (this.dataService.serviceUrl) {
        slink = slink + '?serverurl=' + encodeURI(this.dataService.serviceUrl);
        if (loadSecureContentDirectly) {
          slink = slink + '&auth=' + this.dataService.getAuthentication();
        }
        } else if (loadSecureContentDirectly) {
        slink = slink + '?auth=' + this.dataService.getAuthentication();
        }
      }

      if (slink.search('youtube.com') > 0) {
        const sl = slink.split('&');
        const tubeLink = sl[0].replace('watch?v=', 'embed/');
        myFrame.setAttribute('src', tubeLink || slink);
        myFrame.setAttribute('allow', 'autoplay; encrypted-media;');
        myFrame.setAttribute('frameborder', '0');
        myFrame.setAttribute('controls', '0');
        myFrame.setAttribute('allowfullscreen', 'true');
        isVideo = true;
      } else if (slink.search('vimeo') > 0) {
        // https://player.vimeo.com/video/268216832
        let vLink = slink;
        vLink = vLink.replace('vimeo.com', 'player.vimeo.com');
        vLink = vLink.replace('channels/staffpicks', 'video');
        myFrame.setAttribute('src', vLink);
        isVideo = true;
      } else {
        if (this.dataService.isApp) {
        if (cordova) {
          if (this.dataService.useAjaxInViewOnline) {
          this.viewOnlineMethod(myFrame, slink);
          this.popupModal(sTitle, slink, isVideo, session, firstOpen, sessionIndex, likeCommentData);
          } else {
          // myFrame.setAttribute('src', slink);
          const ref = cordova.InAppBrowser.open(slink, '_blank', options);
          }
        }
        } else {
        // code to call Lambda and set stuff
        // myFrame.setAttribute('src', slink);
        const self = this;
        if ((session.authenticate_user || session.authenticateUser) && !loadSecureContentDirectly) {
          self.viewOnlineMethod(myFrame, slink);
        } else {
          if (!session.redirectExternally) {
          // loading content in iframe without source
          if (slink.indexOf('.mp4') !== -1) {
            let videoHtml = '<video controls=\'\' controlsList=\'nodownload\' height=\'100%\' width=\'100%\'>';
            videoHtml += '<source src=\'' + slink + '\' type=\'video/mp4\'>';
            videoHtml += 'Your browser does not support the video tag. </video>';
            const iFrameDoc = myFrame.document || myFrame.contentWindow.document;
            iFrameDoc.write(videoHtml);
            iFrameDoc.close();
          } else {
            myFrame.setAttribute('src', slink);
          }
          }
        }
        }
      }

      if (!this.dataService.isApp) {
        this.popupModal(sTitle, slink, isVideo, session, firstOpen, sessionIndex, likeCommentData);
      }
      }
    }
  }

  viewOnlineShadowActivities(session: any, myFrame: any, firstOpen: boolean, sessionIndex: number, likeCommentData: any) {
    let slink = session.link;
    const shadowToken = session.token;

    this.dataService.getShadowInfo(shadowToken).subscribe((jsonData: any) => {
      if (jsonData.status === 'success') {
      slink = slink.replace(slink.substring(slink.indexOf('auth_token=')), 'auth_token=' + jsonData.authToken + '');

      myFrame.setAttribute('src', slink);

      if (!this.dataService.isApp) {
        this.popupModal(session.name, slink, false, session, firstOpen, sessionIndex, likeCommentData);
      }
      }
    });
  }

  viewOnlineMethod(myFrame, slink) {
    let iFrameDoc: any = null;
    const self: any = this;
    iFrameDoc = myFrame.document || myFrame.contentWindow.document;
    const authToken = this.dataService.getAuthentication();
    // custom code
    $.ajax({
      type: 'GET',
      url: slink,
      dataType: 'html',
      beforeSend: function (xhr, settings) {
        xhr.setRequestHeader('authorization', authToken);
      },
      complete: function (jqXHR, status, responseText) {
        // Store the response as specified by the jqXHR object
        responseText = jqXHR.statusText;
        // If successful, inject the HTML into all the matched elements
        jqXHR.done(function (r) {
          responseText = r;
          // See if a selector was specified
          iFrameDoc.write(responseText);
          iFrameDoc.close();
        });
        },
      error: function (jqXHR, textStatus, errorThrown) {
        iFrameDoc.write(errorThrown || 'Error!');
        iFrameDoc.close();
      }
    }).fail(function (jqXHR, status, responseText) {
      iFrameDoc.write(self.ts.translateMe('check_console'));
      iFrameDoc.close();
    });
  }

  popupModal(sTitle, slink, isVideo, session, firstOpen, sessionIndex, likeCommentData) {
    let showBack = true;
    let showNext = true;
    let nextTitle, backTitle;
    this.dataService.popup.title = sTitle;

    if (sessionIndex === 0) {
      showBack = false;
    }

    if (sessionIndex === this.viewOnlineSessions.length - 1) {
      showNext = false;
    }

    if (showNext) {
      nextTitle = this.viewOnlineSessions[sessionIndex + 1] ? this.viewOnlineSessions[sessionIndex + 1].name : '';
      if (window.innerWidth <= 768) {
      nextTitle = this.ts ? this.ts.translateMe('next') : 'Next';
      }
    }

    if (showBack) {
      backTitle = this.viewOnlineSessions[sessionIndex - 1] ? this.viewOnlineSessions[sessionIndex - 1].name : '';
      if (window.innerWidth <= 768) {
      backTitle = this.ts ? this.ts.translateMe('previous') : 'Prev.';
      }
    }

    this.dataService.popup.data = {
      'slink': slink,
      'session': session,
      'nextSessionTitle': showNext ? nextTitle : null,
      'previousSessionTitle': showBack ? backTitle : null,
      'rateable': session.rateable || false
    };

    this.dataService.popup.data.session.likeCommentData = likeCommentData;

    if (firstOpen) {
      this.dataService.saveStatus = null;
      this.dataService.popup.type = 'view-online';
      this.dataService.popup.view = true;
      this.dataService.popup.footer = true;
    }

    if (slink.search('.mp4') > 0) {
      this.dataService.popup.video = true;
      this.dataService.emitData('video_modalpopup', {
      isVideo: this.dataService.popup.video,
      slink: this.dataService.popup.data.slink
      });
    } else {
      this.dataService.popup.video = false;
    }

    if (isVideo || session.type === 'Audio/Video' || session.type === 'Video') {
      this.dataService.popup.class = 'bs-popup-size__viewVideo';
    } else if (session.type === 'Card Sort' || session.type === 'CardSort' || session.type === 'CardSortV2') {
      this.dataService.popup.class = 'bs-popup-size__viewCard';
    } else if (session.type === 'Shadow') {
      this.dataService.popup.class = 'bs-popup-size__viewShadowActivity';
    } else if (session.type === 'PulseContent') {
      this.dataService.popup.class = 'bs-popup-size__pcOnline';
    } else {
      this.dataService.popup.class = 'bs-popup-size__viewOnline';
    }
  }

  bookDetails(session) {
    this.dataService.saveStatus = null;
    this.dataService.popup.title = session.name;
    this.dataService.popup.type = 'booking-details';
    this.dataService.popup.data = {
      'sessionId': session.id,
      'details': session
    };
    this.dataService.popup.view = true;
    this.dataService.popup.class = 'bs-popup-size__noPathways';
    this.dataService.popup.footer = false;
    this.dataService.popup.buttons.caption1 = '';
    this.dataService.popup.buttons.caption2 = '';
    this.dataService.popup.buttons.show1 = false;
    this.dataService.popup.buttons.show2 = false;
    this.dataService.popup.buttons.align = '';
    this.dataService.popup.buttons.disabled = true;
  }

  getTimeDuration(session) {
    let duration = 0;

    if (session.hasOwnProperty('estimatedTime')) {
      duration = session.estimatedTime ? session.estimatedTime : 0;
    } else {
      duration = session.duration ? session.duration : 0;
    }

    return duration;
  }

  confirmBookingCancel(session: any) {
    this.dataService.saveStatus = null;
    this.dataService.popup.title = 'cancel_booking';        // Title name as per the language json key
    this.dataService.popup.type = 'confirm-booking-cancel';
    this.dataService.popup.data = {
      'session': session,
      'message': this.ts.translateMe('quest8')
    };
    this.dataService.popup.view = true;
    this.dataService.popup.class = 'bs-popup-size__noPathways';
    this.dataService.popup.footer = true;
    this.dataService.popup.buttons.caption1 = this.ts.translateMe('yes');
    this.dataService.popup.buttons.caption2 = this.ts.translateMe('no');
    this.dataService.popup.buttons.show1 = true;
    this.dataService.popup.buttons.show2 = true;
    this.dataService.popup.buttons.align = 'left';
    this.dataService.popup.buttons.disabled = false;
  }

  isSessionClosed(session: any) {
    if (session && session.booking && session.booking.closeDateTime) {
      const difference = momentTimezone.tz(this.dataService.coachee.timezone)
      .diff(momentTimezone.tz(session.booking.closeDateTime, this.dataService.coachee.timezone));
      return difference > 0 ? true : false;
    }
    return false;
  }

  isVirtualCalling(sType: any) {
    let retVal = false;

    if (sType === 'VirtualPhoneCallCoachingSession' ||
      sType === 'VirtualThreeWayPhoneCallCoachingSession' ||
      sType === 'Virtual Phone Call Coaching Session' ||
      sType === 'Virtual Three Way Phone Call Coaching Session') {
      retVal = true;
    }

    return retVal;
  }

  isCallingSession(sType: any) {
    let retVal = false;

    if (sType === 'PhoneCallCoachingSession' ||
      sType === 'VirtualPhoneCallCoachingSession' ||
      sType === 'ThreeWayPhoneCallCoachingSession' ||
      sType === 'VirtualThreeWayPhoneCallCoachingSession' ||
      sType === 'Phone Call Coaching Session' ||
      sType === 'Virtual Phone Call Coaching Session' ||
      sType === 'Three Way Phone Call Coaching Session' ||
      sType === 'Virtual Three Way Phone Call Coaching Session') {
      retVal = true;
    }

    return retVal;
  }

  isCoachingSession(sType: any) {
    let retVal = false;

    if (sType === 'GroupCoachingSession' ||
      sType === 'ThreeWayCoachingSession' ||
      sType === 'FaceToFaceCoachingSession' ||
      sType === 'ThreeWayFaceToFaceCoachingSession' ||
      sType === 'Group Coaching Session' ||
      sType === 'Three Way Coaching Session' ||
      sType === 'Face To Face Coaching Session' ||
      sType === 'Three Way Face To Face Coaching Session' ||
      sType === 'VirtualGroupCoachingSession' ||
      sType === 'VirtualThreeWayCoachingSession' ||
      sType === 'Virtual Group Coaching Session' ||
      sType === 'Virtual Three Way Coaching Session' ||
      sType === 'Virtual Face To Face Coaching Session'
    ) {
      retVal = true;
    }

    return retVal;
  }

  getTimeDifference(act: any) {
    let diffVal = 24;
    const bookingDate: any = this.tls.bookingDateTime(act);
    const difference = momentTimezone.tz(bookingDate, this.dataService.coachee.timezone)
    .diff(momentTimezone.tz(this.dataService.coachee.timezone), 'hours');

    if (act.type === 'PodSession' && act.sessionType === 'Open') {
      diffVal = 72;
    }

    return difference >= diffVal ? true : false;
  }

  doCancelSession(s: any) {
    if (s.status === 'Completed' || s.status === 'Booked') {
      if (s.type === 'PodSession' && s.sessionType === 'Open' && !this.dataService.isPodLeader('coachee', this.dataService.pathwayId)) {
      return this.getTimeDifference(s) ? true : false;
      } else if (s.type === 'PodSession' && s.sessionType === 'Closed' &&
      this.dataService.isPodLeader('coachee', this.dataService.pathwayId)) {
      return this.getTimeDifference(s) ? true : false;
      } else if (this.tls.bookingDateTime(s) && this.getTimeDifference(s)) {
      return true;
      } else {
      return false;
      }
    }
  }

  download(activity: any) {
    const slink: string = activity.downloadLink;
    if (slink && this.dataService.isLiveUrl(slink)) {
      // var filename = slink.replace(/^.*[\\\/]/, '');
      // slink = this.dataService.pdfUrl + filename;

      window.open(slink, '_blank');

      if (activity.id && !activity.complete) {
      this.markSessionComplete(activity);
      }
    } else {
      alert(this.ts.translateMe('invalid_dlink'));
      return false;
    }
  }

  markSessionComplete(activity: any) {
    const markStatus: boolean = !activity.complete;

    if (this.dataService.pulseType(activity.type, null)) {
      return;
    }

    this.dataService.unbookActivity(activity.id, markStatus)
    .subscribe(result => {
    activity.complete = result.activity.complete;
    },
    error => {
      // console.log(error);
    });
  }

  addToCalendar(sessionID) {
    // const icsURL = this.dataService.coachServerUrl + 'coaching_session/' + sessionID + '.ics?authToken=' + this.dataService.authToken;
    // https://SERVER/coachees/COACHEE_ID/pathway_v2s/PATHWAY_ID/coaching_session_v2s/COACHING_SESSION_ID/as_ical.ics
    let icsURL = this.dataService.coachServerUrl + 'coachees/' + this.dataService.coachee.id;
    icsURL += '/pathway_v2s/' + this.dataService.pathwayId + '/coaching_session_v2s/' + sessionID;
    icsURL += '/as_ical.ics?authToken=' + this.dataService.authToken;

    window.open(icsURL, '_blank');
  }

  openBenchFeedback(activity: any) {
    this.dataService.bfbIntroPage = true;
    this.dataService.saveStatus = null;

    this.dataService.popup.title = 'submit_bfb';        // Title name as per the language json key
    this.dataService.popup.type = 'bench-feedback';
    this.dataService.popup.data = { 'activity': activity };
    this.dataService.popup.view = true;
    this.dataService.popup.class = 'bs-popup-size__benchFeedback';
    this.dataService.popup.footer = true;
    this.dataService.popup.buttons.caption1 = this.ts.translateMe('save_proceed');
    this.dataService.popup.buttons.caption2 = this.ts.translateMe('save_draft');
    this.dataService.popup.buttons.show1 = true;
    this.dataService.popup.buttons.show2 = true;
    this.dataService.popup.buttons.align = 'left';
    this.dataService.popup.buttons.disabled = false;
  }

  podMembers(podName: string, obj: any) {
    if (obj && obj.participantDetails.length) {
      this.dataService.podMembers = {};
      const podMem: any = obj.participantDetails;
      const podMemZ: any = [];

      for (let i = 0; i < podMem.length; i++) {
      podMemZ.push({
        'id': podMem[i].userId,
        'name': podMem[i].name,
        'leader': (obj.leaderUserId === podMem[i].userId) ? true : false
      });
      }

      this.dataService.podMembers = {
      'groupName': podName,
      'data': podMemZ
      };

      this.dataService.podMembers.data = _.orderBy(this.dataService.podMembers.data, ['leader'], ['desc']);
      this.dataService.podPopup = true;
    }
  }

  viewSlots(s) {
    this.dataService.popup.viewSlots = true;
    this.tls.bookSession(s);
  }

}
