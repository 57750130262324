import { Component, OnInit, OnDestroy } from '@angular/core';
import { DataService } from './../data.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-resetpassword',
  templateUrl: './resetpassword.component.html'
})
export class ResetpasswordComponent implements OnInit, OnDestroy {
  model: any = {};
  resetToken: any;
  loginResponse: any;
  // regex: any = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,}$/;
  isValid: boolean = null;
  validateConfirmPwd = false;
  tokenObj: any = {};
  public isValidToken: boolean = null;
  public isPasswordInValid = false;
  errorMsg: string;
  token: any;
  tokenType: any;
  checkFirstNameValid: any;
  checkLastNameValid: any;
  public passwordSuccessful = false;
  isPWExpired = false;

  constructor(private dataService: DataService, private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      if (params['authToken']) {
        this.tokenObj['authToken'] = params['authToken'];
        this.token = params['authToken'];
        this.tokenType = 'authToken';
      } else if (params['confirmationToken']) {
        this.tokenObj['confirmationToken'] = params['confirmationToken'];
        this.token = params['confirmationToken'];
        this.tokenType = 'confirmationToken';
      } else if (params['resetPasswordToken']) {
        this.tokenObj['resetPasswordToken'] = params['resetPasswordToken'];
        this.token = params['resetPasswordToken'];
        this.tokenType = 'resetPasswordToken';
      } else if (params['resetPWT']) {
        const d = new Date();
        const dt: number = Number(d.getFullYear() + '' + d.getMonth() + '' + d.getDate());
        const pwt = params['resetPWT'];
        const myToken = this.dataService.hex2dec(pwt);
        this.token = params['pwaToken'];

        if (myToken === dt) {
          this.isValidToken = true;
          this.isPWExpired = true;
        }
      }

      if (Object.keys(this.tokenObj).length > 0) {
        this.dataService.checkToken(this.tokenObj).subscribe(result => {
          this.checkFirstNameValid = result.firstName;
          this.checkLastNameValid = result.lastName;
          if (result.message && result.message.toLowerCase() === 'valid token') {
            this.isValidToken = true;
          } else {
            this.isValidToken = false;
          }
        }, error => {
          this.isValidToken = false;
        });
      }
    });
  }

  onBlurMethod() {
    this.validateConfirmPwd = false;
    if (this.model.choosepwd) {
      // this.isValid = this.regex.test(this.model.choosepwd);
      // if(this.regex.test(this.model.choosepwd)) {
      if (this.isValidPW(this.model.choosepwd)) {
        this.checkFirstNameValid = this.checkFirstNameValid || '~';
        this.checkLastNameValid = this.checkLastNameValid || '~';

        if (this.model.choosepwd.toLowerCase().indexOf(this.checkFirstNameValid.toLowerCase()) === -1 &&
          this.model.choosepwd.toLowerCase().indexOf(this.checkLastNameValid.toLowerCase()) === -1) {
          this.isValid = true;
        } else {
          this.isValid = false;
          // this.isPasswordInValid = true;
          this.errorMsg = 'Your password must not contain either your first name or last name.';
        }
      } else {
        this.isValid = false;
        this.isPasswordInValid = false;
        this.errorMsg = 'Please provide valid password';
      }
    } else {
      this.isValid = null;
      this.model.pwdconfirm = '';
    }
  }

  validateConfirmPassword() {
    if (this.model.choosepwd && this.isValid) {
      this.validateConfirmPwd = true;
    }
  }

  resetPassword() {
    this.tokenType = this.tokenType || 'authToken';
    const body = {
      [this.tokenType]: this.token,
      'password': this.model.choosepwd,
      'passwordConfirmation': this.model.pwdconfirm
    };

    this.dataService.changeUserPassword(body).subscribe(response => {
      if (response) {
        this.passwordSuccessful = true;
        this.validateConfirmPwd = false;
        this.isPasswordInValid = false;
        this.isValidToken = null;
      }
    }, error => {
      this.isPasswordInValid = true;
      this.model.pwdconfirm = '';
      this.model.choosepwd = '';
      this.isValid = null;
      this.validateConfirmPwd = null;
      this.errorMsg = error.errorMsg || error.message;
    });
  }

  isValidPW(password) {
    const uppercase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const lowercase = 'abcdefghijklmnopqrstuvwxyz';
    const digits = '0123456789';
    const splChars = '~!@#$%&*()-_+={[]}|/<>?.';

    const ucaseFlag = this.pwContains(password, uppercase);
    const lcaseFlag = this.pwContains(password, lowercase);
    const digitsFlag = this.pwContains(password, digits);
    const splCharsFlag = this.pwContains(password, splChars);

    if (password.length >= 8 && ucaseFlag && lcaseFlag && (digitsFlag || splCharsFlag)) {
      return true;
    } else {
      return false;
    }
  }

  pwContains(password, allowedChars) {
    for (let i = 0; i < password.length; i++) {
      const char = password.charAt(i);

      if (allowedChars.indexOf(char) >= 0) {
        return true;
        break;
      }
    }

    return false;
  }

  backToLogin() {
    this.router.navigate(['login']);
  }

  ngOnDestroy() {
  }
}
