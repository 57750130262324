import { Injectable, EventEmitter } from '@angular/core';
import { Observable, Observer, of, throwError, Subject } from 'rxjs';
// TO DO REMOVE THIS IMPORT
import { inspect } from 'util';
//import { Response, Http, Headers, RequestOptions, ResponseOptions } from '@angular/http';
import {
  HttpResponse as Response,
  HttpClient as Http,
  HttpHeaders as Headers,
  HttpRequest as RequestOptions,
  HttpResponseBase as ResponseOptions
} from '@angular/common/http';

import { SignalRService } from '@btsdigital/pulsesignalr';
import { VideoCallManagerService } from '@btsdigital/pulsewebrtc';
import { map, catchError, tap } from 'rxjs/operators';
import { LoggerService } from '@btsdigital/pulseutilities';

//import 'rxjs/add/operator/catch';
import * as _ from 'lodash';
import * as moment from 'moment';

declare var require;
import * as Raven from 'raven-js';

var timezones = require('../timezone-list.json');

@Injectable()
export class DataService {
  coachServerUrl: string = '';
  shadowStagingServerUrl: string = '';
  mbirV2ServerUrl: string = '';
  pulseSurveyUrl: string = '';
  taggingServerUrl: string = '';
  contentRatingUrl: string = '';
  useAjaxInViewOnline: boolean = false;
  language: any;
  curLang: any = 'en';
  loginResponse: any;
  coach: any = null;
  coachee: any = null;
  reminders: any = [];
  userId: number;
  authToken: string;
  videoCallId: string;
  pathwayId: any;
  timeZones: any = timezones;
  tourTips: TourTip = null;
  saveStatus: string = null;	//null, processing, error and saved
  popup: any = {
    title: 'Content Title',
    view: false,
    type: null,
    data: {
      'details': {
        'sessionType': ''
      }
    },
    class: null,
    footer: false,
    buttons: {
      'caption1': 'OK',
      'caption2': 'Cancel',
      'show1': false,
      'show2': false
    },
    closeBtn: true
  };

  authorizationTokenForPulse: any;
  serviceUrl: string = 'https://localhost/';
  customUserData: any;
  numberOfDays: number = 21;
  isApp: boolean = false;
  toolbarColor: string = '#ff2452';
  toolbarCloseText: string = 'Close';
  toolbarCloseColor: string = '#ffffff';
  configLoaded: boolean = false;
  minRespondents: number = 5;
  questions: any;
  bfbResultQuestions: any = [];
  respondents: any;
  round: number;
  bfbShowPanel: any = 'hide';
  respondentList: Array<any> = [];
  pathwaySections: any;
  bfbIntroPage: boolean = false;	// To keep benchfeedback into page by default open
  surveyErrorMsg: any = {};
  errorMsg: string = '';
  private isTabChanged = new Subject();
  tabChangeStatus = this.isTabChanged.asObservable();
  directlyLoadSecureContent: Array<any> = [];
  flowPlayerKey = '';
  podPopup: boolean = false;
  podMembers: any = {};

  setVideoCallId(callId: string, authToken: string) {
    let apiResponse = JSON.parse(localStorage.getItem('user'));
    if (apiResponse) {
      apiResponse.authToken = authToken;
      apiResponse.videoCallId = callId;
      this.videoCallId = callId;
      this.loginResponse = apiResponse;
      this.setLocalStorage();
    }
  }

  getAuthentication() {
    return this.authorizationTokenForPulse;
  }

  constructor(private http: Http, private signalRService: SignalRService, private videoCallManagerService: VideoCallManagerService, private ls: LoggerService) {
    try {
      this.curLang = localStorage.getItem('curLang') || 'en';
      this.loginResponse = JSON.parse(localStorage.getItem('user'));
      if (this.loginResponse) {
        this.userId = this.loginResponse.userId;
        this.authToken = this.loginResponse.authToken || this.loginResponse.source.authToken;
        this.authorizationTokenForPulse = this.loginResponse.authorizationTokenForPulse;
      }
    } catch (e) {
      // console.log("Error localStorage");
    }
  }

  public setConfig(): Promise<any> {
    return new Promise((resolve) => {
      this.http.get('./config.json').subscribe((res) => {
        let config: any = res;  //res.json();
        if (config) {
          this.flowPlayerKey = config.flowPlayerKey;
          this.language = config.language;
          this.pulseSurveyUrl = config.pulseSurveyUrl;
          this.coachServerUrl = config.coachServerUrl;
          this.taggingServerUrl = config.taggingServerUrl;
          this.contentRatingUrl = config.contentRatingUrl;
          this.shadowStagingServerUrl = config.shadowStagingServerUrl;
          this.serviceUrl = config.pulseServiceUrl;
          this.useAjaxInViewOnline = config.useAjaxInViewOnline;
          this.minRespondents = config.minRespondents;
          this.mbirV2ServerUrl = config.mbirV2ServerUrl;
          this.numberOfDays = config.getSessionForNextNoOfDays || null;
          this.isApp = config.isApp || false;
          this.toolbarColor = config.toolbarColor || '#ff2452';
          this.toolbarCloseText = config.toolbarCloseText || 'Close';
          this.toolbarCloseColor = config.toolbarCloseColor || '#ffffff';
          this.surveyErrorMsg = config.surveyErrorMsg || null;
          this.directlyLoadSecureContent = config.directlyLoadSecureContent || []
          this.signalRService.configureSignalRService({
            hostname: config.pulseServerHostName,
            serviceUrl: config.pulseServiceUrl,
            hasHeartBeat: false
          });
          this.videoCallManagerService.configureWebRTCService({ serviceUrl: config.pulseServiceUrl, eject: true });

          if (config.ravenUrl && config.ravenUrl !== '' && config.ravenEnvironment !== 'Unknown' && Raven !== null) {
            Raven.config(config.ravenUrl, { environment: config.ravenEnvironment })
              .install();
          }

          if (config.enableLogging && config.enableLogging !== '') {
            this.ls.enableLogging(config.enableLogging);
          } else {
            this.ls.enableLogging(false);
          }
        }
        this.configLoaded = true;
        resolve(config);
      });
    });
  }

  dateDiff_inDays(date1, date2) {
    var dt1 = new Date(date1);
    var dt2 = new Date(date2);
    return Math.floor((Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) - Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) / (1000 * 60 * 60 * 24));
  }

  isLiveUrl(slink: string) {
    var pattern: any = /(http|https):\/\/(\w+:{0,1}\w*)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;

    if (!pattern.test(slink)) {
      return false;
    } else {
      return true;
    }
  }

  getCountriesList() {
    var countries = require(`../assets/countries/countries-${this.curLang || 'en'}.json`);
    var sorted = _.orderBy(countries, ['country'], ['ASC']);
    return sorted;
  }

  handleError(error: any) {
    /*var err: any = {};
    if (error._body != "") {
      err = error._body;
    }*/
    var errJson: any = [];

    if (error.status) {
      errJson = {
        'status': error.status,
        'statusText': error.statusText,
        'message': error.message || error,
        'errorMsg': error.error ? error.error.message : error.errorMsg ? error.errorMsg : ''
      };
    } else if (error.error && error.error.status) {
      errJson = {
        'status': error.error.status,
        'statusText': error.error.statusText,
        'message': error.message,
        'errorMsg': error.error ? error.error.message : error.errorMsg ? error.errorMsg : ''
      };
    } else {
      errJson = error;
    }

    if (errJson && errJson.status == 401) {
      this.emitData('do-logout', 'true');
    }

    return throwError(errJson);
  }

  login(userName: string, password: string): Observable<any> {
    var body = {
      'email': userName,
      'password': password
    };

    return this.postRequest('api/mother/users/sessions/create', body, true).pipe(
      map(c => {
        this.readResponseAndSetHeaders(c);

        if (this.loginResponse.message) {
          this.loginResponse.success = false;
        } else {
          this.loginResponse.success = true;
        }

        return this.loginResponse;
      }),
      catchError(this.handleError.bind(this))
    );
  }

  loginSso(token: string): Observable<any> {
    var body = {
      'authToken': token
    };

    return this.postRequest('api/mother/users/sessions/sso', body, true).pipe(
      map(c => {
        this.readResponseAndSetHeaders(c);

        if (this.loginResponse.message) {
          this.loginResponse.success = false;
        } else {
          this.loginResponse.success = true;
        }

        return this.loginResponse;
      }),
      catchError(this.handleError.bind(this))
    );
  }

  public pulseType(dType: any, opt: string) {
    if (opt == 'pc') {
      return (dType == 'PulseContent' || dType == 'Pulse Content' || dType == 'Isomer') ? true : false;
    } else if (opt == 'pa') {
      return (dType == 'PulseAssessmentCoachingSession' || dType == 'Pulse Assessment Coaching Session') ? true : false;
    } else if (opt == 'ps') {
      return (dType == 'PulseSession' || dType == 'Pulse Session') ? true : false;
    } else {
      return (dType == 'PulseContent' || dType == 'Pulse Content' ||
        dType == 'PulseSession' || dType == 'Pulse Session' ||
        dType == 'PulseAssessmentCoachingSession' || dType == 'Pulse Assessment Coaching Session' || dType == 'Isomer') ? true : false;
    }
  }

  readResponseAndSetHeaders(apiCallResult: any) {
    this.loginResponse = apiCallResult.body;
    if (this.loginResponse) {
      this.userId = this.loginResponse.userId;
      this.authToken = this.loginResponse.authToken || this.loginResponse.source.authToken;
      if (apiCallResult.headers && apiCallResult.headers.get('Authorization')) {
        this.authorizationTokenForPulse = apiCallResult.headers.get('Authorization');
        this.loginResponse.authorizationTokenForPulse = this.authorizationTokenForPulse;
      }
    }
  }

  setLocalStorage() {
    if (this.loginResponse) {
      localStorage.setItem('user', JSON.stringify(this.loginResponse));
      this.ls.log('login Successful');
    }
  }

  logout() {
    var body = {
      'authToken': this.authToken
    };
    return this.postRequest('api/mother/users/sessions/destroy', body).pipe(map(data => {
      localStorage.removeItem('user');
      this.userId = null;
      this.authToken = null;

      return data;
    }),
      catchError(this.handleError.bind(this))
    );
  }

  passwordReset(email: any): Observable<any> {
    var body = {
      'userEmail': email
    };
    var url = 'api/mother/users/passwords/request_reset?email=' + email;
    return this.postRequest(url, body).pipe(map(data => {
      return data;
    }),
      catchError(this.handleError.bind(this))
    );
  }

  checkToken(tokenObject: any): Observable<any> {
    var url = 'api/mother/users/passwords/check_token';
    return this.postRequest(url, tokenObject).pipe(map(data => {
      this.readResponseAndSetHeaders(data);
      return data;
    }),
      catchError(this.handleError.bind(this))
    );
  }

  changeUserPassword(object: any): Observable<any> {
    var url = 'api/mother/users/passwords/set';
    return this.postRequest(url, object).pipe(map(data => {
      return data;
    }),
      catchError(this.handleError.bind(this))
    );
  }

  createObservable(data: any): Observable<any> {
    return Observable.create((observer: Observer<any>) => {
      observer.next(data);
      observer.complete();
    });
  }

  getPathways(): Observable<any> {
    var url = 'api/mother/pathways/list?userId=' + this.userId + '&authToken=' + this.authToken;
    return this.get(url).pipe(map(data => {
      return data;
    }),
      catchError(this.handleError.bind(this))
    );
  }

  /*markedComplete(activityId: number): Observable<any> {
    var body = {
      "activityId": activityId
    }
    var url = "api/mother/activities/complete?authToken=" + this.authToken;
    return this.postRequest(url, body).pipe(data => {
      return data.map(c => {
        return c.json();
      }).catch(this.handleError.bind(this));
    });
  }*/

  unbookActivity(activityId: number, status: boolean): Observable<any> {
    var body = {
      'authToken': this.authToken,
      'activityId': activityId,
      'status': status
    };
    var url = 'api/mother/activities/update';
    return this.postRequest(url, body).pipe(map(data => {
      return data;
    }),
      catchError(this.handleError.bind(this))
    );
  }

  getPathwayDetails(pathwayId: number): Observable<any> {
    // var url = "api/mother/pathways/show/with_sections?pathwayId=" + pathwayId + "&authToken=" + this.authToken;
    var url = 'api/mother/pathways/show?pathwayId=' + pathwayId + '&authToken=' + this.authToken;

    return this.get(url).pipe(map(data => {
      return data;
    }),
      catchError(this.handleError.bind(this))
    );
  }

  getSlots(sessionId: number): Observable<any> {
    var url = 'api/mother/slots/available?authToken=' + this.authToken + '&coachingSessionId=' + sessionId;
    return this.get(url).pipe(map(data => {
      return data;
    }),
      catchError(this.handleError.bind(this))
    );
  }

  getShifts(): Observable<any> {
    var url = 'api/mother/diagnostics?userId=' + this.userId + '&authToken=' + this.authToken;
    return this.get(url).pipe(map(data => {
      return data;
    }),
      catchError(this.handleError.bind(this))
    );
  }

  bookSlot(sessionId: number, slotId: number): Observable<any> {
    var body = {
      'coachingSessionId': sessionId,
      'slotId': slotId,
      'status': 'booked'
    };

    return this.postRequest('api/mother/sessions/update?authToken=' + this.authToken, body).pipe(map(data => {
      return data;
    }),
      catchError(this.handleError.bind(this))
    );
  }

  unbookSession(sessionId: number): Observable<any> {
    var body = {
      'coachingSessionId': sessionId,
      'userId': this.userId,
      'status': 'Not Yet Booked'
    };

    return this.postRequest('api/mother/sessions/update?authToken=' + this.authToken, body).pipe(map(data => {
      return data;
    }),
      catchError(this.handleError.bind(this))
    );
  }

  getSessionDetails(sessionId: number): Observable<any> {
    var url = 'api/mother/sessions/details?authToken=' + this.authToken + '&bookingId=' + sessionId;
    return this.get(url).pipe(map(data => {
      return data;
    }),
      catchError(this.handleError.bind(this))
    );
  }

  getMBIR(pathwayId: number): Observable<any> {
    var url = 'api/mother/mbirs?pathwayId=' + pathwayId + '&authToken=' + this.authToken;
    return this.get(url).pipe(map(data => {
      return data;
    }),
      catchError(this.handleError.bind(this))
    );
  }

  saveMBIR(jsonData: any): Observable<any> {
    var body = {
      'pathwayId': this.pathwayId,
      'mbir': jsonData
    };

    var url = 'api/mother/mbirs/create?authToken=' + this.authToken;
    return this.postRequest(url, body).pipe(map(data => {
      return data;
    }),
      catchError(this.handleError.bind(this))
    );
  }

  getMBIRV2(mbirId: string): Observable<any> {
		var url = 'get?id=' + mbirId + '&token=' + this.authorizationTokenForPulse;

		return this.getRequestMbirV2(url).pipe(map(data => {

			// To do: Remove this ==========
			console.log('G E T');
			console.log(inspect(data, false, null, true), '\n\n');
			//==============================

			return data;
		}),
			catchError(this.handleError.bind(this))
		);
	}

	// mbirData: The full MBIR object as described at https://btsdigital.atlassian.net/wiki/spaces/TD/pages/1745715225/MBIR+V2+API
	saveMBIRV2(mbirId: string, mbirData: any): Observable<any>  {
		var url = 'update'
		var body = {
			id: mbirId,
			token: this.authorizationTokenForPulse,
			mbir: mbirData
		}

		// To do: Remove this ==========
		console.log('U P D A T E ');
		console.log(inspect(mbirData, false, null, true), '\n\n');
		//==============================

		return this.putRequestMbirV2(url, body).pipe(map(data => {
			return data;
		}),
			catchError(this.handleError.bind(this))
		);
	}

	emailMBIRV2(mbirId: string, toCoachee: boolean, toCoach: boolean, locale: string): Observable<any> {
		const url = 'email'
		const body = {
			id: mbirId,
			token: this.authorizationTokenForPulse,
			coach: toCoach,
			coachee: toCoachee,
			locale: locale
		}

		return this.postRequestMbirV2(url, body).pipe(map(data => {
			return data;
		}),
			catchError(this.handleError.bind(this))
		);
	}
	
	getMBIRV2Options(mbirId: string): Observable<any> {
		var url = 'api/mother/mbirs/mbir_options?mbirId=' + mbirId + '&token=' + this.authorizationTokenForPulse;

		return this.get(url).pipe(map(data => {
			return data;
		}),
			catchError(this.handleError.bind(this))
		);
	}

  cleanInputs(html: any) {
    // Remove the html tags from string.
    return html ? html.replace(/<\/?[^>]+(>|$)/g, '') : html;
  }

  saveUser(jsonData: any): Observable<any> {
    var curDate = moment.utc().format();
    var body = {
      'user': {
        'id': jsonData.id,
        'username': this.cleanInputs(jsonData.username),
        'firstName': this.cleanInputs(jsonData.firstName),
        'lastName': this.cleanInputs(jsonData.lastName),
        'email': this.cleanInputs(jsonData.email),
        'emailTwo': this.cleanInputs(jsonData.emailTwo),
        'phoneOne': this.cleanInputs(jsonData.phoneOne),
        'phoneTwo': this.cleanInputs(jsonData.phoneTwo),
        'country': this.cleanInputs(jsonData.country),
        'town': this.cleanInputs(jsonData.town),
        'timezone': this.cleanInputs(jsonData.timezone),
        'timeModified': curDate
      }
    };

    var url = 'api/mother/users/update?authToken=' + this.authToken;
    return this.postRequest(url, body).pipe(map(data => {
      return data;
    }),
      catchError(this.handleError.bind(this))
    );
  }

  uploadPic(jsonData: any): Observable<any> {
    var curDate = moment.utc().format();
    var body = new FormData();
    body.append('timeModified', curDate);
    body.append('userId', jsonData.id);
    body.append('file', jsonData.photoFile);
    body.append('authToken', this.authToken);

    var url = 'api/mother/users/upload_pic';
    return this.http.post(this.coachServerUrl + url, body).pipe(map(data => {
      return data;
    }),
      catchError(this.handleError.bind(this))
    );
  }

  uploadDocuments(jsonData: any, document: any): Observable<any> {
    var body = new FormData();
    body.append('pathwayId', this.pathwayId);
    body.append('userId', jsonData.id);
    body.append('file', document);
    body.append('authToken', this.authToken);

    var url = 'api/mother/pathways/files/upload';
    return this.http.post(this.coachServerUrl + url, body).pipe(map(data => {
      return data;
    }),
      catchError(this.handleError.bind(this))
    );
  }

  deleteDocument(document: any): Observable<any> {
    var body = new FormData();
    body.append('pathwayId', this.pathwayId);
    body.append('fileId', document.id);
    body.append('authToken', this.authToken);

    var url = 'api/mother/pathways/files/delete';
    return this.http.post(this.coachServerUrl + url, body).pipe(map(data => {
      return data;
    }),
      catchError(this.handleError.bind(this))
    );
  }

  getPathwayDocuments(pathwayId: any): Observable<any> {
    var url = 'api/mother/pathways/files?pathwayId=' + pathwayId + '&userId=' + this.userId + '&authToken=' + this.authToken;
    return this.get(url).pipe(map(data => {
      return data;
    }),
      catchError(this.handleError.bind(this))
    );
  }

  getAgreement(userId: number): Observable<any> {
    var url = 'api/mother/agreements/outstanding?userId=' + this.userId + '&authToken=' + this.authToken;
    return this.get(url).pipe(map(data => {
      return data;
    }),
      catchError(this.handleError.bind(this))
    );
  }

  acceptAgreement(userId: number, required: Array<any>, optional: Array<any>): Observable<any> {
    var body = {
      'userId': this.userId,
      'authToken': this.authToken,
      'agreements': []
    };

    if (required && required.length > 0) {
      for (let i = 0; i < required.length; i++) {
        body.agreements.push({
          'id': required[i].id,
          'approved': required[i].approved
        });
      }
    }

    if (optional && optional.length > 0) {
      for (let i = 0; i < optional.length; i++) {
        body.agreements.push({
          'id': optional[i].id,
          'approved': optional[i].approved
        });
      }
    }

    return this.postRequest('api/mother/agreements/accept', body).pipe(map(data => {
      return data;
    }),
      catchError(this.handleError.bind(this))
    );
  }

  setCountryDetails(userId: number, coachee: any): Observable<any> {
    var body = {
      'user': {
        'id': userId,
        'country': coachee.country,
        'town': coachee.town || '-',
        'timezone': coachee.timezone
      }
    };

    var url = 'api/mother/users/set_country?authToken=' + this.authToken;
    return this.postRequest(url, body).pipe(map(data => {
      return data;
    }),
      catchError(this.handleError.bind(this))
    );
  }

  setUserLocale(userId: number, lang: string): Observable<any> {
    var body = {
      'user': {
        'id': userId,
        'locale': lang
      }
    };

    var url = 'api/mother/users/set_locale?authToken=' + this.authToken;
    return this.postRequest(url, body).pipe(map(data => {
      return data;
    }),
      catchError(this.handleError.bind(this))
    );
  }

  getAvailableSessions(startDate, moduleName): Observable<any> {
    var body = {
      'startDate': startDate,
      'module': moduleName,
      'days': this.numberOfDays
    };

    var url = 'GatewayService.svc/GetAvailableSessions';

    return this.postRequestPulse(url, body).pipe(map(data => {
      return data;
    }),
      catchError(this.handleError.bind(this))
    );
  }

  scheduleCoachParticipant(coName, sessionId, moduleName): Observable<any> {
    var body = {
      'coName': coName,
      'sessionId': sessionId,
      'moduleName': moduleName
    };

    var url = 'GatewayService.svc/ScheduleCoachParticipant';

    return this.postRequestPulse(url, body).pipe(map(data => {
      return data;
    }),
      catchError(this.handleError.bind(this))
    );
  }

  deleteScheduledCoachParticipant(coName, sessionId, moduleName): Observable<any> {
    var body = {
      'coName': coName,
      'sessionId': sessionId,
      'moduleName': moduleName
    };

    var url = 'GatewayService.svc/DeleteScheduledCoachParticipant';

    return this.postRequestPulse(url, body).pipe(map(data => {
      return data;
    }),
      catchError(this.handleError.bind(this))
    );
  }

  authenticateUser(assessmentSession): Observable<any> {
    let authenticatedFor: any = localStorage.getItem('pulseAuthentication');
    let authenticated = false;

    this.serviceUrl = assessmentSession.pulseServer;

    if (authenticatedFor) {
      authenticatedFor = JSON.parse(authenticatedFor);
      if (authenticatedFor.experienceName === assessmentSession.experienceName && authenticatedFor.pulseServer === assessmentSession.pulseServer) {
        authenticated = true;
      } else {
        authenticated = false;
      }
    } else {
      authenticated = false;
    }

    if (!authenticated) {
      localStorage.setItem('pulseAuthentication', JSON.stringify(assessmentSession));
      return this.createUserWithJWT(assessmentSession.experienceName).pipe(catchError(e => {
        localStorage.removeItem('pulseAuthentication');
        //console.log(e);
        return e;
      }));
    }

    return of(true);
  }

  createUserWithJWT(eventName): Observable<any> {
    var body = { 'currentEvent': eventName };

    var url = 'Authenticate.svc/CreateUserWithJWT';

    return this.postRequestPulse(url, body, this.authorizationTokenForPulse).pipe(map(data => {
      let resJson: any = data;
      if (resJson.CreateUserWithJWTResult && resJson.CreateUserWithJWTResult.success === false) {
        localStorage.removeItem('pulseAuthentication');
      }
      return data;
    }),
      catchError(this.handleError.bind(this))
    );
  }

  getBookedSession(body: any) {
    // let body = {
    // 	param: getBookedSessionFor,
    // 	coParams : []
    // };

    return this.postRequestPulse('GatewayService.svc/GetBookedSessions', body).pipe(map(data => {
      return data;
    }),
      catchError(this.handleError.bind(this))
    );
  }

  getCustomData() {
    return this.get('/api/mother/users/custom_data?authToken=' + this.authToken + '&userId=' + this.userId)
      .pipe(map(data => {
        return data;
      }),
        catchError(this.handleError.bind(this))
      );
  }

  getShadowInfo(token: string) {
    var url = 'api/shadow/get_info?token=' + token;
    return this.http.post(this.shadowStagingServerUrl + url, '').pipe(map(data => {
      return data;
    }),
      catchError(this.handleError.bind(this))
    );
  }

  // Change Password from Edit Profile
  changePW(newPassword: boolean, confPassword: boolean): Observable<any> {
    var jsonData = {
      'authToken': this.authToken,
      'password': newPassword,
      'passwordConfirmation': confPassword
    };

    return this.postRequest('api/mother/users/passwords/set', jsonData).pipe(map(data => {
      return data;
    }),
      catchError(this.handleError.bind(this))
    );
  }


  saveCustomData(customData): Observable<any> {
    let body = { 'userId': this.userId, 'customData': JSON.parse(customData), 'authToken': this.authToken };

    return this.postRequest(`api/mother/users/custom_data`, body).pipe(map(data => {
      return data;
    }),
      catchError(this.handleError.bind(this))
    );
  }

  // Get Diagnostics Data
  getDiagnosticsData(): Observable<any> {
    var url = 'api/mother/diagnostics?userId=' + this.userId + '&authToken=' + this.authToken;
    return this.get(url).pipe(map(data => {
      return data;
    }),
      catchError(this.handleError.bind(this))
    );
  }

  // Get All Benchfeedback Data
  getBenchFeedbackData(activityID: any): Observable<any> {
    var url = 'api/mother/bench?activityId=' + activityID + '&authToken=' + this.authToken;
    return this.get(url).pipe(map(data => {
      return data;
    }),
      catchError(this.handleError.bind(this))
    );
  }

  // Save Bench Feedback Behaviours
  saveBehaviours(jsonData): Observable<any> {
    return this.postRequest('api/mother/bench/behaviours', jsonData).pipe(map(data => {
      return data;
    }),
      catchError(this.handleError.bind(this))
    );
  }

  // Save Bench Feedback Respondents
  saveRespondents(jsonData): Observable<any> {
    return this.postRequest('api/mother/bench/respondents', jsonData).pipe(map(data => {
      return data;
    }),
      catchError(this.handleError.bind(this))
    );
  }

  // Save More Respondents
  saveMoreRespondents(jsonData): Observable<any> {
    return this.postRequest('api/mother/bench/add_respondents', jsonData).pipe(map(data => {
      return data;
    }),
      catchError(this.handleError.bind(this))
    );
  }

  // Notify Respondents
  notifyRespondents(jsonData): Observable<any> {
    return this.postRequest('api/mother/bench/notify', jsonData).pipe(map(data => {
      return data;
    }),
      catchError(this.handleError.bind(this))
    );
  }

  // Delete Respondent from Bench Feedback
  delRespondent(jsonData): Observable<any> {
    return this.postRequest('api/mother/bench/delete_respondent', jsonData).pipe(map(data => {
      return data;
    }),
      catchError(this.handleError.bind(this))
    );
  }

  // Save Bench Feedback Email
  saveEmail(jsonData): Observable<any> {
    return this.postRequest('api/mother/bench/email', jsonData).pipe(map(data => {
      return data;
    }),
      catchError(this.handleError.bind(this))
    );
  }

  // Re Generate Survey Data
  reGenSurveyData(activityId): Observable<any> {
    var body = {
      'authToken': this.authToken,
      'activityId': activityId
    };

    return this.postRequest('api/mother/bench/start', body).pipe(map(data => {
      return data;
    }),
      catchError(this.handleError.bind(this))
    );
  }

  // Sync Calendar Start
  startSyncCalendar(): Observable<any> {
    var jsonData = {
      'authToken': this.authToken,
      'userId': this.userId
    };

    return this.postRequest('api/mother/users/start_coachee_calendar_sync', jsonData).pipe(map(data => {
      return data;
    }),
      catchError(this.handleError.bind(this))
    );
  }

  stopSyncCalendar(): Observable<any> {
    var jsonData = {
      'authToken': this.authToken,
      'userId': this.userId
    };

    return this.postRequest('api/mother/users/stop_coachee_calendar_sync', jsonData).pipe(map(data => {
      return data;
    }),
      catchError(this.handleError.bind(this))
    );
  }

  // Lock Bench Feedback View
  lockView(jsonData): Observable<any> {
    return this.postRequest('api/mother/bench/lock', jsonData).pipe(map(data => {
      return data;
    }),
      catchError(this.handleError.bind(this))
    );
  }

  // Send MBIR email
  emailMbir(myself: boolean, coach: boolean): Observable<any> {
    var jsonData = {
      'authToken': this.authToken,
      'pathwayId': this.pathwayId,
      'coach': myself,
      'coachee': coach
    };

    return this.postRequest('api/mother/mbirs/email', jsonData).pipe(map(data => {
      return data;
    }),
      catchError(this.handleError.bind(this))
    );
  }

  getSurveyHTML(roundNo: number) {
    let body = {
      'round': roundNo
    };

    return this.postRequestBfb('api/getSurvey', body).pipe(map(data => {
      return data;
    }),
      catchError(this.handleError.bind(this))
    );
  }

  postRequest(url: string, body: any, isLogin?: boolean): Observable<any> {
    const headersObj = {
      'Content-Type': 'application/json'
    };
    if (this.authorizationTokenForPulse) {
      headersObj['Authorization'] = this.authorizationTokenForPulse;
    }

    const headers = new Headers(headersObj);
    const options = { headers: headers };
    const params = JSON.stringify(body);

    if (isLogin) {
      options['observe'] = 'response' as 'body';
    }

    return this.http.post(this.coachServerUrl + url, params, options)
      .pipe(map((data) => data), catchError(this.handleError));
  }

  postRequestMbirV2(url: string, body: any): Observable<any> {
		const headersObj = {
			'Content-Type': 'application/json'
		};
		if (this.authorizationTokenForPulse) {
			headersObj['Authorization'] = this.authorizationTokenForPulse;
		}

		const headers = new Headers(headersObj);
		const options = { headers: headers };
		const params = JSON.stringify(body);
		options['observe'] = 'response' as 'body';

		return this.http.post(this.mbirV2ServerUrl + url, params, options)
			.pipe(map((data) => data), catchError(this.handleError));
	}

	putRequestMbirV2(url: string, body: any): Observable<any> {
		const headersObj = {
			'Content-Type': 'application/json'
		};
		if (this.authorizationTokenForPulse) {
			headersObj['Authorization'] = this.authorizationTokenForPulse;
		}

		const headers = new Headers(headersObj);
		const options = { headers: headers };
		const params = JSON.stringify(body);
		options['observe'] = 'response' as 'body';

		return this.http.put(this.mbirV2ServerUrl + url, params, options)
			.pipe(map((data) => data), catchError(this.handleError));
	}

  postRequestPulse(url: string, body: any, authorization?: any): Observable<any> {
    let headers: Headers;
    headers = new Headers({
      'Content-Type': 'application/json'
    });

    if (authorization) {
      headers = new Headers({
        'Content-Type': 'application/json',
        'Authorization': authorization
      });
    }

    const options = { headers: headers, withCredentials: true };
    var params = JSON.stringify(body);

    if (this.serviceUrl && !this.serviceUrl.endsWith('/')) {
      this.serviceUrl += '/'
    }
    return this.http.post(this.serviceUrl + 'PulseServices/' + url, params, options)
      .pipe(map((data) => data), catchError(this.handleError));
  }

  postRequestBfb(url: string, body: any): Observable<any> {
    const headersObj = {
      'Content-Type': 'application/json'
    };
    if (this.authorizationTokenForPulse) {
      headersObj['Authorization'] = this.authorizationTokenForPulse;
    }

    const headers = new Headers(headersObj);
    const options = { headers: headers };
    const params = JSON.stringify(body);
    return this.http.post(this.pulseSurveyUrl + url, params, options)
      .pipe(map((data) => data), catchError(this.handleError));
  }

  postRequestContentRating(url: string, body: any): Observable<any> {
    const headersObj = {
      'Content-Type': 'application/json'
    };
    if (this.authorizationTokenForPulse) {
      headersObj['Authorization'] = this.authorizationTokenForPulse;
    }

    const headers = new Headers(headersObj);
    const options = { headers: headers };
    const params = JSON.stringify(body);
    return this.http.post(this.contentRatingUrl + url, params, options)
      .pipe(map((data) => data), catchError(this.handleError));
  }

  getRequestBfb(url: string): Observable<any> {
    // headers.append('Access-Control-Allow-Origin', '*');
    if (this.authorizationTokenForPulse) {
      var headers = new Headers({ 'Authorization': this.authorizationTokenForPulse });
    }
    const options = { headers: headers, withCredentials: true };
    return this.http.get(this.pulseSurveyUrl + url, options);
  }

  getRequestMbirV2(url: string): Observable<any> {
		if (this.authorizationTokenForPulse) {
			var headers = new Headers({ 'Authorization': this.authorizationTokenForPulse });
		}
		const options = { headers: headers };
		return this.http.get(this.mbirV2ServerUrl + url, options);
	}

  get(url: string): Observable<any> {
    //headers.append('Access-Control-Allow-Origin', '*');
    if (this.authorizationTokenForPulse) {
      var headers = new Headers({ 'Authorization': this.authorizationTokenForPulse });
    }
    const options = { headers: headers };

    return this.http.get(this.coachServerUrl + url, options);
  }

  public _observableEmitter: any = {};

  emitData(key, opts) {
    if (this._observableEmitter[key]) {
      this._observableEmitter[key].emit(opts);
    }
  }

  getEmitter(key) {
    if (key) {
      this._observableEmitter[key] = new EventEmitter();
      return this._observableEmitter[key];
    }
  }

  getTourJSON(): Observable<any> {
    return this.http.get('./tour-tips.json');
  }

  dec2hex(s?: number) {
    var d = new Date();
    var dt: number = parseInt(d.getFullYear() + '' + d.getMonth() + '' + d.getDate());

    if (!s) {
      s = dt;
    }
    return (s < 15.5 ? '0' : '') + Math.round(s).toString(16);
  };

  hex2dec(s) {
    return parseInt(s, 16);
  };

  deleteSurvey(email, round) {
    let body = {
      'email': email,
      'round': round
    };
    return this.postRequestBfb('api/deleteSurvey', body).pipe(map(data => {
      return data;
    }),
      catchError(this.handleError.bind(this))
    );
  }

  setTPCookies() {
    return this.getRequestBfb('setTPCookies').pipe(map(data => {
      return data
    }),
      catchError(this.handleError.bind(this))
    );
  }

  getTPCookies() {
    return this.getRequestBfb('getTPCookies').pipe(map(data => {
      return data
    }),
      catchError(this.handleError.bind(this))
    );
  }

  getAnonymisedResults(activityId: any, roundNo: number): Observable<any> {
    var url = 'api/mother/bench/anonymised_results?authToken=' + this.authToken + '&activityId=' + activityId + '&round=' + roundNo;

    return this.get(url).pipe(map(data => {
        return data;
      }),
      catchError(this.handleError.bind(this))
    );
  }

  generateSurvey(questions, respondents, round) {
    let body = {
      'questions': questions,
      'respondents': respondents,
      'round': round
    };
    return this.postRequestBfb('api/generateSurvey', body).pipe(map(data => {
      return data;
    }),
      catchError(this.handleError.bind(this))
    );
  }

  processSurveyResult(results, round, fullRes: boolean) {
    var resData: Array<any> = [];
    var result: any = null;
    var managerRating = [];
    let obj = {
      questions: []
    };

    resData = results.filter((r: any) => {
      return r.custom.pathwayId == this.pathwayId;
    });


    resData.sort(function (a, b) { return a.round - b.round });

    for (let i = 0; i < resData.length; i++) {
      if (resData[i].round <= round) {
        result = resData[i];

        for (let j = 0; j < result.questions.length; j++) {
          let alreadyPresent = obj.questions.filter((q: any) => {
            return q.name == result.questions[j].name;
          });

          if (alreadyPresent.length === 0) {
            obj.questions.push({
              'name': result.questions[j].name,
              'rounds': []
            });
          }
        }

        for (let k = 0; k < obj.questions.length; k++) {
          let lmrData: any = this.respondentList.filter((res: any) => {
            return res.round === result.round;
          })[0];
          let lineManagers: any = lmrData.respondents.filter((res: any) => {
            return res.role === 'LineManager';
          });

          obj.questions[k].rounds.push({
            'round': result.round,
            'ratings': [],
            'comments': [],
            // 'lineManagerEmail': [],
            'managerRating': []
          });
          for (let j = 0; j < result.surveys.length; j++) {
            if (this.loginResponse.source.email !== result.surveys[j].recipientEmail && result.surveys[j].response) {
              var index = k + 1;
              var rating: number = parseInt(result.surveys[j].response['question' + index]);
              var comment = result.surveys[j].response['commentQuestion' + index];

              let islineManager: any = lineManagers.filter((res: any) => {
                return res.email === result.surveys[j].recipientEmail;
              });

              if (islineManager && islineManager.length > 0) {
                obj.questions[k].rounds[i].managerRating.push(rating);
                obj.questions[k].rounds[i].comments.push({ 'comment': comment, 'linemgr': true, 'from': result.surveys[j].recipientEmail });
              } else {
                obj.questions[k].rounds[i].ratings.push(rating);
                obj.questions[k].rounds[i].comments.push({ 'comment': comment, 'linemgr': false, 'from': result.surveys[j].recipientEmail });
              }
            }
          }
        }
      }
    }

    var ratingCount = 0;
    for (let i = 0; i < obj.questions.length; i++) {
      let question = obj.questions[i];
      for (let j = 0; j < question.rounds.length; j++) {
        let round = question.rounds[j];
        round.originalRatings = round.ratings.slice(0);
        if (round.ratings.length >= this.minRespondents) {
          if (round.ratings && round.ratings.length > 0) {
            round['managerRating'] = round.managerRating;
            ratingCount++;
            round['averageRating'] = (_.sum(round.ratings) / _.size(round.ratings)).toFixed(1);

            round['highestRating'] = _.max(round.ratings);
            round['lowestRating'] = _.min(round.ratings);

            round.ratings.splice(_.indexOf(round.ratings, round['highestRating']), 1);
            round.ratings.splice(_.indexOf(round.ratings, round['lowestRating']), 1);

            round['secondHighestRating'] = _.max(round.ratings);
            round['secondLowestRating'] = _.min(round.ratings);
          }
        }
      }
    }

    result.questions = obj.questions;
    return fullRes ? result : obj;
  }

  isFileExists(filePath: string): Observable<any> {
    return this.http
      .get(filePath, { observe: 'response', responseType: 'blob' })
      .pipe(
        map(response => {
          return response;
        }),
        catchError(error => {
          console.log(error);
          return of(false);
        })
      );
  }

  getTaggingData(url) {
    let headers;
    if (this.authorizationTokenForPulse) {
      headers = new Headers({ 'Authorization': this.authorizationTokenForPulse });
    }
    const options = { headers: headers };
    return this.http.get(this.taggingServerUrl + url, options);
  }
  postTaggingData(url, data) {
    let headers;
    if (this.authorizationTokenForPulse) {
      headers = new Headers({ 'Authorization': this.authorizationTokenForPulse });
    }
    const options = { headers: headers };
    return this.http.post(this.taggingServerUrl + url, data, options);
  }
  emitTabChange(msg: any) {
		this.isTabChanged.next(msg);
	}

  isPodLeader(coco: any, pwid: any) {
    let retVal: any = null;
    const pathway1: String = localStorage.getItem('pathway');
    const pathway2 = pathway1 ? pathway1.replace(/"/g, '') : pathway1;

    if(pwid && pwid.type && pwid.type != 'PodSession') return;

    if(coco == 'coachee') {
      retVal = (pathway2 && this.pathwayId==pwid) ? pathway2.indexOf('leaderUserId:' + (this.coachee && this.coachee.id)) : null;
    } else if(coco == 'tlCoach') {
      if (pwid.podDetails) {
        if (pwid.podDetails.leaderUserId==(this.coachee && this.coachee.id) || 
          pwid.podDetails.participantDetails && pwid.podDetails.participantDetails.length>0
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    } else if(coco == 'tlCobtn') {
      if (pwid.podDetails && (pwid.podDetails.leaderUserId==(this.coachee && this.coachee.id) || 
        pwid.podDetails.participantDetails && pwid.podDetails.participantDetails.length>0
      )) {
        return true;
      }
    } else if(coco == 'tlcmbr') {
      if(pwid.podDetails && pwid.podDetails.participantDetails && pwid.podDetails.participantDetails.length>0) {
        return true;
      }
    } else {
    //   retVal = this.pathwayId==pwid ? pathway2.indexOf('coach:' + coco.name) : null;
    }

    return (retVal && retVal != -1) ? true : false;
  }

  coachPodLeader(coach: any) {
    const pathway: any = JSON.parse(localStorage.getItem('pathway'));

    for(let i=0; i<pathway.sections.length; i++) {
      for(let j=0; j<pathway.sections[i].activities.length; j++) {
        let act: any = pathway.sections[i].activities[j];
        if(act.type=="PodSession" && act.coach == coach.name) {
          return true;
        }

        // if(coco == 'coachee' && act.podDetails && act.podDetails.leaderUserId==(this.coachee && this.coachee.id)) {
        //   return true;
        // }
      }
    }
  }

}
