import { Component, Input, OnInit, DoCheck } from '@angular/core';
import { DataService } from './../data.service';
import * as _ from 'lodash';
import * as $ from 'jquery';
import { TranslateService } from '../translate.service';
// declare var $: $;
declare var jQuery: any;
window['$'] = window['jQuery'] = window['jquery'] = $;

@Component({
  selector: 'app-submit-button',
  templateUrl: './submit-button.component.html'
})
export class SubmitButtonComponent implements OnInit, DoCheck {
  @Input() buttons: any;

  saveStatus: string = null;  // null, processing, error and saved
  errorMsg: string = null;
  taggingError = false;
  showCompletePersonalDataModal = false;
  enableOkBtn = false;
  pathwayTagsPresentFlagArray = [];
  constructor(private dataService: DataService, public ts: TranslateService) {
    if (localStorage.getItem('first_booking_present') === 'false') {
      // check for unapproved tags
      let url = 'api/v1/tagging/list_for?source=Coach&model=PathwayV2&unapprovedOnly=true';
      const pathwayId: any = localStorage.getItem('pathways') ? JSON.parse(localStorage.getItem('pathways')) : [];
      if (pathwayId.length > 0) {
        let modelIds = '';
        pathwayId.forEach(obj => {
          modelIds += `&modelIds[]=${obj.id}`;
        });
        url += modelIds;
      }
      this.dataService.getTaggingData(url).subscribe((res: any) => {
        if (res.models && res.models.length > 0) {
          for (let index = 0; index < res.models.length; index++) {
            const element = res.models[index];
            if (element.tagGroups.length > 0) {
              // this pathway has tags
              this.pathwayTagsPresentFlagArray.push(true);
              // show complete your personal data modal
              this.showCompletePersonalDataModal = true;
              localStorage.setItem('unapprovedTagsPresent', 'true');
              this.enableOkBtn = true;
              break;
            } else {
              this.pathwayTagsPresentFlagArray.push(false);
            }
          }
          if (_.includes(this.pathwayTagsPresentFlagArray, true)) {
            this.showCompletePersonalDataModal = true;
            localStorage.setItem('unapprovedTagsPresent', 'true');
          } else {
            this.showCompletePersonalDataModal = false;
            localStorage.setItem('unapprovedTagsPresent', 'false');
          }
          this.enableOkBtn = true;
        } else {
        this.enableOkBtn = true;
        this.showCompletePersonalDataModal = false;
        localStorage.setItem('unapprovedTagsPresent', 'false');
        }
      });
    } else {
      this.enableOkBtn = true;
    }
  }

  ngOnInit() {
    this.dataService.getEmitter('tagging_validation').subscribe(
      (res: any) => {
        this.taggingError = res;
      }
    );
  }

  ngDoCheck() {
    const self = this;
    this.saveStatus = this.dataService.saveStatus;

    this.errorMsg = this.dataService.errorMsg;
    $('input,select,textarea').change(function () {
      self.dataService.saveStatus = null;
    });
    $('input,textarea').keyup(function () {
      self.dataService.saveStatus = null;
    });
    $('input,select,textarea').focus(function () {
      self.dataService.saveStatus = null;
    });
    $('.mycount_btns').click(function () {
      self.dataService.saveStatus = null;
    });
    $('input,select,textarea').blur(function () {
      self.dataService.saveStatus = null;
      const forms = document.getElementsByClassName('needs-validation');
      const validation = Array.prototype.filter.call(forms, function (form) {
        const isValid: boolean = form.checkValidity();

        if (isValid === false) {
          event.preventDefault();
          event.stopPropagation();
          form.classList.add('was-validated');
        }
      });
    });
  }

  reminderCompletePersonalData(btnName) {
    if (this.showCompletePersonalDataModal && this.ts.translateMe(btnName) === this.ts.translateMe('OK') &&
      localStorage.getItem('first_booking_present') === 'false') {
      localStorage.removeItem('first_booking_present');
      localStorage.removeItem('unapprovedTagsPresent');
      this.dataService.saveStatus = null;
      this.dataService.popup.title = 'complete_personal_data';        // Title name as per the language json key
      this.dataService.popup.type = 'complete-personal-data';
      // this.dataService.popup.data = session;
      this.dataService.popup.view = true;
      this.dataService.popup.class = 'bs-popup-size__completeProfile';
      this.dataService.popup.footer = false;
      this.dataService.popup.buttons.caption1 = '';
      this.dataService.popup.buttons.caption2 = '';
      this.dataService.popup.buttons.show1 = false;
      this.dataService.popup.buttons.show2 = false;
      this.dataService.popup.buttons.align = '';
      this.dataService.popup.buttons.disabled = true;
    } else {
      localStorage.removeItem('first_booking_present');
      localStorage.removeItem('unapprovedTagsPresent');
    }
  }
}
