import { Component, OnInit } from '@angular/core';
import { DataService } from '../../data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { popover } from 'bootstrap';
import { TranslateService } from '../../translate.service';

declare var jQuery:any;
declare var $ :any;

@Component({
  selector: 'me-section',
  templateUrl: './me.component.html'
})
export class MeComponent implements OnInit {
	pathway: any;
	user:any = null;
	cardSorts : any = [];
	coachee: any = {
		firstName: "",
		lastName: "",
		timezone: "",
		email: "",
		phoneOne: ""
	};
	meContact: any = {
		timezone: "",
		email: "",
		phoneOne: ""
	}
	isMobileView: boolean = false;

  	constructor(private route: ActivatedRoute, public dataService: DataService, private router: Router, private ts: TranslateService) { }

	ngOnInit() {
		this.pathway = JSON.parse(localStorage.getItem('pathway'));

		this.dataService.tabChangeStatus.subscribe((result) => {
			if (result) {
	    			this.pathway = result;
            		}
        	});

		const winWidth = window.innerWidth;
		if (winWidth <= 668) {
			this.isMobileView = true;
		} else {
			this.isMobileView = false;
		}
	}

	showPopOver() {
		// data-toggle="popover"
		// data-placement="top"
		// data-content="Text Here"

		$('#testPopover').click();
	}

	editProfile(meData:any) {
		this.dataService.saveStatus = null;
		this.dataService.popup.title = 'edit_profile';	    //Title name as per the language json key
		this.dataService.popup.type = "edit-profile";
		this.dataService.popup.data = meData;
		this.dataService.popup.view = true;
		this.dataService.popup.class = "bs-popup-size__editProfile";
		this.dataService.popup.footer = true;
		this.dataService.popup.buttons.caption1 = this.ts.translateMe('update');
		this.dataService.popup.buttons.caption2 = this.ts.translateMe('cancel');
		this.dataService.popup.buttons.show1 = true;
		this.dataService.popup.buttons.show2 = true;
		this.dataService.popup.buttons.align = "left";
		this.dataService.popup.buttons.disabled = false;
		this.dataService.emitData('tagging_validation', false);
	}

	myObjectives() {
		this.dataService.saveStatus = null;
		this.dataService.popup.title = 'my_ldrshift';	    //Title name as per the language json key
		this.dataService.popup.type = "my-objectives";
		this.dataService.popup.data = null;
		this.dataService.popup.view = true;
		this.dataService.popup.class = "bs-popup-size__myObjectives";
		this.dataService.popup.footer = true;
		this.dataService.popup.buttons.caption1 = this.ts.translateMe('submit');
		this.dataService.popup.buttons.caption2 = this.ts.translateMe('cancel');
		this.dataService.popup.buttons.show1 = true;
		this.dataService.popup.buttons.show2 = true;
		this.dataService.popup.buttons.align = "left";
		this.dataService.popup.buttons.disabled = false;
	}

	syncCalendar() {
		this.dataService.popup.title = this.ts.translateMe('sync_clndr');	    //Title name as per the language json key
		this.dataService.popup.type = "sync-calendar";
		this.dataService.popup.data = null;
		this.dataService.popup.view = true;
		this.dataService.popup.class = "bs-popup-size__calSynchronize";
		this.dataService.popup.footer = false;
		this.dataService.popup.buttons.caption1 = '';
		this.dataService.popup.buttons.caption2 = '';
		this.dataService.popup.buttons.show1 = false;
		this.dataService.popup.buttons.show2 = false;
		this.dataService.popup.buttons.align = "";
		this.dataService.popup.buttons.disabled = false;
	}

	myObjectives1() {
		this.dataService.saveStatus = null;
		this.dataService.popup.title = 'my_ldrshift_v2';	    //Title name as per the language json key
		this.dataService.popup.type = "my-objectives_v2";
		this.dataService.popup.data = null;
		this.dataService.popup.view = true;
		this.dataService.popup.class = "bs-popup-size__myObjectives";
		this.dataService.popup.footer = true;
		this.dataService.popup.buttons.caption1 = this.ts.translateMe('submit');
		this.dataService.popup.buttons.caption2 = this.ts.translateMe('cancel');
		this.dataService.popup.buttons.show1 = true;
		this.dataService.popup.buttons.show2 = true;
		this.dataService.popup.buttons.align = "left";
		this.dataService.popup.buttons.disabled = false;
	}

	ngDoCheck() {
		if(this.dataService.coachee) {
			this.coachee = this.dataService.coachee;
			this.meContact.timezone = '<i class="icon globe"></i> ' + this.coachee.timezone;
			this.meContact.email = '<i class="icon email-01"></i> <a href="mailto:' + this.coachee.email + '"> ' + this.coachee.email + '</a>';
			this.meContact.phoneOne = '<i class="icon phone-calling2"></i> ' + this.coachee.phoneOne;
			if(this.coachee.photo) {
				this.coachee.pic = this.dataService.coachServerUrl + this.coachee.photo;
			} else {
				this.coachee.pic = "./assets/images/no-image.png";
			}
		}
	}
}
