import { Component, Input, ViewChild, Directive, ElementRef, OnInit, OnDestroy } from '@angular/core';
import * as _ from 'lodash';
import * as Highcharts from 'highcharts/highcharts';
import { ChartDefaults } from './chartdefaults';
import { DataService } from '../data.service';

declare var require: any;
require('highcharts/highcharts-more')(Highcharts);

// const RE_RENDER_STACKEDCOLUMN_GRAPH = 'rerender-stackedcolumn-graph';

/**
* BarChart component
*
*/
@Component({
    selector: 'boxplot',
    templateUrl: './boxplot.html'
})

export class BoxplotChartComponent implements OnDestroy, OnInit {
    /**
    * Input binding for component data
    *
    */
    @Input() data: any;
    /**
    * Horizontal Alignment for chart legend
    *
    */
    private legendalign = 'center';
    /**
    * Vertical Alignment for chart legend
    *
    */
    private legendverticalalign = 'bottom';
    /**
    * Layout for chart legend
    *
    */
    private legendlayout = 'horizontal';
    /**
    * Highcharts chart object
    *
    */
    private chart: any = null;
    /**
    * The container inside which this chart is to be rendered
    *
    */
    private el: ElementRef = null;
    /**
    * Extra chart options
    *
    */
    public chartOptions: any;
    /**
    * Chart configuration data
    *
    */
    public chartData: any;

    /**
    * Constructor for BarChart component
    *
    * @param {ElementRef} [el] ElementRef instance
    * @param {ChartDefaults} [chartDefaults] ChartDefaults instance
    * @param {ParticipantAppService} [pas] ParticipantApp service instance
    *
    */
    constructor(el: ElementRef, private chartDefaults: ChartDefaults, private service: DataService) {
        const self = this;
        self.el = el;

        //HighchartsMore(Highcharts);
    }

    /**
    * OnDestroy lifecycle function to destroy the component
    *
    */
    ngOnDestroy() {
        if (this.chart) {
            this.chart.destroy();
        }
    }

    /**
    * OnInit lifecycle function to instantiate the component
    *
    */
    ngOnInit() {
        if (!this.chart) {
            this.renderGraph();
        }
    }

    /**
    * The function to render the graph after collecting and formatting all the data and chart options
    *
    */
    renderGraph() {
        const opt = {}, self = this;
        let appendFormat;
        
        this.chartData = this.chartDefaults.getBoxPlotChartDefaults();
        self.chartData.chart.height = "100";
        this.chartData.chart.renderTo = self.el.nativeElement.querySelector('.boxplot');
        this.chartData.chart.inverted = true;
        this.chartData.xAxis.visible = false;
        this.chartData.legend.enabled = false;
 
        this.chartData.tooltip.outside = true;
        let seriesData: any;
        for (let i = 0; i < this.data.length; i++) {
            if (i === 0) {
                seriesData = {
                    'tooltip': {
                        'headerFormat': null,
                    }
                }

                _.merge(this.data[i], seriesData); 
            } else if (i === 1) {
                seriesData = {
                    'tooltip': {
                        'pointFormat': '{point.y}'
                    },
                    'color': '#FF2452',
                    'type': 'scatter',
                    'marker': {
                        'fillColor': '#FF2452',
                        'lineWidth': 1
                    }
                }

                _.merge(this.data[i], seriesData); 
            }
        }

        this.chartData.series = this.data;

        if (this.data.chartConfig) {
            this.chartOptions = this.data.chartConfig;
        }

        _.merge(opt, this.chartData, this.chartOptions);

        this.chart = new Highcharts.Chart(opt);
    }

    /*reRenderGraph(chartData: any) {
        this.data = chartData.data;
        this.serieslabels = chartData.serieslabels;
        this.legendlabels = chartData.legendlabels;
        this.categorylabels = chartData.categorylabels;
        this.chartoptions = chartData.chartoptions;
        this.stacking = chartData.stacking;
        this.renderGraph();
    }*/
}
