import { Component, Input, OnInit } from '@angular/core';
import { DataService } from '../../data.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'myobj-mbir',
  templateUrl: './myobj-mbir.component.html'
})
export class MBIRComponent implements OnInit {
	pathwayId: number;
	mbirData: any = null;
	@Input() infoType: string;		//infoType = setting or review

	myMBIR:any = {
		"shift": null,
		"shiftAchieved": null,
		"reviewComments": null,
		"mindset": {
			"from": null,
			"to": null,
			"clientChoice": null,
			"review": null,
			"clientMindsetOptions": null
		},
		"behaviour": {
			"from": null,
			"to": null,
			"clientChoice":null,
			"review": null,
			"clientBehaviorOptions": null
		},
		"impact": {
			"from": null,
			"to": null,
			"clientChoice": null,
			"review": null,
			"clientImpactOptions": null
		},
		"results": {
			"to": null,
			"review": null,
			"resultsOptions": null
		}
	};

	constructor(private route: ActivatedRoute, private dataService: DataService, private router: Router) {
		//
	}

	ngOnInit() {
		if(this.pathwayId != this.dataService.pathwayId) {
			this.pathwayId = this.dataService.pathwayId;
			this.getMBIR();
		}
	}

	getMBIR(){
		this.dataService.getMBIR(this.pathwayId).subscribe(
			result => {
				this.myMBIR = this.cleanMBIRData(result.mbir);
				// console.log(this.myMBIR);
			},
			error => {
				// console.log(error);
			}
		);
	}

	cleanMBIRData(mbirData:any) {
		return {
			"shift": this.dataService.cleanInputs(mbirData.shift),
			"shiftAchieved": this.dataService.cleanInputs(mbirData.shiftAchieved),
			"reviewComments": this.dataService.cleanInputs(mbirData.reviewComments),
			"mindset": {
				"from": this.dataService.cleanInputs(mbirData.mindset.from),
				"to": this.dataService.cleanInputs(mbirData.mindset.to),
				"clientChoice": this.dataService.cleanInputs(mbirData.mindset.clientChoice),
				"review": this.dataService.cleanInputs(mbirData.mindset.review),
				"clientMindsetOptions": mbirData.mindset.clientMindsetOptions
			},
			"behaviour": {
				"from": this.dataService.cleanInputs(mbirData.behaviour.from),
				"to": this.dataService.cleanInputs(mbirData.behaviour.to),
				"clientChoice": this.dataService.cleanInputs(mbirData.behaviour.clientChoice),
				"review": this.dataService.cleanInputs(mbirData.behaviour.review),
				"clientBehaviorOptions": mbirData.behaviour.clientBehaviourOptions
			},
			"impact": {
				"from": this.dataService.cleanInputs(mbirData.impact.from),
				"to": this.dataService.cleanInputs(mbirData.impact.to),
				"clientChoice": this.dataService.cleanInputs(mbirData.impact.clientChoice),
				"review": this.dataService.cleanInputs(mbirData.impact.review),
				"clientImpactOptions": mbirData.impact.clientImpactOptions
			},
			"results": {
				"to": this.dataService.cleanInputs(mbirData.results.to),
				"review": this.dataService.cleanInputs(mbirData.results.review),
				"resultsOptions": mbirData.results.resultsOptions
			}
		};
	}

	saveMBIR() {
		var form: any = document.getElementsByClassName('ng-invalid');
		if(form.length) {
			return false;
		} else {
			this.dataService.saveStatus = "processing";
		}

		this.myMBIR = this.cleanMBIRData(this.myMBIR);

		this.dataService.saveMBIR(this.myMBIR).subscribe(
		result => {
			if(this.dataService.popup.data) {
				this.markSessionComplete(this.dataService.popup.data);
			}

			this.getMBIR();
			this.dataService.saveStatus = "saved";
		},
		error => {
			// console.log(error);
			this.dataService.saveStatus = "error";
		}
		);
	}

	markSessionComplete(activity:any) {
		if(this.dataService.pulseType(activity.type, null)) return;

		if(!activity.complete){
			this.dataService.unbookActivity(activity.id, true)
			.subscribe(result => {
				activity.complete = result.activity.complete;
			},
			error => {
				// console.log(error);
			});
		}
	}

	ngDoCheck() {
		if(this.pathwayId != this.dataService.pathwayId){
			this.pathwayId = this.dataService.pathwayId;
			this.getMBIR();
		}

		this.dataService.getEmitter("save-mbir").subscribe((result) => {
			if(result == "true" && !this.dataService.saveStatus) {
				this.dataService.emitData("save-mbir", null);
				this.saveMBIR();
			}
		});
	}
}
