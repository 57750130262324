import { Component, OnInit } from '@angular/core';

// Timeline Sessions Preloader
@Component({
  selector: 'app-session-loader',
  template: `<div class="timeline-vline">
		<div class="timeline-lineHeader coach-brandBg-primary">{{'timeline' | translate}}</div>

		<div class="timeline-unBookedSession">
			<div class="popover popover-right popover-default">
				<div class="timeline__timeSlot">
					<span class="timeline__timeSlot-iconPointX float-right">
						<img src="./assets/images/preloader.gif" height="24">
					</span>
				</div>
				<div class="popover-content">
					<div class="timeline-columnView">
						<div class="timeline-details">
							<h5 class="h5">{{'loading' | translate}}</h5>
							<div class="timeline-duration">
								<i class="icon duration-clock"></i> &nbsp;
								<em>{{'wait' | translate}}</em>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="timeline-endPoint"><!-- End Point of Timeline --></div>
	</div>`
})
export class SessionLoaderComponent {
}
