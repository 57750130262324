import { Component, OnInit } from '@angular/core';
import { DataService } from '../../data.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'my-objectives',
  templateUrl: './my-objectives.component.html'
})
export class MyObjectivesComponent implements OnInit {
	showInfo: boolean = null;
	tabActive: string = "setting";		//Only setting / review
	
  	constructor(private route: ActivatedRoute, private dataService: DataService, private router: Router) { }

	ngOnInit() {
		if(localStorage.getItem("tips") != "read") {
			this.showInfo = true;
		}
	}

	toggleTips() {
		if(this.showInfo) {
			this.closeTips();
		} else {
			this.showInfo = true;
		}
	}

	closeTips() {
		this.showInfo = false;
		localStorage.setItem("tips", "read");
	}
}
