import { Component, Input, ViewChild, ElementRef, AfterViewInit, OnInit, Inject } from '@angular/core';
import { DataService } from '../../../data.service';
import { TLService } from '../timeline.service';
import { ActivatedRoute } from '@angular/router';
import { LOAD_NEW_PATHWAY, TimelineComponent } from '../timeline.component';
import { TranslateService } from '../../../translate.service';

// Timeline Session Details
@Component({
    selector: 'app-session-info',
    templateUrl: './session-info.component.html'
})
export class SessionInfoComponent implements OnInit {
    @Input() s;
    @Input() cnt;
    @Input() groups: boolean;
    @ViewChild('childAccr', null) childAccr: any;
    @Input() tipele;
    @Input() timeslotEle;
    @Input() allActivities;
    callDetails: any = {};
    height: string;
    el: ElementRef;
    pathway: any;
    activityId: any;
    pathwayId: number;
    reminders: any;
    grpNo: any = null;

    constructor(
        public dataService: DataService,
        public tls: TLService,
        private route: ActivatedRoute,
        private timeLineComp: TimelineComponent,
        private ts: TranslateService
    ) {
    }

    ngOnInit() {
        const self = this;
        if (this.timeslotEle) {
            this.timeslotEle.className = 'timeline__timeSlot timeline__timeSlot-collapsed';
            this.timeslotEle.classList.value = 'timeline__timeSlot timeline__timeSlot-collapsed';
        }

        if (this.tipele) {
            this.tipele.className = 'popover-content-tip popover-content-tip-collapsed';
            this.tipele.classList.value = 'popover-content-tip popover-content-tip-collapsed';
        }

        this.pathway = null;
        this.reminders = this.dataService.reminders;

        setTimeout(function() {
            if (!self.tls.isAllMarked(self.s) && !self.tls.oneGroupExpand) {
                self.tls.oneGroupExpand = true;
                self.collexpClick(self.cnt);    // self.cnt val is updating in html page
            }
        }, 600);
    }

    public collexpClick(cnt) {
        this.grpNo = this.grpNo != null ? null : cnt;
        this.calcHeight();
        this.autoScrollGroup(cnt);
    }

    autoScrollGroup(cnt) {
        // Auto scroll expanded group on top
        const tlbox: any = document.getElementsByClassName('tlbox');
        const tlcY: any = document.getElementsByClassName('timeline-cont')[0];
        const tlbox2: any = document.getElementsByClassName('tlbox')[cnt];
        tlcY.scrollTop = tlbox2 ? tlbox2.offsetTop : 0;
    }

    calcHeight() {
        // this.timeslotEle.classList.value = this.timeslotEle.classList.value === 'timeline__timeSlot timeline__timeSlot-collapsed' ?
        // 'timeline__timeSlot timeline__timeSlot-opened' : 'timeline__timeSlot timeline__timeSlot-collapsed';
        if (this.timeslotEle.classList.value === 'timeline__timeSlot timeline__timeSlot-collapsed') {
            this.timeslotEle.classList.remove('timeline__timeSlot-collapsed');
            this.timeslotEle.classList.add('timeline__timeSlot-opened');
        } else {
            this.timeslotEle.classList.remove('timeline__timeSlot-opened');
            this.timeslotEle.classList.add('timeline__timeSlot-collapsed');
        }

        if (this.tipele.classList.value === 'popover-content-tip popover-content-tip-collapsed') {
            this.tipele.classList.remove('popover-content-tip-collapsed');
            this.tipele.classList.add('popover-content-tip-opened');
        } else {
            this.tipele.classList.remove('popover-content-tip-opened');
            this.tipele.classList.add('popover-content-tip-collapsed');
        }
        // this.tipele.classList.value = this.tipele.classList.value === 'popover-content-tip popover-content-tip-collapsed' ?
        // 'popover-content-tip popover-content-tip-opened' : 'popover-content-tip popover-content-tip-collapsed';
        // console.log(parentHost, this.tipele)
        // console.log('popover-content-tip after', this.tipele.classList.value)
        let tempHeight = 0;
        if (this.childAccr) {
            for (const x of this.childAccr.nativeElement.children) {
                // console.log(this.childAccr.nativeElement.children.length * x.clientHeight + 40 + 'px');
                tempHeight += x.clientHeight + 15;
            }
        }

        this.height = tempHeight + 'px';
    }

    isNeedButton(session) {
        // for (var i=0; i<=session.activities.length; i++) {
            // var act = session.activities[i];
            const act = session;

            if ((act.sessionType === 'GroupCoachingSession' ||
                act.sessionType === 'PhoneCallCoachingSession' ||
                act.sessionType === 'VirtualPhoneCallCoachingSession' ||
                act.sessionType === 'VirtualGroupCoachingSession' ||
                act.sessionType === 'ThreeWayCoachingSession' ||
                act.sessionType === 'VirtualThreeWayCoachingSession' ||
                act.sessionType === 'Group Coaching Session' ||
                act.sessionType === 'Phone Call Coaching Session' ||
                act.sessionType === 'Virtual Phone Call Coaching Session' ||
                act.sessionType === 'Virtual Group Coaching Session' ||
                act.sessionType === 'Three Way Coaching Session' ||
                act.sessionType === 'Virtual Three Way Coaching Session') &&
                (act.status === 'Not Yet Booked' || act.status === 'Missed' || act.status === null)) {
                return true;
            }
        // }
    }

    getTimeDuration(session) {
        let duration = 0;

        if (session.hasOwnProperty('estimatedTime')) {
            duration = session.estimatedTime ? session.estimatedTime : 0;
        } else {
            duration = session.duration ? session.duration : 0;
        }

        return duration;
    }

    bookDetails(session) {
        this.dataService.saveStatus = null;
        this.dataService.popup.title = session.name;
        this.dataService.popup.type = 'booking-details';
        this.dataService.popup.data = {
            'sessionId': session.id,
            'details': session.details
        };
        this.dataService.popup.view = true;
        this.dataService.popup.class = 'bs-popup-size__noPathways';
        this.dataService.popup.footer = false;
        this.dataService.popup.buttons.caption1 = '';
        this.dataService.popup.buttons.caption2 = '';
        this.dataService.popup.buttons.show1 = false;
        this.dataService.popup.buttons.show2 = false;
        this.dataService.popup.buttons.align = '';
        this.dataService.popup.buttons.disabled = true;
    }

    download(slink: string) {
        if (slink && this.dataService.isLiveUrl(slink)) {
            // var filename = slink.replace(/^.*[\\\/]/, '');
            // slink = this.dataService.pdfUrl + filename;

            window.open(slink, '_blank');
        } else {
            alert(this.ts.translateMe('invalid_dlink'));
            return false;
        }
    }

    bookSession(session: any) {
        this.dataService.saveStatus = null;
        this.dataService.popup.title = 'book_session';    // Title name as per the language json key
        this.dataService.popup.type = 'book-session';
        this.dataService.popup.data = session;
        this.dataService.popup.view = true;
        this.dataService.popup.class = 'bs-popup-size__bookSession';

        if (session.sessionType === 'GroupCoachingSession' || session.sessionType === 'Group Coaching Session') {
            this.dataService.popup.footer = true;
            this.dataService.popup.buttons.caption1 = '';
            this.dataService.popup.buttons.caption2 = this.ts.translateMe('ok');
            this.dataService.popup.buttons.show1 = false;
            this.dataService.popup.buttons.show2 = true;
            this.dataService.popup.buttons.align = 'center';
            this.dataService.popup.buttons.disabled = false;
        } else {
            this.dataService.popup.footer = false;
            this.dataService.popup.buttons.caption1 = '';
            this.dataService.popup.buttons.caption2 = '';
            this.dataService.popup.buttons.show1 = false;
            this.dataService.popup.buttons.show2 = false;
            this.dataService.popup.buttons.align = '';
            this.dataService.popup.buttons.disabled = true;
        }
    }
}
