import { Injectable } from '@angular/core';
declare var require;

@Injectable()
  export class TranslateService {
  data: any = {};

  constructor() {}

  use(lang: string): Promise<{}> {
    return new Promise<{}>((resolve, reject) => {
      const langPath = `coach-${lang || 'en'}.json`;
      const langData = require('../assets/lang/' + langPath);
      this.data = Object.assign({}, langData || {});
      resolve(this.data);
    });
  }

  translateMe(key: any): any {
    return this.data[key] || key;
  }
}
