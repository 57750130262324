import { Injectable } from '@angular/core';
import { DataService } from '../data.service';
import { TranslateService } from '../translate.service';

declare var renderSurveyPage: any;
declare var jQuery: any;
declare var $: any;
declare var require;

@Injectable()
export class BFService {
	steps: any = [];
	bfbStatus: any = [{
		"status_no": 0,
		"status_name": "Incomplete",
		"status_desc": "Needs action from Coachee"
	}, {
		"status_no": 1,
		"status_name": "Incomplete",
		"status_desc": "Needs action from Coachee"
	}, {
		"status_no": 2,
		"status_name": "Submitted",
		"status_desc": "Coachee has entered behaviors, respondents; ready for coach review"
	}, {
		"status_no": 3,
		"status_name": "Ready to Send",
		"status_desc": "Coach approved (or standard behaviors)"
	}, {
		"status_no": 4,
		"status_name": "Active",
		"status_desc": "Notification have been sent, not all responders completed"
	}, {
		"status_no": 5,
		"status_name": "Closed",
		"status_desc": "All respondents have responded"
	}];

	bList: any = [];		// Behaviours List
	// MBIR From / To for behaviour page
	mbirft: any = {
		"from": null,
		"to": null
	}
	// Respondents List
	rList_name: any = [];
	rList_email: any = [];
	rList_role: any = [];
	// rList_link:any = [];

	email_subject: string = "";
	email_body: string = "";
	email_fixedBody1: string = "";
	email_fixedBody2: string = "";
	surveySubmitted: boolean = false;
	// isRoundDone:boolean = false;
	roundStatus: any = [];

	constructor(private dataService: DataService, private ts: TranslateService) {
		this.updateStatusData();
	}

	updateStatusData() {
		const langData:any = require('../../assets/lang/coach-'+ this.dataService.curLang +'.json');

		// Set the default array value on 0 row
		// this.steps.push(langData.bfb_steps[1]);
		this.steps[0] = langData.bfb_steps[0];
		this.steps[0].step_no = 0;
		this.bfbStatus[0].status_name = langData.bfb_status[0];

		// Set the existing array value with the language specific value
		for(let i=1; i<6; i++) {
			this.steps[i] = langData.bfb_steps[i-1];
			this.bfbStatus[i].status_name = langData.bfb_status[i-1];
		}
	}

	// Hide Validation Indications after 3 seconds
	hideVMsg() {
		var self = this;
		var frm: any = document.getElementsByClassName('needs-validation');		
		var wa: any = document.getElementsByClassName('was-validated');
		if (wa) {
			setTimeout(function() {
				if(frm[0] && frm[0].classList) frm[0].classList.remove('was-validated');
			}, 3000);
		}
	}

	loadSurveyHTML(roundNo: number, pgRender: boolean) {
		return new Promise((resolve, reject) => {
			this.surveySubmitted = false;
			this.dataService.getSurveyHTML(roundNo).subscribe(res => {
				if (res.success) {
					var resData: any = null;
					var dataLen: number = res.data.length;
					if (dataLen > 0 && dataLen == 1) {
						if (res.data[0].template.custom.pathwayId == this.dataService.pathwayId) {
							resData = res.data[0];
						}
					}

					if (dataLen > 1) {
						for (var i = 0; i < dataLen; i++) {
							if (res.data[i].template.custom.pathwayId == this.dataService.pathwayId) {
								resData = res.data[i];
								break;
							}
						}
					}

					this.surveySubmitted = resData && resData.submitted || false;

					if (pgRender) {
						if(resData) {
							renderSurveyPage(resData, this.dataService.pulseSurveyUrl);
						} else {
							res.success = false;
							res.msg = '{"name": "NoData"}';
						}
						
						resolve(res);
						return res;
					} else {
						resolve(res);
						return res;
					}
				} else {
					resolve(res);
					return res;
				}
			},
			error => {
				resolve(error);
				return error;
			});
		});
	}

	/*getRoundStatus(sections:any) {
		return new Promise((resolve, reject) => {
			let newIdx:any = null;
			let activity:any = null;

			newIdx = this.completedRoundIdx(sections);

			if (newIdx) {
				activity = sections[newIdx.secIdx].activities[newIdx.actIdx];

				this.getActivityDetails(activity).then((result:any) => {
					if(result) {
						resolve(result);
						return result;
					}
				});
			} else {
				resolve();
				return;
			}
		});
	}*/

	completedRoundIdx(sections:any) {
		let secIdx:number = 0;
		let act:any = null;
		let retVal:any = null;

		for(var s=0; s<sections.length; s++) {
			act = sections[s].activities;

			for(var a=0; a<act.length; a++) {
				if (act[a].type == "BenchFeedback" && act[a].complete != "Completed") {
					retVal = {"secIdx": s, "actIdx": a};
					break;
				}
			}

			if(retVal) break;
		}

		// return retVal || {"secIdx": s-1 < 0 ? 0 : s-1, "actIdx": a-1 < 0 ? 0 : a-1};
		return retVal;
	}

	getActivityDetails(activity:any) {
		return new Promise((resolve, reject) => {
			let rStatus: any = {};
			rStatus.roundNo = activity.round;
			rStatus.activity = activity;
			this.updateStatusData();

			// If Coach has rejected behaviours then come to the first step in edit mode
			if (activity.behaviours && activity.behaviours.coachApproved == "Rejected") {
				rStatus.statusAmend = true;
				rStatus.stepNo = 1;
				rStatus.stepName = this.steps[1].step_name;
				rStatus.stepBarActive = this.steps[1].step_no;
				rStatus.statusName = this.bfbStatus[1].status_name;
				rStatus.statusBarActive = this.bfbStatus[1].status_no;
				resolve(rStatus);
				return rStatus;
			} else if(activity.behaviours && !activity.behaviours.submitted) {
				rStatus.stepNo = 1;
				rStatus.stepName = this.steps[1].step_name;
				rStatus.stepBarActive = this.steps[1].step_no;
				rStatus.statusName = this.bfbStatus[1].status_name;
				rStatus.statusBarActive = this.bfbStatus[1].status_no;
				resolve(rStatus);
				return rStatus;
			} else if(activity.respondents && !activity.respondents.submitted) {
				rStatus.stepNo = 2;
				rStatus.stepName = this.steps[2].step_name;
				rStatus.stepBarActive = this.steps[2].step_no;
				rStatus.statusName = this.bfbStatus[2].status_name;
				rStatus.statusBarActive = this.bfbStatus[2].status_no;
				resolve(rStatus);
				return rStatus;
			} else if(activity.personalisedEmail && !activity.personalisedEmail.submitted) {
				// Respondents Added, so move on Email Details Page
				if (activity.respondents.list.length >= 5) {
					rStatus.statusDone = true;
				} else {
					rStatus.statusDone = false;
					rStatus.respCnt = activity.respondents.list.length + '/5'
				}

				rStatus.stepNo = 3;
				rStatus.stepName = this.steps[3].step_name;
				rStatus.stepBarActive = this.steps[3].step_no;
				rStatus.statusName = this.bfbStatus[3].status_name;
				rStatus.statusBarActive = this.bfbStatus[3].status_no;
				resolve(rStatus);
				return rStatus;
			} else {
				return this.loadSurveyHTML(activity.round, false).then((res1: any) => {
					if (res1 && this.surveySubmitted) {
						this.isRoundDone(activity, rStatus).then((res2: any) => {
							if(res2) {
								resolve(res2);
								return res2;								
							}
						}).catch(()=>{
							rStatus.stepNo = 5;
							rStatus.stepName = this.steps[4].step_name;
							rStatus.stepBarActive = this.steps[4].step_no;
							rStatus.statusName = this.bfbStatus[4].status_name;
							rStatus.statusBarActive = this.bfbStatus[4].status_no;
							resolve(rStatus);
							return rStatus;
						});
					} else {
						// Email Details is submitted, so move on Self Rating Page
						rStatus.stepNo = 4;
						rStatus.stepName = this.steps[4].step_name;
						rStatus.stepBarActive = this.steps[4].step_no;
						rStatus.statusName = this.bfbStatus[4].status_name;
						rStatus.statusBarActive = this.bfbStatus[4].status_no;
						resolve(rStatus);
						return rStatus;
					}
				});
			}
		});
	}


	isRoundDone(activity: any, rStatus:any) {
		return new Promise((resolve, reject) => {
			this.dataService.getAnonymisedResults(activity.id, rStatus.roundNo).subscribe(res => {
				if (res) {
					let pathway:any = JSON.parse(localStorage.getItem('pathway'));
					let coachee:any = this.dataService.coachee || pathway.coachee;

					let totRes = 0;
					let totRespondents = 0;

					if (res.status) {
						totRes = res.status ? res.status.total_response_count : 0;
						totRespondents = res.status ? res.status.respondent_count : 0;
					}

					rStatus.stepNo = 5;
					rStatus.stepName = this.steps[5].step_name;
					rStatus.stepBarActive = this.steps[5].step_no;

					// Survey / Self Rating is submitted, so move on the Result Page
					if (totRespondents > 0 && totRes < totRespondents) {
						rStatus.respCnt = totRes + '/' + totRespondents;
						rStatus.statusDone = false;
						rStatus.stepDone = false;
						rStatus.statusName = this.bfbStatus[4].status_name;
						rStatus.statusBarActive = this.bfbStatus[4].status_no;

						resolve(rStatus);
						return rStatus;
					} else {
						rStatus.respCnt = null;
						rStatus.statusDone = true;
						rStatus.stepDone = true;
						rStatus.statusName = this.bfbStatus[5].status_name;
						rStatus.statusBarActive = this.bfbStatus[5].status_no;

						resolve(rStatus);
						return;
					}
				}
			},
			error => {
				// console.log(error);
				reject(false);
			});
		});
	}

}