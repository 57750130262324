import { Injectable } from '@angular/core';
import { DataService } from '../../data.service';
import { TranslateService } from '../../translate.service';

import * as moment from 'moment';
import * as momentTimezone from 'moment-timezone';

@Injectable()
export class TLService {
  // bsCO: any = null;
  oneGroupExpand = false;
  track: number = null;

  constructor(private dataService: DataService, private ts: TranslateService) { }

  getColorSet(session: any) {
    let clr: string = null;

    if (!session || !this.getIconImage(session)) {
      return;
    }

    // if (this.bookingDateTime(session) && session.status === 'Completed') {
    //   clr = 'green';
    // } else
    if (session.complete || (session.status && session.status === 'Completed') || session.is_complete) {
      clr = 'gray';
    // } else if (session.details && this.getBSCOCompleted(session.details.coName)) {
    //   clr = 'green';
    } else if (this.bookingDateTime(session) && session.status === 'Booked') {
      clr = 'blue';
    } else if (session.status === 'Missed') {
      clr = 'red';
    } else {
      clr = 'default';
    }

    return 'popover-' + clr;
  }

  /*getBSCOCompleted(sdco_name: string) {
    if (!this.bsCO) return;

    for (let i = 0; i < this.bsCO.length; i++) {
      if (this.bsCO[i].coName === sdco_name) {
        return this.bsCO[i].completed;
      }
    }
    return false;
  }*/

  isAllMarked(s) {
    const noLink = true;
    const activities: any = s.activities || s;

    if (!activities) {
      return false;
    }

    if (activities.length > 0) {
      for (let i = 0; i < activities.length; i++) {
        const actVt: any = activities[i];

        // if (!actVt.complete || (actVt.link && !actVt.complete) || actVt.complete != 'Completed') {
        if (actVt.complete !== 'Completed' && actVt.status !== 'Completed' && actVt.status !== 'Greyed Out') {
          return false;
        }
      }
    } else {
      const actVt: any = activities;

      // if (!actVt.complete || (actVt.link && !actVt.complete) || actVt.complete != 'Completed') {
      if (actVt.complete !== 'Completed' && actVt.status !== 'Completed' && actVt.status !== 'Greyed Out') {
        return false;
      }
    }

    return true;
  }

  getIconImage(session: any) {
    let retVal: any = null;
    // activityType = type now
    switch (session.type || session.pre_work_type_name) {
      case 'Audio/Video':
      case 'Video':
        retVal = 'timeline-icon__video';
        break;
      case 'Insight':
        retVal = 'timeline-icon__insight';
        break;
      case 'Card Sort':
      case 'CardSort':
      case 'CardSortV2':
        retVal = 'timeline-icon__cards';
        break;
      case 'MBIR':
      case 'Mbir':
      case 'MBIRV2':
      case 'MbirV2':
        retVal = 'timeline-icon__mbir';
        break;
      case 'Exercise':
      case 'Material':
      case 'Action':
      case 'CiaB Material':
      case 'EventActivity':
      case 'PodSession':
      case 'Feedback':
      case 'BenchFeedback':
        // retVal = 'timeline-icon__material';
        retVal = 'timeline-icon__objectives';
        break;
      case 'Diagnostic':
      case 'CiaB Diagnostic':
      case 'Hints and Tips':
        retVal = 'timeline-icon__diagnostic';
        break;
      case 'Outcomes':
        retVal = 'timeline-icon__outcomes';
        break;
      case 'PulseAssessmentCoachingSession':
      case 'Pulse Assessment Coaching Session':
      case 'PulseSession':
        retVal = 'timeline-icon__assessment';
        break;
      case 'PulseContent':
      case 'Pulse Content':
      case 'Introduction':
        retVal = 'timeline-icon__content';
        break;
      case 'Other':
      case 'Isomer':
        retVal = 'timeline-icon__isomer';
        break;
        /*if (session.activityText === 'Outcomes')
          retVal = 'timeline-icon__outcomes';
        else if (session.activityText === 'Insight')
          retVal = 'timeline-icon__insight';*/
        break;
      case 'Shadow':
        retVal = 'timeline-icon__shadow';
        break;
      default:
        session.sessionType = session.sessionType || session.pre_work_type_name;

        if (session.sessionType === 'Non Bookable Coaching Session' ||
          session.sessionType === 'Three Way Coaching Session' ||
          session.sessionType === 'Virtual Three Way Coaching Session') {
          retVal = 'timeline-icon__callSession';
        } else if (session.sessionType === 'Phone Call Coaching Session' ||
          session.sessionType === 'Virtual Phone Call Coaching Session') {
          retVal = 'timeline-icon__phone';
        } else if (session.sessionType === 'Face To Face Coaching Session' ||
          session.sessionType === 'Virtual Face To Face Coaching Session') {
          retVal = 'timeline-icon__face2face';
        } else if (session.sessionType === 'Group Coaching Session' ||
          session.sessionType === 'Virtual Group Coaching Session') {
            retVal = 'timeline-icon__group';
        } else if (session.name === 'Hints and Tips' || session.title === 'Hints and Tips') {
          retVal = 'timeline-icon__objectives';
        }
        break;
    }

    return retVal;
  }

  getIconTitle(session: any) {
    const retVal: any = session.sessionType ? session.sessionType : session.type;

    return retVal;
  }

  bookingDateTime(act: any) {
    return (act.booking && act.booking.dateTime) || (act.podDetails && act.podDetails.booking && act.podDetails.booking.dateTime);
  }

  getSessionID(sessions: any, ds: any) {
    const tDate = new Date();
    let dDiff: number = null;
    let retVal: number = null;

    for (let x = 0; x <= 30; x++) {
      for (let i = 0; i < sessions.length; i++) {
        if (this.bookingDateTime(sessions[i])) {
          const bDate = this.bookingDateTime(sessions[i]);

          dDiff = ds.dateDiff_inDays(tDate, bDate);

          if (dDiff === x) {
            retVal = sessions[i].id;
            break;
          }
        }
      }

      if (retVal) {
        break;
      }
    }

    if (retVal) {
      return retVal;
    }
  }

  dateFormat(bookingDate, isTime: boolean, ds: any) {
    let coacheeDate: any = null;
    // const defaultDate = moment.utc(bookingDate).format('DD MMM, YYYY~HH:mm:A');
    if (this.dataService.curLang === 'ko') {
      coacheeDate = momentTimezone.tz(bookingDate, ds.coachee.timezone).format('YYYY/MM/DD');
    } else {
      coacheeDate = momentTimezone.tz(bookingDate, ds.coachee.timezone).format('DD MMM, YYYY');
    }
    const coacheeTime1 = momentTimezone.tz(bookingDate, ds.coachee.timezone).format('HH:mm');
    const coacheeTime2 = momentTimezone.tz(bookingDate, ds.coachee.timezone).format('h:mm a');

    // const coachDate = momentTimezone.tz(bookingDate, ds.coach.timezone).format('DD MMM, YYYY~HH:mm:A');
    // const bDate: string = coacheeDate;
    // const dtm = bDate.split('~');
    // const newDate = dtm[0];
    // const tm = dtm[1].split(':');

    // const newTime = tm[0] + ':' + tm[1] + ' (';
    // newTime += (parseInt(tm[0]) > 12) ? (parseInt(tm[0]) - 12) + ':' + tm[1] : tm[0] + ':' + tm[1];
    // newTime += tm[2] + ')';
    // return isTime ? newTime : newDate;

    return isTime ? coacheeTime1 + ' (' + coacheeTime2 + ')' : coacheeDate;
  }

  joinCall(session: any, ds: any, bypass: boolean) {
    if (this.callStatus(session, ds) !== 'active' && !bypass) {
      return;
    }

    if (session.booking.id) {
      if (ds.coach.photo) {
        ds.coach.pic = ds.coachServerUrl + ds.coach.photo;
      } else {
        ds.coach.pic = './assets/images/no-image.png';
      }

      const callDetails = {
        callId: session.booking.id,
        session_title: session.name,
        name: ds.coach.name,
        name2: ds.coachee.firstName + ' ' + ds.coachee.secondName,
        email: ds.coach.email,
        timezone: ds.coach.timezone,
        phone1: ds.coach.phone1,
        phone2: ds.coach.phone2,
        pic: ds.coach.pic || './assets/images/no-image.png',
        isScheduledCall: true
      };

      ds.emitData('call-popup', callDetails);
    } else {
      alert('Required booking id');
    }
  }

  callStatus(session: any, ds: any) {
    const dateTime = this.bookingDateTime(session);

    if (dateTime) {
      const todayDate = new Date();
      const bookingDate = new Date(dateTime);
      // const a = moment(todayDate);
      // const b = moment(dateTime);
      const adt = momentTimezone.tz(todayDate, ds.coachee.timezone).format('YYYY-MM-DD HH:mm');
      const bdt = momentTimezone.tz(dateTime, ds.coachee.timezone).format('YYYY-MM-DD HH:mm');
      const a = moment(adt);
      const b = moment(bdt);
      const diff = a.diff(b, 'minutes');
      const timeDuration: number = session.duration || 15;

      // For Testing Purpose ...
      /*if(this.track && this.track > 0) {
        const timeDuration: number = this.track;
        console.log(diff + ' >= 0 && ' + diff + ' <= ' + timeDuration);
      }*/

      if (diff >= 0 && diff <= timeDuration) {
        return 'active';
      } else if (diff > timeDuration) {
        return 'missed';
      } else {
        return null;
      }
    }
  }

  zoomCallClick(zoomLink) {
    if (zoomLink) {
      window.open(zoomLink, '_blank');
    }
  }

  bookSession(session: any) {
    this.dataService.saveStatus = null;

    //Title name as per the language json key
    if(this.dataService.popup.viewSlots) {
      this.dataService.popup.title = 'asch_options';
    } else {
      this.dataService.popup.title = 'book_session';
    }

    this.dataService.popup.type = 'book-session';
    this.dataService.popup.data = session;
    this.dataService.popup.view = true;
    this.dataService.popup.class = 'bs-popup-size__bookSession';

    if (session.sessionType === 'GroupCoachingSession' ||
      session.sessionType === 'Group Coaching Session' ||
      session.type === 'PulseSession') {
      this.dataService.popup.footer = true;
      this.dataService.popup.buttons.caption1 = '';
      this.dataService.popup.buttons.caption2 = this.ts.translateMe('ok');
      this.dataService.popup.buttons.show1 = false;
      this.dataService.popup.buttons.show2 = true;
      this.dataService.popup.buttons.align = 'center';
      this.dataService.popup.buttons.disabled = false;
    } else {
      this.dataService.popup.footer = false;
      this.dataService.popup.buttons.caption1 = '';
      this.dataService.popup.buttons.caption2 = '';
      this.dataService.popup.buttons.show1 = false;
      this.dataService.popup.buttons.show2 = false;
      this.dataService.popup.buttons.align = '';
      this.dataService.popup.buttons.disabled = true;
    }
  }

  getSessionCounts(section: any) {
    let cntAct = 0;
    let cntSsn = 0;
    let activity: any = null;
    let actVal: any = '';
    let SsnVal: any = '';
    let langCode = '';

    for (let a = 0; a < section.activities.length; a++) {
      activity = section.activities[a];

      if (activity.hasOwnProperty('booking')) {
        cntSsn++;
      } else {
        cntAct++;
      }
    }

    if (cntAct > 0) {
      if (cntAct <= 1) {
        actVal = cntAct + ' Activity and ';
      } else {
        actVal = cntAct + ' Activities and ';
      }
    }

    if (cntSsn <= 1) {
      SsnVal = cntSsn + ' Session';
      langCode = 'actssn';
    } else {
      SsnVal = cntSsn + ' Sessions';
      langCode = 'actsssns';
    }

    // return actVal + SsnVal;
    return this.ts.translateMe(langCode);
  }

  remindSessionClick(e, ds: any) {
    if (e.target.rel) {
      const sessionId: number = e.target.rel;
      const pathwayId = ds.pathwayId;

      ds.getPathwayDetails(pathwayId).subscribe(
        result => {
          if (result.pathway.sections) {
            result.pathway.sections.forEach(innerSection => {
              innerSection.activities.forEach(session => {
                if (session.id === Number(sessionId)) {
                  if (session.sessionType === 'NonBookableCoachingSession' || session.sessionType === 'Non Bookable Coaching Session') {
                    ds.popup.title = session.name;
                    ds.popup.type = 'booking-details';
                    ds.popup.data = {
                      'sessionId': session.id,
                      'details': session.details
                    };
                    ds.popup.class = 'bs-popup-size__noPathways';
                  } else {
                    ds.popup.title = 'book_session';        // Title name as per the language json key
                    ds.popup.type = 'book-session';
                    ds.popup.data = session;
                    ds.popup.class = 'bs-popup-size__bookSession';
                  }

                  ds.popup.buttons.show1 = false;
                  ds.popup.buttons.show2 = false;
                  ds.popup.footer = false;
                  ds.popup.view = true;
                  ds.popup.buttons.disabled = false;
                  this.dataService.saveStatus = null;

                  return;
                }
              });
            });
            const sessions = result.pathway.sessions;
          }
        },
        error => {
          // console.log(error);
        }
      );
    }

    return false;
  }
}
