import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

declare var cordova;
declare var device;

if (environment.production) {
  enableProdMode();
}

function initPushwoosh() {
  const pushwoosh = cordova.require('pushwoosh-cordova-plugin.PushNotification');
  // console.log(pushwoosh);
  // console.log(device);
  // Should be called before pushwoosh.onDeviceReady
  document.addEventListener('push-notification', (event: any) => {
    const notification = event.notification;
    // console.log('abcd: ');
    // console.log(notification);
  });

  // handle push open here


  // Initialize Pushwoosh. This will trigger all pending push notifications on start.
  pushwoosh.onDeviceReady({
    appid: '3CB69-27BC3',
    projectid: '928658288686'
  });

  pushwoosh.registerDevice(
    function (status) {
      const pushToken = status.pushToken;
      // console.log(pushToken);
      // console.log('status: ', status);
      // handle successful registration here
    },
    function (status) {
      console.log(status);
      // handle registration error here
    }
  );
}

// for App
// let onDeviceReady = () => {
//   platformBrowserDynamic().bootstrapModule(AppModule);
//   initPushwoosh();
// };
// document.addEventListener('deviceready', onDeviceReady, false);

// for Web
platformBrowserDynamic().bootstrapModule(AppModule);
