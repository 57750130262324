import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from '../../data.service';
import { TranslateService } from '../../translate.service';
import { BFService } from '../bench-feedback.service';
import { LandingComponent } from '../../landing/landing.component';

import * as moment from 'moment';
import * as _ from 'lodash';

declare var require;

@Component({
	selector: 'bfb-status-panel',
	templateUrl: './status-panel.component.html'
})
export class BenchFeedbackStatusPanel implements OnInit {
	stepBar: any = [1, 2, 3, 4, 5];
	statusBar: any = [1, 2, 3, 4, 5];
	activity: any = null;
	rStatus: any = null;
	stepNo: number = 0;
	statusName:string = null;
	showDgns: boolean = false;	//My Diagnostics Modal Popup show/hide
	cardSorts: any = [];
	icardSorts: any = [];
	bfbData: any = [];
	shadows: any = [];

	constructor(private route: ActivatedRoute, private router: Router,
		public dataService: DataService, private ts: TranslateService,
		public bfs: BFService) {
		//
	}

	ngOnInit() {
		this.dataService.bfbShowPanel = "loading";
		this.getDiagnostics();
	}

	getDiagnostics() {
		this.dataService.getDiagnosticsData().subscribe(response => {
			if (response) {
				this.cardSorts = [];
				this.icardSorts = [];

				if (response.cardSorts && response.cardSorts.length > 0) {
					for (let i = 0; i < response.cardSorts.length; i++) {
						if (response.cardSorts[i].activityInfo) {
							this.cardSorts.push(response.cardSorts[i]);
						} else {
							this.icardSorts.push(response.cardSorts[i]);
						}
					}
				}

				this.bfbData = response.benchFeedbacks || [];				
				this.shadows = response.shadows || [];

				if(this.cardSorts.length>0 || this.icardSorts.length>0 || this.bfbData.length>0 || this.shadows.length>0) {
					this.dataService.bfbShowPanel = "show";
				} else {
					this.dataService.bfbShowPanel = "hide";
				}
			}
		},
		error => {
			//console.log(error);
			this.dataService.bfbShowPanel = "hide";
		});
	}

	icstClick(icst: any) {
		let myFrame: any = document.getElementById('frmViewOnline');

		if (myFrame && myFrame.src != "about:blank") {
			myFrame.src = "about:blank";
		}

		this.dataService.popup.title = 'card_sorts';        //Title name as per the language json key
		this.dataService.popup.type = "iCardSort";			//for individual card sorts
		this.dataService.popup.data = { "cs_url": icst.url };
		this.dataService.popup.view = true;
		this.dataService.popup.class = "bs-popup-size__viewCard";
		this.dataService.popup.footer = false;

		this.showDgns = false;
	}

	dtFormat(digDate: any) {
		let dtd = digDate ? new Date(digDate) : new Date('2020-06-29');
		let retVal = null;

		if (this.dataService.curLang == 'KO' || this.dataService.curLang == 'ko') {
			retVal = moment(dtd).format("YYYY/MM/DD");
		} else {
			retVal = moment(dtd).format("MMM DD, YYYY");
		}

		return retVal;
	}

	lblFormat(digLabel: string, length: number) {
		if(digLabel.length > length) {
			return digLabel.substring(0, length) + '...';
		} else {
			return digLabel;
		}
	}

	statusText(status) {
		let retVal = '';

		/* if (status == 'Completed') {
			retVal = 'completed';
		} else if(status.substr(0, 10) == 'Completed,') {
			retVal = 'revisit'; */
		if (status == 'Completed' || status.substr(0, 10) == 'Completed,') {
			retVal = 'revisit';
		} else if(status == 'In progress') {
			retVal = 'resume';
		} else {
			retVal = 'start';
		}

		return retVal;
	}

	startClick(actInfo: any, sTitle) {
		let pwID  = actInfo.activityInfo.pathwayId;
		let ssnID = actInfo.activityInfo.sectionId;
		let actID = actInfo.activityInfo.activityId;

		if (!pwID && !ssnID && !actID && actInfo.url) {
			let myFrame: any = document.getElementById('frmViewOnline');

			if (myFrame && myFrame.src != "about:blank") {
				myFrame.src = "about:blank";
			}

			if (actInfo.redirectExternally) {
                myFrame.setAttribute('src', "");
                window.open(actInfo.url, '_blank');
			} else {
				myFrame.setAttribute('src', actInfo.url);
			}

			this.dataService.popup.title = sTitle;
			this.dataService.popup.type = "shadows";
			
			if (sTitle == 'CardSort' || sTitle == 'CardSortV2') {
				this.dataService.popup.class = 'bs-popup-size__viewCard';
			} else if (sTitle == 'Shadow') {
				this.dataService.popup.class = 'bs-popup-size__viewShadowActivity';
			} else {
				this.dataService.popup.class = 'bs-popup-size__benchFeedback';
			}
			
			this.dataService.popup.view = true;
			this.dataService.popup.footer = false;
			this.showDgns = false;
		} else {
			let tabs: any = document.getElementsByClassName("pathways-tabs");
			for (let i = 0; i < tabs.length; i++) {
				let tabId: number = parseInt(tabs[i].id);

				if (tabId == pwID) {
					tabs[i].click();

					setTimeout(function () {
						let colexp: any = document.getElementsByClassName("timeline-collexp");
						if(colexp[0]) colexp[0].click();

						let links: any = document.getElementsByClassName("timeline-links--outer");
						for (let j = 0; j < links.length; j++) {
							let linkId: number = parseInt(links[j].id);

							if (linkId == ssnID || linkId == actID) {
								links[j].click();
								break;
							}
						}
					}, 2000);

				}
			}

			this.showDgns = false;
		}
	}
}