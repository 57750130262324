import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { LoggerService } from '@btsdigital/pulseutilities';
import { DataService } from '../data.service';
import { ConfigLoaderGuard } from './config-loader.guard';

@Injectable()
export class CoachAuthGuard implements CanActivate {

    constructor(private dataService: DataService, private router: Router, private logger: LoggerService) {
    }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        return new Promise((resolve) => {
            let authToken = next.queryParams.authToken;
            let bookingId = next.queryParams.bookingId;
            if (authToken != null && bookingId != null) {
                this.logger.log("authToken", authToken);
                this.logger.log("bookingId", bookingId);
                //call the dataservice api to validate the authToken and grant them access
                this.dataService.checkToken({ authToken: authToken }).subscribe(result => {
                    if (result.message && result.message.toLowerCase() == 'valid token') {
                        this.logger.log('Valid token found in dataService.checkToken');
                        this.dataService.setLocalStorage();
                        this.dataService.setVideoCallId(bookingId, authToken);
                        this.router.navigate(['coachVideoCall']);
                        resolve(true);
                    }
                    else {
                        this.router.navigate(['login']);
                        resolve(false);
                    }
                }, error => {
                    this.router.navigate(['login']);
                    resolve(false);
                });
            }
            else {
                // console.log('logging out as authToken & callId not found')
                this.router.navigate(['login']);
                resolve(false);
            }
        });
    };
}
