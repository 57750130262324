import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import * as Highcharts from 'highcharts';
import { NumberFormatting } from './number-formatting';
import * as HighchartsExporting from 'highcharts/modules/exporting.js'
import * as HighchartsOfflineExporting from 'highcharts/modules/offline-exporting.js'

@Injectable()
export class ChartDefaults {

    getDefaults():any {
        Highcharts.setOptions({
            lang: {
                thousandsSep: ","
            }
        });

        let defaults = {
            chart: {
                style: '{"fontFamily":"OpenSans","fontSize":"12px"}',
                backgroundColor: 'transparent'
            },
            colors: ['#F07300', '#80CC28', '#006EB9', '#FF409C', '#FFC300', '#00873C', '#28324B', '#ee7423', '#FB054B', '#BEC0C1'],
            credits: {
                enabled: false
            },
            xAxis: {
                labels: {
                    style: { color: '#fff' }
                },
                tickPosition: 'inside',
                gridLineColor: 'transparent',
                tickWidth: 1,
                lineWidth: 1
            },
            yAxis: {
                labels: {
                    style: { color: '#fff' }
                },
                tickPosition: 'inside',
                gridLineColor: 'transparent',
                tickWidth: 1,
                lineWidth: 1
            }
        }

        // HighchartsExporting(Highcharts);
        // HighchartsOfflineExporting(Highcharts);

        return defaults;
    }

    getDataLabelDefaultStyle(): any {
        return {
            style: '{"color": "contrast", "fontSize": "10px", "fontWeight": "bold", "textShadow": "none" }'
        };
    }


    getSolidGaugeDefaults() {
        let chartData = {
            chart: {
                type: 'solidgauge'
            },
            title: null,
            pane: {
                center: ['50%', '85%'],
                // size: '140%',
                startAngle: -90,
                endAngle: 90,
                background: {
                    backgroundColor: '#EEE',
                    innerRadius: '60%',
                    outerRadius: '100%',
                    shape: 'arc'
                }
            },

            tooltip: {
                enabled: false
            },

            credits: {
                enabled: false
            },

            // the value axis
            yAxis: {
                // stops: [
                //     [0.1, '#55BF3B'], // green
                //     [0.5, '#DDDF0D'], // yellow
                //     [0.9, '#DF5353'] // red
                // ],
                lineWidth: 0,
                minorTickInterval: null,
                tickPixelInterval: 400,
                tickWidth: 0,
                labels: {
                    enabled: false
                }
            },

            plotOptions: {
                solidgauge: {
                    dataLabels: {
                        y: 5,
                        borderWidth: 0,
                        useHTML: true,
                        style: this.getDataLabelDefaultStyle()
                    }
                }
            }
        };

        return _.merge({}, this.getDefaults(), chartData);
    }

    getBasicBarChartDefaults(numberFormat?:string) {
        let chartData = {
            chart: {
                type: 'bar'
            },
            title: null,
            xAxis: {
                categories: [],
                title: {
                    text: null
                }
            },
            yAxis: {
                min: 0,
                title: {
                    text: '',
                    align: 'high'
                },
                labels: {
                    overflow: 'justify',
                    formatter: function(){
                        if (numberFormat) {
                            return NumberFormatting.format(this.value, numberFormat);
                        }
                        else {
                            return this.value;
                        }
                    }
                }
            },
            legend: {
                layout: 'horizontal',
                align: 'center',
                verticalAlign: 'bottom',
                x: 0,
                y: 0,
                floating: false,
                borderWidth: 0,
                shadow: false,
                symbolRadius: 5,
                itemStyle: {
                    color: '#fff',
                    fontWeight: 'normal'
                }
            },
            tooltip: {
                headerFormat: '<b>{point.x}</b><br/>',
                pointFormatter: function() {
                    if (numberFormat) {
                        return NumberFormatting.format(this.y, numberFormat);
                    }
                    else {
                        return this.y;
                    }
                }
            },
            plotOptions: {
                bar: {
                    dataLabels: {
                        enabled: true,
                        style: this.getDataLabelDefaultStyle(),
                        formatter: function() {
                            if (numberFormat) {
                                return NumberFormatting.format(this.y, numberFormat);
                            }
                            else {
                                return this.y;
                            }
                        }
                    }
                }
            },
            series: []
        };


        return _.merge({}, this.getDefaults(), chartData);
    }

    getStackedColumnChartDefaults(numberFormat?:string, appendFormat?:string) {
        let chartData = {
            chart: {
                type: 'column'
            },
            title: {
                text: ''    
            },
            xAxis: {
                categories: []
            },
            yAxis: {
                title: {
                    text: ''
                },
                allowDecimals: false,
                alternateGridColor: '#828282',
                labels: {
                    style: { color: '#fff' },
                    formatter: function() {
                        if (numberFormat) {
                            return NumberFormatting.format(this.value, numberFormat);
                        }
                        else {
                            return appendFormat ? this.value + appendFormat : this.value;
                        }
                    }
                },
                stackLabels: {
                    enabled: false,
                    style: {
                        fontWeight: 'bold',
                        color: 'gray'
                    }
                },
                plotLines: [{
                    color: '#000000',
                    width: 2,
                    value: 0
                }]
            },
            legend: {
                layout: '',
                align: '',
                verticalAlign: '',
                x: -10,
                y: 0,
                floating: false,
                borderWidth: 0,
                shadow: false,
                symbolRadius: 5,
                symbolHeight: 15,
                itemMarginTop: 10,
                itemStyle: {
                    color: '#fff',
                    fontWeight: 'normal'
                }
            },
            tooltip: {
                headerFormat: '<b>{point.x}</b><br/>',
                // pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}',
                pointFormatter: function() {
                    if (numberFormat) {
                        return this.series.name + ": " + NumberFormatting.format(this.y, numberFormat) + "<br />" + "Total: " + NumberFormatting.format(this.total, numberFormat);
                    }
                    else {
                        return this.series.name+ ": " + this.y + "<br/>Total: " + this.stackTotal;
                    }
                }
            },
            plotOptions: {
                column: {
                    stacking: 'normal',
                    dataLabels: {
                        enabled: false,
                        // format: '{y:0.1f}',
                        formatter: function() {
                            if (numberFormat) {
                                return NumberFormatting.format(this.y, numberFormat);
                            }
                            else {
                                return this.y;
                            }
                        },
                        color: 'black',
                        style: this.getDataLabelDefaultStyle()
                    }
                }
            },
            series: []
        };
        return _.merge({}, this.getDefaults(), chartData);
    }

    getBasicColumnChartDefaults(numberFormat?:string, appendFormat?:string) {
        let chartData = {
            chart: {
                type: 'column'
            },
            title: {
                text: ''    
            },
            xAxis: {
                categories: []
            },
            yAxis: {
                title: {
                    text: ''
                },
                allowDecimals: false,
                alternateGridColor: '#828282',
                plotLines: [{
                    color: '#000000',
                    width: 2,
                    value: 0
                }],
                labels: {
                    style: { color: '#fff' },
                    formatter: function() {
                        if (numberFormat) {
                            return NumberFormatting.format(this.value, numberFormat);
                        }
                        else {
                            return appendFormat ? this.value + appendFormat : this.value;
                        }
                    }
                }
            },
            legend: {
                layout: '',
                align: '',
                verticalAlign: '',
                x: -10,
                y: 0,
                floating: false,
                borderWidth: 0,
                shadow: false,
                symbolRadius: 5,
                symbolHeight: 15,
                itemMarginTop: 10,
                itemStyle: {
                    color: '#fff',
                    fontWeight: 'normal'
                }
            },
            tooltip: {
                //headerFormat: '<b>{point.x}</b><br/>',
                // pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}',
                formatter: function() {
                    if (numberFormat) {
                        return this.series.name + ": " + NumberFormatting.format(this.y, numberFormat) + "<br />" + "Total: " + NumberFormatting.format(this.total, numberFormat);
                    }
                    else {
                        return '<span style="color: ' + this.color + '">' + this.series.name + '</span>' + ': ' + (appendFormat ? this.y.toFixed(2) + appendFormat : this.y);
                    }
                }
            },
            plotOptions: {
                column: {
                    pointPadding: 0.2,
                    borderWidth: 0,
                    style: this.getDataLabelDefaultStyle()
                }
            },
            series: []
        };
        return _.merge({}, this.getDefaults(), chartData);
    }

    getPieChartDefaults() {
        let chartData: any = {
            chart: {
                type: 'pie',
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false
            },
            title: null,
            tooltip: {
                headerFormat: '<b>{series.name}</b><br/>',
                pointFormat: '{point.name}: <b>{point.percentage:.1f}%</b>'
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    dataLabels: {
                        enabled: false
                    },
                    showInLegend: true,
                    style: this.getDataLabelDefaultStyle()
                }
            },
            legend: {
                layout: 'horizontal',
                align: 'center',
                verticalAlign: 'bottom',
                floating: false,
                borderWidth: 0,
                shadow: false,
                symbolRadius: 5,
                itemMarginTop: 10,
                itemStyle: {
                    color: '#fff',
                    fontWeight: 'normal'
                }
            },
            series: [{
                name: '',
                colorByPoint: true,
                data: []
            }]
        };
        return _.merge({}, this.getDefaults(), chartData);
    }

    getBasicLineChartDefaults() {
        let chartData: any = {
            chart: {
                type: 'line'
            },
            title: {
                text: ''
            },
            xAxis: {
                categories: []
            },
            yAxis: {
                min: 0,
                title: {
                    text: ''
                },
                plotLines: [{
                    value: 0,
                    width: 1,
                    color: '#808080'
                }]
            },
            legend: {
                layout: '',
                align: '',
                verticalAlign: '',
                x: 0,
                y: 0,
                floating: false,
                borderWidth: 0,
                shadow: false,
                symbolRadius: 5,
                symbolHeight: 15,
                itemMarginTop: 10,
                itemStyle: {
                    color: '#fff',
                    fontWeight: 'normal'
                }
            },
            plotOptions: {
                line: {
                    style: this.getDataLabelDefaultStyle()
                },
                series: {
                    marker: {
                        radius: 8
                    }
                }
            },
            series: []
        };
        return _.merge({}, this.getDefaults(), chartData);
    }

    getSplineChartDefaults() {
        let chartData: any = {
            chart: {
                type: 'spline'
            },
            title: {
                text: ''
            },
            xAxis: {
                categories: []
            },
            yAxis: {
                min: 0,
                title: {
                    text: ''
                },
                plotLines: [{
                    value: 0,
                    width: 1,
                    color: '#808080'
                }]
            },
            legend: {
                layout: '',
                align: '',
                verticalAlign: '',
                x: 0,
                y: 0,
                floating: false,
                borderWidth: 0,
                shadow: false,
                symbolRadius: 5,
                symbolHeight: 15,
                itemMarginTop: 10,
                itemStyle: {
                    color: '#fff',
                    fontWeight: 'normal'
                }
            },
            plotOptions: {
                line: {
                    style: this.getDataLabelDefaultStyle()
                },
                series: {
                    marker: {
                        radius: 8
                    }
                }
            },
            series: []
        };
        return _.merge({}, this.getDefaults(), chartData);
    }

    getBasicAreaChartDefaults() {
        let chartData: any = {
            chart: {
                type: 'area'
            },
            title: {
                text: ''
            },
            xAxis: {
                categories: []
            },
            yAxis: {
                min: 0,
                title: {
                    text: ''
                },
                plotLines: [{
                    value: 0,
                    width: 1,
                    color: '#808080'
                }]
            },
            legend: {
                layout: '',
                align: '',
                verticalAlign: '',
                x: 0,
                y: 0,
                floating: false,
                borderWidth: 0,
                shadow: false,
                symbolRadius: 5,
                symbolHeight: 15,
                itemMarginTop: 10,
                itemStyle: {
                    color: '#fff',
                    fontWeight: 'normal'
                }
            },
            plotOptions: {
                line: {
                    style: this.getDataLabelDefaultStyle()
                },
                series: {
                    marker: {
                        radius: 8
                    }
                }
            },
            series: []
        };
        return _.merge({}, this.getDefaults(), chartData);
    }

    getHeatMapChartDefaults(numberFormat?:string) {
        let chartData:any = {

            chart: {
                type: 'heatmap',
                marginTop: 60,
                marginBottom: 20,
                plotBorderWidth: 1
            },
            title: {
                text: ''
            },

            xAxis: {
                categories: [],
                opposite: true,
                labels: {
                    style: {
                        fontSize: "16px"
                    }
                }
            },
            yAxis: {
                categories: [],
                title: null,
                labels: {
                    style: {
                        fontSize: "16px"
                    }
                }
            },

            plotOptions: {
                series: {
                    borderWidth: 5,
                    borderColor: "#FFFFFF",
                    dataLabels: {
                        style: {
                            fontSize: "24px",
                            fontFamily: "OpenSans",
                            fontWeight: "500"
                        },
                        formatter:function() {
                            // if (this.y == 4) {
                            //     return this.point.value + " %";
                            // } else {
                            //     return this.point.value + " mm";
                            // }
                            return this.point.value;
                        },
                        enabled: true
                    }
                }
            },

            colorAxis: {
                min: 0,
                minColor: '#FFFFFF',
                maxColor: '#FFFFFF'
            },

            legend: {
                align: 'right',
                layout: 'vertical',
                margin: 0,
                verticalAlign: 'top',
                y: 25,
                symbolHeight: 280
            },

            tooltip: {
                formatter: function () {
                    return '<b>' + this.series.xAxis.categories[this.point.x] + '</b><br><b>' +
                        this.point.value + '</b><br><b>' + this.series.yAxis.categories[this.point.y] + '</b>';
                }
            },

            series: [{
                name: '',
                borderWidth: 1,
                data: [],
                dataLabels: {
                    enabled: true,
                    color: '#FFFFFF'
                }
            }]
            
        };
        return _.merge({}, this.getDefaults(), chartData);
    }

    getBoxPlotChartDefaults() {
        let chartData: any = {
            chart: {
                type: 'boxplot',
                inverted: false
            },
            title: {
                text: ''
            },
            xAxis: {
                categories: []
            },
            yAxis: {
                min: 1,
                max: 10,
                title: {
                    text: ''
                },
                labels: {
                    style: { color: '#333' }
                },
            },
            legend: {
                enabled: true
            },
            tooltip: {
                useHTML: true
            },
            plotOptions: {
                boxplot: {
                    fillColor: '#FFE8CE',
                    lineWidth: 1,
                    medianColor: '#FF8A00',
                    medianWidth: 2,
                    stemColor: '#60BEFE',
                    stemDashStyle: 'solid',
                    stemWidth: 2,
                    whiskerColor: '#60BEFE',
                    whiskerLength: '70%',
                    whiskerWidth: 2
                }
            },
            series: []
        };
        return _.merge({}, this.getDefaults(), chartData);
    }
}