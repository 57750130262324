import { ChangeDetectorRef, Component, DoCheck, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { DataService } from '../../data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '../../translate.service';
import * as _ from 'lodash';
import { TranslatePipe } from '../../translate.pipe';

import * as $ from 'jquery';
import { HttpClient } from '@angular/common/http';
import { range } from 'rxjs';
declare var $: $;
declare var jQuery: any;
window['$'] = window['jQuery'] = window['jquery'] = $;

@Component({
	selector: 'edit-profile',
	templateUrl: './edit-profile.component.html'
})

export class EditProfileComponent implements OnInit, OnChanges, DoCheck {
	oldPassword: string = "";
	isCorrectOldPass: boolean;
	isCorrectConfirmPass: boolean;
	isCorrectNewPass: boolean;
	countries: any = [];
	towns: any = [];
	timeZones: any = this.dataService.timeZones;
	// mapSource: any = this.dataService.mapSource;
	imgMapData: any = "";

	secondEmail: boolean = false;
	secondContact: boolean = false;
	noTown: boolean = false;
	errFirstName: boolean = false;
	errLastName: boolean = false;
	errEmail: boolean = false;
	errCode: boolean = false;
	errTimezone: boolean = false;
	errContact1: boolean = false;
	errContact2: boolean = false;
	tabActive: string = "personal";
	insideTabActive: string = "personal-data";
	isNew: boolean = false;
	isOld: boolean = false;
	isConfirm: boolean = false;
	password: any = {
		oldPass: "",
		newPass: "",
		confirmPass: ""
	};
	coachee: any = {
		id: "",
		username: "",
		firstName: "",
		lastName: "",
		email: "",
		emailTwo: "",
		country: "",
		town: "",
		timezone: "",
		phoneOne: "",
		phoneTwo: "",
		photo: "",
		photoFile: ""
	};
	pic_filename: string = "";
	checkFirstNameValid: any;
	checkLastNameValid: any;
	isValid: boolean = null;
	errorMsg: string;

	taggingArray = [];
	approveRejectArray = [];
	isDataAvailabel = false;
	tagCategories = {
		personal: ['Gender', 'Pronouns', 'Age', 'Nationality'],
		role: ['Industry', 'Role in Industry', 'Time in Role (years)', 'Time in Business (years)'],
		business: ['Business Region', 'Business Function', 'Level of Leader', 'Product Area'],
		others: ['Role Branding']
	}
	personalDataArray = [];
	aboutYourRoleArray = [];
	aboutYourBusinessArray = [];
	othersArray = [];
	@Output() hideSubmitBtns = new EventEmitter<any>();
	@Input() goNowBtnClicked;

	constructor(private route: ActivatedRoute, public dataService: DataService, private router: Router, private ts: TranslateService, private httpClient: HttpClient, private cd: ChangeDetectorRef) {
	}

	ngOnInit() {
		var self = this;

		this.countries = this.dataService.getCountriesList();

		if (this.dataService.coachee) {
			this.coachee = Object.assign({}, this.dataService.coachee);

			if (this.coachee.country) {
				var lastTown = this.coachee.town;
				this.getTowns(null);
				this.coachee.town = lastTown;
			}

			if (this.coachee.photo) {
				this.coachee.pic = this.dataService.coachServerUrl + this.coachee.photo
			} else {
				this.coachee.pic = "./assets/images/no-image.png";
			}
			this.checkFirstNameValid = this.coachee.firstName;
			this.checkLastNameValid = this.coachee.lastName;
		}
	}

	ngOnChanges() {
		if (this.goNowBtnClicked) {
			this.getTaggingData();
			this.tabActive = 'otherDetails';
			this.insideTabActive = 'personal-data';
			this.hideSubmitBtns.emit(true);
		}
	}

	// Move to change pw tab
	changePassword() {
		this.tabActive = 'updatePass';
		this.password.oldPass = "";
		this.password.newPass = "";
		this.password.confirmPass = "";
		this.isCorrectOldPass = true;
		this.isCorrectConfirmPass = true;
		this.isValid = true;
	}

	// Check the basic pw validation
	isValidPW(password) {
		var uppercase = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
		var lowercase = "abcdefghijklmnopqrstuvwxyz";
		var digits = "0123456789";
		var splChars = "~!@#$%&*()-_+={[]}|/<>?.";

		var ucaseFlag = this.pwContains(password, uppercase);
		var lcaseFlag = this.pwContains(password, lowercase);
		var digitsFlag = this.pwContains(password, digits);
		var splCharsFlag = this.pwContains(password, splChars);

		if (password.length >= 8 && ucaseFlag && lcaseFlag && (digitsFlag || splCharsFlag)) {
			return true;
		} else {
			return false;
		}
	}

	// Check the basic pw validation
	pwContains(password, allowedChars) {
		for (var i = 0; i < password.length; i++) {
			var char = password.charAt(i);

			if (allowedChars.indexOf(char) >= 0) return true;
		}

		return false;
	}

	isOldPasswordValid() {
		if (!this.password.oldPass || this.password.oldPass === "") {
			this.isCorrectOldPass = true;
			return;
		}
		if (this.oldPassword === this.password.oldPass) {
			this.isCorrectOldPass = true;
		} else {
			this.isCorrectOldPass = false;
		}
	}

	isConfirmPasswordValid() {
		if (this.password.newPass === this.password.confirmPass) {
			this.isCorrectConfirmPass = true;
		} else {
			this.isCorrectConfirmPass = false;
		}
	}

	isNewPasswordValid() {
		if (this.password.newPass) {
			if (this.isValidPW(this.password.newPass)) {
				this.checkFirstNameValid = this.checkFirstNameValid || '~';
				this.checkLastNameValid = this.checkLastNameValid || '~';

				if (this.password.newPass.toLowerCase().indexOf(this.checkFirstNameValid.toLowerCase()) === -1 && this.password.newPass.toLowerCase().indexOf(this.checkLastNameValid.toLowerCase()) === -1) {
					this.isValid = true;
				}
				else {
					this.isValid = false;
					this.errorMsg = this.ts.translateMe('pw_flname');
				}
			}
			else {
				this.isValid = false;
				this.errorMsg = this.ts.translateMe('pw_valid');
			}
		}
		else {
			this.isValid = null;
		}
	}

	getFileName(event) {
		var self = this;
		var fileInput = event.target;
		var filePath = fileInput.value;
		var allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;

		if (!allowedExtensions.exec(filePath)) {
			alert(this.ts.translateMe('upload_fhx'));
			fileInput.value = '';
			return false;
		} else {
			if (fileInput.files && fileInput.files[0]) {
				self.coachee.photoFile = fileInput.files[0];
				this.pic_filename = fileInput.files[0].name;

				/*var reader = new FileReader();
				reader.readAsDataURL(fileInput.files[0]);
				reader.onload = function(event:any) {
					self.coachee.pic = event.target.result;
				};*/
			}
		}
	}

	getTowns(event) {
		this.noTown = false;
		this.towns = null;
		this.coachee.town = null;
		if (event) this.coachee.country = event.target.value;

		if (this.coachee.country) {
			let country = this.countries.filter(c => c.code == this.coachee.country);
			if (country.length > 0) {
				this.towns = country[0].towns;
			}
		} else {
			this.noTown = true;
		}
	}

	updateUser() {
		var self = this;
		var frmInvalid: any = document.getElementsByClassName('ng-invalid');

		this.errFirstName = this.coachee.firstName ? false : true;
		this.errLastName = this.coachee.lastName ? false : true;
		this.errEmail = this.coachee.email ? false : true;
		this.errCode = this.coachee.country ? false : true;
		this.errTimezone = this.coachee.timezone ? false : true;
		this.errContact1 = this.coachee.phoneOne ? false : true;
		this.errContact2 = false;

		if (this.tabActive === 'otherDetails') {
			// this.saveTaggingData();
			return false;
		}

		if (this.coachee.phoneOne) {
			let myStr: string = this.coachee.phoneOne;

			this.coachee.phoneOne = myStr.replace(/(?!\+)[^0-9 ]/g, "");

			if (myStr.length < 10) {
				this.errContact1 = true;
			}
		}

		if (this.coachee.phoneTwo) {
			let myStr: string = this.coachee.phoneTwo;

			this.coachee.phoneTwo = myStr.replace(/(?!\+)[^0-9 ]/g, "");

			if (myStr.length < 10) {
				this.errContact2 = true;
			}
		}

		if (this.errContact1 || this.errContact2) {
			this.dataService.saveStatus = null;
			return false;
		}

		if (frmInvalid.length) {
			this.dataService.saveStatus = null;
			return false;
		}

		this.dataService.saveStatus = "processing";
		self.dataService.saveUser(self.coachee).subscribe(
			result => {
				this.dataService.coachee = result.user;
				if (this.coachee.photoFile) {
					self.dataService.uploadPic(self.coachee).subscribe(response => {
						var imageFile = response.file;
						self.dataService.saveStatus = "saved";
						self.dataService.coachee.photo = imageFile.substring(imageFile.indexOf("media"));
						self.coachee.photoFile = null;
					});
				} else {
					self.dataService.coachee.photo = self.coachee.photo;
					self.dataService.coachee.pic = self.coachee.pic;
				}

				if (this.password.newPass && this.password.newPass == this.password.confirmPass) {
					this.dataService.changePW(this.password.newPass, this.password.confirmPass).subscribe(retVal => {
						if (retVal) {
							self.dataService.saveStatus = "saved";
							// alert("Password has been changed, you need to relogin again.");
							// this.dataService.emitData("do-logout", "true");
						}
					},
						error => {
							this.tabActive = 'updatePass';
							self.dataService.saveStatus = "error";
							self.dataService.errorMsg = error.errorMsg;
							return false;
						});
				} else {
					this.tabActive = "personal";
					self.dataService.saveStatus = "saved";
				}
			},
			error => {
				// console.log(error);
				self.dataService.saveStatus = "error";
			});
	}

	onDDUpdate(retVal: any) {
		this.coachee.town = retVal;
	}

	ngDoCheck() {
		this.dataService.getEmitter("save-profile").subscribe((result) => {
			if (result == "true" && !this.dataService.saveStatus) {
				this.updateUser();
			}
		});
	}

	// saveTaggingData() {
	// 	if (this.approveRejectArray.length > 0 || this.taggingArray.length > 0) {
	// 		const arr = [];
	// 		this.taggingArray.forEach((source) => {
	// 			source.tagGroups.forEach((group) => {
	// 				if (group.tags.length > 0) {
	// 					const obj: any = {
	// 						id: group.tags[0].taggingId,
	// 						modelId: source.sourceId,
	// 						modelType: 'PathwayV2',
	// 						tagId: (group.selectedID > 0) ? group.selectedID : '',
	// 						tagName: (group.selectedName !== '') ? group.selectedName : group.tags[0].name,
	// 						status: (group.mode === '') ? 'Approved' : group.mode
	// 					};
	// 					const compList = localStorage.getItem('pathways') ? JSON.parse(localStorage.getItem('pathways')) : [];
	// 					if (compList.length > 0) {
	// 						const findRestrict = compList.find((f) => f.id === source.sourceId);
	// 						if (findRestrict && findRestrict.id) {
	// 							obj.restrictedModelId = findRestrict.companyId;
	// 							obj.restrictedModelName = 'Company';
	// 							arr.push(obj);
	// 						}
	// 					}
	// 				}
	// 			});
	// 		});
	// 		// Check field is empty or not
	// 		for (const item of arr) {
	// 			if (item.tagName === '' || item.tagName === null) {
	// 				if (item.status !== 'Rejected') {
	// 					this.dataService.emitData('tagging_validation', true);
	// 					return false;
	// 				}
	// 			}
	// 		}
	// 		this.dataService.emitData('tagging_validation', false);
	// 		this.dataService.saveStatus = 'processing';

	// 		const data = {
	// 			source: 'Coach',
	// 			taggings: arr
	// 		};

	// 		const url = 'api/v1/tagging/approve';
	// 		this.dataService.postTaggingData('api/v1/tagging/approve', data).subscribe((res: any) => {
	// 			if (res.models.length > 0) {
	// 				this.dataService.saveStatus = 'saved';
	// 			} else {
	// 				this.dataService.saveStatus = 'error';
	// 			}
	// 		});
	// 	}
	// }

	saveSingleTag(group) {
		if (this.approveRejectArray.length > 0) {
			const arr = [];
			const obj: any = {
				id: group.tags[0].taggingId,
				modelId: group.sourceId,
				modelType: 'PathwayV2',
				tagId: (group.selectedID > 0) ? group.selectedID : '',
				tagName: (group.selectedName !== '') ? group.selectedName : group.tags[0].name,
				status: (group.mode === '') ? 'Approved' : group.mode
			};
			const compList = localStorage.getItem('pathways') ? JSON.parse(localStorage.getItem('pathways')) : [];
			if (compList.length > 0) {
				const findRestrict = compList.find((f) => f.id === obj.modelId);
				if (findRestrict && findRestrict.id) {
					obj.restrictedModelId = findRestrict.companyId;
					obj.restrictedModelName = 'Company';
					arr.push(obj);
				}
			}
			console.log(arr)
			for (const item of arr) {
				if (item.tagName === '' || item.tagName === null) {
					if (item.status !== 'Rejected') {
						this.dataService.emitData('tagging_validation', true);
						return false;
					}
				}
			}
			this.dataService.emitData('tagging_validation', false);
			this.dataService.saveStatus = 'processing';

			const data = {
				source: 'Coach',
				taggings: arr
			};
			const url = 'api/v1/tagging/approve';
			this.dataService.postTaggingData('api/v1/tagging/approve', data).subscribe((res: any) => {
				if (res.models.length > 0) {
					this.dataService.saveStatus = 'saved';
				} else {
					this.dataService.saveStatus = 'error';
				}
			});
		}

	}

	getTaggingData() {
		let url = 'api/v1/tagging/list_for?source=Coach&model=PathwayV2';
		const pathwayId: any = localStorage.getItem('pathways') ? JSON.parse(localStorage.getItem('pathways')) : [];
		if (pathwayId.length > 0) {
			let modelIds = '';
			pathwayId.forEach(obj => {
				modelIds += `&modelIds[]=${obj.id}`;
			});
			url += modelIds;
		}
		this.personalDataArray = [];
		this.aboutYourRoleArray = [];
		this.aboutYourBusinessArray = [];
		this.othersArray = [];
		this.isDataAvailabel = false;
		this.dataService.getTaggingData(url).subscribe(async (res: any) => {
			if (res.models && res.models.length > 0) {
				const data = res.models;
				const arr = [];
				let isDataAvailabel = false;
				// data.forEach(async (item: any, i: number) => {
				for (const item of data) {
					if (item.tagGroups.length > 0) {
						// item.tagGroups.forEach(async (tag: any, n: number) => {
						for (const tag of item.tagGroups) {
							tag.class = '';
							tag.action = 'select';
							tag.taggingBackgroundColor = '#f0f3f2';
							tag.selectedID = (tag.tags && tag.tags.length > 0 && tag.tags[0].tagId > 0) ? tag.tags[0].tagId : 0;
							tag.selectedName = (tag.tags && tag.tags.length > 0 && tag.tags[0].tagId > 0) ? tag.tags[0].locales ? tag.tags[0].locales[this.dataService.curLang] ? tag.tags[0].locales[this.dataService.curLang] : tag.tags[0].name : tag.tags[0].name : tag.tags[0].name;
							tag.getTagList = {};
							tag.mode = '';
							isDataAvailabel = true;
							tag.sourceId = item.sourceId;
							tag.isCustomTagAdded = false;
							if (_.includes(this.tagCategories.personal, tag.name)) {
								tag.getTagList = await this.getTagList2(tag.id);
								if (tag.getTagList['tagGroups'].length > 0) {
									tag.getTagList = tag.getTagList['tagGroups'][0];
									tag.isCustomTagAdded = await this.isCustomTagAdded(tag.getTagList.templates, tag.tags[0].name);
								}

								this.personalDataArray.push(tag);
							} else if (_.includes(this.tagCategories.role, tag.name)) {
								this.aboutYourRoleArray.push(tag);
							} else if (_.includes(this.tagCategories.business, tag.name)) {
								this.aboutYourBusinessArray.push(tag);
							} else {
								this.othersArray.push(tag);
							}
						}
					}
				}
				this.isDataAvailabel = true;
			} else {
				this.isDataAvailabel = true;
			}
		});
	}

	isCustomTagAdded(templates, val) {
		if (!_.includes(_.map(templates, 'name'), val)) {
			return true;
		} else {
			return false;
		}
	}

	getInsideTagList(arr) {
		arr.forEach(async (tag: any, n: number) => {
			tag.getTagList = await this.getTagList2(tag.id);
			if (tag.getTagList['tagGroups'].length > 0) {
				tag.getTagList = tag.getTagList['tagGroups'][0];
			}
			if (tag && tag.tags.length > 0) {
				tag.isCustomTagAdded = await this.isCustomTagAdded(tag.getTagList.templates, tag.tags[0].name);
			}
		});
	}

	getTagList2(id) {
		const url = `api/v1/tag_groups/template_tags?source=Coach&tagGroupId=${id}`;
		return new Promise(resolve => {
			this.dataService.getTaggingData(url)
				.subscribe(response => {
					resolve(response);
				});
		});
	}

	//   getTagList(tagGroup, parentIndex: number, selectedIndex: number) {
	//     const url = `api/v1/tag_groups/template_tags?source=Coach&tagGroupId=${tagGroup.id}`;
	//     this.dataService.getTaggingData(url).subscribe(res => {
	//       const data: any = res;
	//       if (data.tagGroups.length > 0) {
	//         if (this.taggingArray.length > 0) {
	//           this.taggingArray[parentIndex].tagGroups[selectedIndex].getTagList = data.tagGroups[0];

	//           this.taggingArray[parentIndex].tagGroups[selectedIndex].selectedID =
	//             (this.taggingArray[parentIndex].tagGroups[selectedIndex].getTagList.templates.length > 0) ?
	//               this.taggingArray[parentIndex].tagGroups[selectedIndex].getTagList.templates[0].tags[0].id : 0;

	//           this.taggingArray[parentIndex].tagGroups[selectedIndex].selectedName =
	//             (this.taggingArray[parentIndex].tagGroups[selectedIndex].getTagList.templates.length > 0) ?
	//               this.taggingArray[parentIndex].tagGroups[selectedIndex].getTagList.templates[0].tags[0].name : '';
	//         }
	//       }
	//     });
	//   }

	selectedTag2(e, arr, index, tagGroup) {
		if (e.target.value === 'addNew') {
			arr[index].action = 'addNew';
		} else {
			arr[index].action = 'select';
			if (e.target.options[e.target.options.selectedIndex].label === '') {
				return;
			}
			arr[index].selectedID = Number(e.target.value);
			arr[index].selectedName = (e.target.options) ? e.target.options[e.target.options.selectedIndex].label : '';
			arr[index].isCustomTagAdded = false;
			arr[index].tags[0].name = arr[index].selectedName;
			arr[index].tags[0].tagId = Number(e.target.value);
			if (arr[index].tags[0].locales != null && arr[index].tags[0].locales[this.dataService.curLang]) {
				arr[index].tags[0].locales[this.dataService.curLang] = arr[index].selectedName;
			}
			this.approve(tagGroup);
		}
	}

	//   selectedTag(e, parentIndex, childIndex) {
	//     if (e.target.value === 'addNew' || e.target.value === '') {
	//       this.taggingArray[parentIndex].tagGroups[childIndex].selectedID = 0;
	//       this.taggingArray[parentIndex].tagGroups[childIndex].selectedName = '';
	//       this.taggingArray[parentIndex].tagGroups[childIndex].action = 'addNew';
	//     } else {
	//       this.taggingArray[parentIndex].tagGroups[childIndex].selectedID = Number(e.target.value);
	//       this.taggingArray[parentIndex].tagGroups[childIndex].selectedName = (e.target.options) ? e.target.options[e.target.options.selectedIndex].label : '';
	//     }
	//   }

	approve(tagGroup) {
		// const index = _.findIndex(this.approveRejectArray, { id: tagGroup.id, modelId: parentIndex });
		// if (index !== -1) {
		//   this.approveRejectArray.splice(index, 1);
		// }
		// tagGroup.modelId = parentIndex;
		this.approveRejectArray.push(tagGroup);
		this.saveSingleTag(tagGroup);
	}

	reject(tagGroup) {
		// const index = _.findIndex(this.approveRejectArray, { id: tagGroup.id, modelId: parentIndex });
		// if (index !== -1) {
		//   this.approveRejectArray.splice(index, 1);
		// }
		// tagGroup.modelId = parentIndex;
		this.approveRejectArray.push(tagGroup);
		this.saveSingleTag(tagGroup);
	}

	confirmDelete(tagGroup) {
		const tagName = (tagGroup.locales !== null && tagGroup.locales[this.dataService.curLang]) ?
			tagGroup.locales[this.dataService.curLang] : tagGroup.name;
		const val = confirm(this.ts.translateMe('are_you_sure')+': ' + tagName + ' ?');
		if (val) {
			tagGroup.mode = 'Rejected';
			this.reject(tagGroup);
		}
	}

	back(tagGroup, curLang) {
		// tagGroup.selectedID = (tagGroup.getTagList && tagGroup.getTagList.templates.length > 0) ?
		//                        tagGroup.getTagList.templates[0].tags[0].id : '';
		// tagGroup.selectedName = (tagGroup.getTagList && tagGroup.getTagList.templates.length > 0)
		//                         ? (tagGroup.getTagList.templates[0].tags[0].locales !== null) ?
		//                             tagGroup.getTagList.templates[0].tags[0].locales[curLang] : '' : '';
		tagGroup.selectedID = tagGroup.tags[0].tagId;
		tagGroup.selectedName = tagGroup.tags[0].locales !== null ? tagGroup.tags[0].locales[curLang] ? tagGroup.tags[0].locales[curLang] : tagGroup.tags[0].name : tagGroup.tags[0].name;
		tagGroup.action = 'select';
		tagGroup.mode = '';
	}

}
