import { Component, OnInit, DoCheck } from '@angular/core';
import { DataService } from './../data.service';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '../translate.service';

import * as _ from 'lodash';
import * as moment from 'moment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html'
})
export class LoginComponent implements OnInit, DoCheck {
  message = '';
  userName = 'Coachee_1@example.com';
  password = 'Password123';
  lang = 'en';
  ssoMessage = 'Authenticating...';

  userId: number;
  authToken: any;
  timeZones: any = this.dataService.timeZones;

  public pwdReset = false;
  public pwdResetInstructions = false;
  public loginPage = true;
  public agreementPage = false;
  public container = true;
  public userDetails = false;
  public optionalAgreementPage = false;
  public checkedBoxes = false;
  public requiredArray: Array<any>;
  public optionalArray: Array<any>;

  isInvalid = false;
  isPWExpired = false;
  noTown = false;
  canActivate = false;
  isSsoLogin = false;
  isDataAvailabel = false;
  isTaggingError = false;
  subsequentLogins = false;
  cookieAlert = false;
  showLowerBox = false;
  inProcess = true;
  isThirdPartyCookies = true;

  countries: any = [];
  towns: any = [];
  taggingArray = [];
  approveRejectArray = [];
  isTaggingData = false;
  totalApproveRejectLen = 0;

  coachee: any = {
    id: '',
    username: '',
    firstName: '',
    lastName: '',
    email: '',
    emailTwo: '',
    country: '',
    town: '',
    timezone: '',
    phoneOne: '',
    phoneTwo: '',
    photo: ''
  };
  model: any = {
    'remPW': false
  };
  errorMsg = '';
  isScrollDown = false;

  constructor(public dataService: DataService, private router: Router, private ts: TranslateService, private route: ActivatedRoute) {
  }

  async ngOnInit() {
    const self = this;

    if (!navigator.cookieEnabled) {
      this.cookieAlert = true;
      this.isThirdPartyCookies = false;
    } else {
      const isCooChk: any = localStorage.getItem('coochk');

      if (isCooChk !== 'done') {
        try {
          this.dataService.setTPCookies().subscribe(setResponse => {
            if (setResponse.success) {
              this.dataService.getTPCookies().subscribe(getResponse => {
                this.cookieAlert = getResponse.cookieExist === false;
                this.inProcess = false;

                if (!this.cookieAlert) {
                  localStorage.setItem('coochk', 'done');
                }
              });
            }
          });
        } catch (e) {
          // console.log('Failed');
          this.inProcess = false;
        }
      } else {
        this.cookieAlert = false;
        this.inProcess = false;
      }

      this.countries = this.dataService.getCountriesList();

      if (this.dataService.coachee) {
        this.coachee = Object.assign({}, this.dataService.coachee);

        if (this.coachee.country) {
          const lastTown = this.coachee.town;
          this.getTownsName(null);
          this.coachee.town = lastTown;
        }

        if (this.dataService.authToken && localStorage.getItem('user')) {
          this.router.navigate(['landing']);
        }
      }

      try {
        localStorage.removeItem('pulseAuthentication');

        const savedLogin: any = JSON.parse(localStorage.getItem('autoLogin'));
        if (savedLogin && savedLogin.remPW) {
          this.model.remPW = savedLogin.remPW;
          this.model.userName = savedLogin.userName;
          this.model.password = savedLogin.password;

          this.login();
        }
      } catch (e) {
        // alert('cookies disabled');
      }

      this.route.queryParams.subscribe(params => {
        if (params['token']) {
          this.manageSSOLogin(params);
        }
      });
    }


  }

  public manageSSOLogin(params: { [key: string]: any; }) {
    this.isSsoLogin = true;
    this.loginPage = false;

    return this.dataService.loginSso(params['token']).subscribe(
      result => {
        this.handleLogin(result, true);
      }, error => {
        this.ssoMessage = JSON.parse(error.message).message;
      });
  }

  setLang(e) {
    const lang: string = e.target.value;
    this.ts.use(lang);
    this.dataService.curLang = lang;
    this.countries = this.dataService.getCountriesList();
    // localStorage.setItem('curLang', lang);
  }

  login() {
    // For testing, after login move to country list section that comes after agreement.
    // Keep it commented in normal routine
    // this.getDetails();  return false;

    this.dataService.login(this.model.userName, this.model.password).subscribe(
      result => {
        this.handleLogin(result, false);
      },
      error => {
        // this.message = 'invalid_unpw';  // Use translation code instead of plain message.
        this.message = error.errorMsg ? error.errorMsg : 'invalid_unpw';
        this.isInvalid = true;
      });
  }

  handleLogin(result: any, isSsoLogin: boolean) {
    if (result && result.success) {
      this.dataService.setUserLocale(result.userId, this.dataService.curLang).subscribe();

      localStorage.setItem('autoLogin', JSON.stringify(this.model));
      localStorage.setItem('curLang', this.dataService.curLang);

      this.dataService.loginResponse.locale = this.dataService.curLang;
      if (result.pathways && result.pathways.length > 0) {
        localStorage.setItem('pathways', JSON.stringify(result.pathways));
      }
      this.continueLogging(result);
    } else {
      if (isSsoLogin) {
        this.ssoMessage = result.message;
      } else {
        this.message = result.message;
        this.isInvalid = true;
      }
    }
  }

  async continueLogging(result) {
    this.userId = result.userId;
    this.coachee.timezone = result['timezone'];
    this.isPWExpired = result['passwordExpired?'];
    if (this.isPWExpired) {
      this.message = 'Error! Password has been expired.';
      this.isInvalid = true;
      location.href = '#/resetPassword?resetPWT=' + this.dataService.dec2hex() + '&pwaToken=' + this.dataService.authToken;
      this.getDetails();
      return;
    }

    if (result['agreementsRequired?']) {
      this.dataService.getAgreement(result.userId).subscribe(response => {
        if (response) {
          this.container = false;
          if (response.required && response.required.length) {
            this.agreementPage = true;
            const required = response.required;
            this.requiredArray = required;
            for (let i = 0; i < this.requiredArray.length; i++) {
              this.requiredArray[i].approved = false;
            }
          }
          if (response.optional && response.optional.length) {
            this.optionalAgreementPage = true;
            const optional = response.optional;
            this.optionalArray = optional;
            for (let i = 0; i < this.optionalArray.length; i++) {
              this.optionalArray[i].approved = false;
            }
          }
        }
      });
    } else {
      this.dataService.setLocalStorage();
      // if (result.pathways && result.pathways.length > 0) {
      //   const tagging: any = await this.getTaggingData();
      //   if (tagging && tagging.length > 0) {
      //     let isTaggingData = false;
      //     for (let i = 0; i < tagging.length; i++) {
      //       if (tagging[i].tagGroups.length > 0) {
      //         isTaggingData = true;
      //       }
      //     }
      //     if (isTaggingData) {
      //       this.userDetails = true;
      //       this.container = false;
      //       this.agreementPage = false;
      //       this.optionalAgreementPage = false;
      //       this.subsequentLogins = true;
      //     } else {
      //       this.router.navigate(['landing']);
      //     }
      //   } else {
      //     this.router.navigate(['landing']);
      //   }
      // } else {
      //   this.router.navigate(['landing']);
      // }
      this.router.navigate(['landing']);
    }
  }

  getTownsName(event) {
    this.noTown = false;
    this.towns = null;
    this.coachee.town = null;
    if (event) {
      this.coachee.country = event.target.value;
    }

    if (this.coachee.country) {
      const country = this.countries.filter(c => c.code === this.coachee.country);
      if (country.length > 0) {
        this.towns = country[0].towns;
      }
    } else {
      this.noTown = true;
    }
  }

  toggleRequiredCheckboxes(required: any) {
    required.approved = !required.approved;
    this.checkedBoxes = false;

    const checked = document.querySelectorAll('input[type=\'checkbox\']:checked').length;
    if (this.requiredArray.length === checked) {
      this.checkedBoxes = true;
    }
  }

  toggleOptionalCheckboxes(optional: any) {
    optional.approved = !optional.approved;
  }

  optionalAgreement() {
    if (this.optionalArray && this.optionalArray.length > 0) {
      this.optionalAgreementPage = true;
      this.container = false;
      this.agreementPage = false;
    } else {
      this.getDetails();
    }

  }

  backToLogin() {
    this.loginPage = true;
    this.pwdReset = false;
    this.pwdResetInstructions = false;
  }

  forgotPassword() {
    this.pwdReset = true;
    this.loginPage = false;
    this.pwdResetInstructions = false;
  }

  /*forgotPasswordx() {
    this.message = 'Error! Password has been expired.';
    this.isInvalid = true;
    location.href = '#/resetPassword?resetPWT=' + this.dataService.dec2hex() + '&pwaToken=' + this.dataService.authToken;
  }*/

  sendEmailToUser(email) {
    this.dataService.passwordReset(email).subscribe(
      result => {
        this.pwdReset = false;
        this.pwdResetInstructions = true;
      }
    );
  }

  getDetails() {
    this.userDetails = true;
    this.container = false;
    this.agreementPage = false;
    this.optionalAgreementPage = false;
    // this.getTaggingData();
  }

  onDDUpdate(retVal: any) {
    this.coachee.town = retVal;
  }

  setCountryDetails() {
    if (this.approveRejectArray.length > 0 || this.taggingArray.length > 0) {
      if (!(this.subsequentLogins)) {
        if (this.coachee.country === '') {
          this.errorMsg = 'country';
          this.isTaggingError = true;

          setTimeout(function () {
            const tlcY: any = document.getElementsByClassName('btscoach-login__contentWrapper')[0];
            tlcY.scrollTop = 5000;
          }, 100);
          return false;
        }
        if (this.coachee.timezone === '') {
          this.errorMsg = 'timezone';
          this.isTaggingError = true;

          setTimeout(function () {
            const tlcY: any = document.getElementsByClassName('btscoach-login__contentWrapper')[0];
            tlcY.scrollTop = 5000;
          }, 100);
          return false;
        }
      }

      const arr = [];
      this.taggingArray.forEach((source) => {
        source.tagGroups.forEach((group) => {
          if (group.tags.length > 0) {
            const obj: any = {
              id: group.tags[0].taggingId,
              modelId: source.sourceId,
              modelType: 'PathwayV2',
              tagId: (group.selectedID > 0) ? group.selectedID : '',
              tagName: (group.selectedName !== '') ? group.selectedName : group.tags[0].name,
              status: (group.mode === '') ? 'Approved' : group.mode
            };
            const compList = localStorage.getItem('pathways') ? JSON.parse(localStorage.getItem('pathways')) : [];
            if (compList.length > 0) {
              const findRestrict = compList.find((f) => f.id === source.sourceId);
              if (findRestrict && findRestrict.id) {
                obj.restrictedModelId = findRestrict.companyId;
                obj.restrictedModelName = 'Company';
                arr.push(obj);
              }
            }
          }
        });
      });
      // Check tagiings are empty or not
      for (const item of arr) {
        if ((item.tagId === '' || item.tagId === 0) && (item.tagName === '' || item.tagName === null)) {
          if (item.status !== 'Rejected') {
            this.isTaggingError = true;
            this.errorMsg = 'tagging';

            setTimeout(function () {
              const tlcY: any = document.getElementsByClassName('btscoach-login__contentWrapper')[0];
              tlcY.scrollTop = 5000;
            }, 100);

            return false;
          }
        }
      }
      const data = {
        source: 'Coach',
        taggings: arr
      };

      if (arr.length > 0) {
        this.dataService.postTaggingData('api/v1/tagging/approve', data).subscribe((res: any) => {
          if (res.models.length > 0) {
            if (this.subsequentLogins) {
              this.router.navigate(['landing']);
            } else {
              this.activateFunction();
            }
          }
        });
      } else {
        this.activateFunction();
      }
    } else {
      this.activateFunction();
    }
  }

  activateFunction() {
    if (!this.subsequentLogins) {
      if (this.coachee.country === '') {
        this.errorMsg = 'country';
        this.isTaggingError = true;

        setTimeout(function () {
          const tlcY: any = document.getElementsByClassName('btscoach-login__contentWrapper')[0];
          tlcY.scrollTop = 5000;
        }, 100);
        return;
      }
      if (this.coachee.timezone === '') {
        this.errorMsg = 'timezone';
        this.isTaggingError = true;

        setTimeout(function () {
          const tlcY: any = document.getElementsByClassName('btscoach-login__contentWrapper')[0];
          tlcY.scrollTop = 5000;
        }, 100);
        return;
      }
    }
    this.dataService.acceptAgreement(this.userId, this.requiredArray, this.optionalArray).subscribe(result => {
      if (result) {
        this.dataService.setCountryDetails(this.userId, this.coachee).subscribe(response => {
          if (response && response.user) {
            this.dataService.setLocalStorage();
            this.router.navigate(['landing']);
          }
        });
      }
    });
  }

  getTaggingData() {
    return new Promise((resolve) => {
      let url = 'api/v1/tagging/list_for?unapprovedOnly=true&editing=false&source=Coach&model=PathwayV2';
      const pathwayId: any = localStorage.getItem('pathways') ? JSON.parse(localStorage.getItem('pathways')) : [];
      if (pathwayId.length > 0) {
        let modelIds = '';
        pathwayId.forEach(obj => {
          modelIds += `&modelIds[]=${obj.id}`;
        });
        url += modelIds;
      }
      this.dataService.getTaggingData(url).subscribe((res: any) => {
        if (res.models && res.models.length > 0) {
          const data: [] = res.models;
          const arr = [];
          let isDataAvailabel = false;
          data.forEach((item: any, i: number) => {
            if (item.tagGroups.length > 0) {
              item.tagGroups.forEach((tag: any, n: number) => {
                tag.class = '';
                tag.action = 'read';
                tag.taggingBackgroundColor = '#f0f3f2';
                tag.selectedID = 0;
                tag.selectedName = '';
                tag.getTagList = {};
                tag.mode = '';
                isDataAvailabel = true;
              });
              this.isTaggingData = true;
            }
            arr.push(item);
          });
          if (!isDataAvailabel) {
            this.isDataAvailabel = true;
          }
          this.taggingArray = arr;
          this.totalApproveRejectArray();
        }
        resolve(this.taggingArray);
      }, (err) => { resolve([]); });
    });
  }

  totalApproveRejectArray() {
    if (this.taggingArray.length > 0) {
      for (let i = 0; i < this.taggingArray.length; i++) {
        if (this.taggingArray[i].tagGroups && this.taggingArray[i].tagGroups.length > 0) {
          this.totalApproveRejectLen = Number(this.totalApproveRejectLen + this.taggingArray[i].tagGroups.length);
        }
      }
    }
  }

  getTagList(tagGroup, parentIndex: number, selectedIndex: number) {
    const url = `api/v1/tag_groups/template_tags?source=Coach&tagGroupId=${tagGroup.id}`;
    this.dataService.getTaggingData(url).subscribe(res => {
      const data: any = res;
      if (data.tagGroups.length > 0) {
        if (this.taggingArray.length > 0) {
          this.taggingArray[parentIndex].tagGroups[selectedIndex].getTagList = data.tagGroups[0];

          this.taggingArray[parentIndex].tagGroups[selectedIndex].selectedID =
            (this.taggingArray[parentIndex].tagGroups[selectedIndex].getTagList.templates.length > 0) ?
              this.taggingArray[parentIndex].tagGroups[selectedIndex].getTagList.templates[0].tags[0].id : 0;

          this.taggingArray[parentIndex].tagGroups[selectedIndex].selectedName =
            (this.taggingArray[parentIndex].tagGroups[selectedIndex].getTagList.templates.length > 0) ?
              this.taggingArray[parentIndex].tagGroups[selectedIndex].getTagList.templates[0].tags[0].name : '';
        }
      }
    });
  }

  selectedTag(e, parentIndex, childIndex) {
    if (e.target.value === 'addNew' || e.target.value === '') {
      this.taggingArray[parentIndex].tagGroups[childIndex].selectedID = 0;
      this.taggingArray[parentIndex].tagGroups[childIndex].selectedName = '';
      this.taggingArray[parentIndex].tagGroups[childIndex].action = 'addNew';
    } else {
      this.taggingArray[parentIndex].tagGroups[childIndex].selectedID = Number(e.target.value);
      this.taggingArray[parentIndex].tagGroups[childIndex].selectedName =
        (e.target.options) ? e.target.options[e.target.options.selectedIndex].label : '';
    }
  }

  approve(tagGroup, parentIndex: number) {
    const index = _.findIndex(this.approveRejectArray, { id: tagGroup.id, modelId: parentIndex });
    if (index !== -1) {
      this.approveRejectArray.splice(index, 1);
    }
    tagGroup.modelId = parentIndex;
    this.approveRejectArray.push(tagGroup);
  }

  reject(tagGroup, parentIndex: number) {
    const index = _.findIndex(this.approveRejectArray, { id: tagGroup.id, modelId: parentIndex });
    if (index !== -1) {
      this.approveRejectArray.splice(index, 1);
    }
    tagGroup.modelId = parentIndex;
    this.approveRejectArray.push(tagGroup);
  }

  removeAll() {
    return new Promise((resolve) => {
      if (this.taggingArray.length > 0) {
        for (let i = 0; i < this.taggingArray.length; i++) {
          const modelId = this.taggingArray[i].sourceId;
          if (this.taggingArray[i].tagGroups && this.taggingArray[i].tagGroups.length > 0) {
            for (let j = 0; j < this.taggingArray[i].tagGroups.length; j++) {
              this.taggingArray[i].tagGroups[j].mode = 'Rejected';
              this.taggingArray[i].tagGroups[j].modelId = modelId;
              this.taggingArray[i].tagGroups[j].class = 'unapprove';
              this.reject(this.taggingArray[i].tagGroups[j], modelId);
            }
          }
        }
      }

      resolve(true);
    });
  }

  removeAllandProceed() {
    this.removeAll().then(retVal => {
      setTimeout(function () {
        this.setCountryDetails();
      }.bind(this), 100);
    });
  }

  back(tagGroup, curLang) {
    tagGroup.selectedID = (tagGroup.getTagList && tagGroup.getTagList.templates.length > 0) ?
      tagGroup.getTagList.templates[0].tags[0].id : 0;
    tagGroup.selectedName = (tagGroup.getTagList && tagGroup.getTagList.templates.length > 0)
      ? (tagGroup.getTagList.templates[0].tags[0].locales !== null) ?
        tagGroup.getTagList.templates[0].tags[0].locales[curLang] : '' : '';
    tagGroup.action = 'read';
    tagGroup.mode = '';
  }

  getScrollInfo(event) {
    const currentScrollPos = event.target.scrollTop;

    if (currentScrollPos > 10) {
      this.isScrollDown = true;
    }
  }

  doScrollDown() {
    const rvbox: any = document.getElementsByClassName('btscoach-login__contentWrapper')[0];
    rvbox.scrollTop = rvbox.offsetHeight - 30;
    this.isScrollDown = true;
  }

  checkDropdownValidation() {
    if (Number(this.totalApproveRejectLen) === this.approveRejectArray.length) {
      this.canActivate = true;
    }
  }

  ngDoCheck() {
    if (this.coachee.country && this.coachee.timezone) {
      this.checkDropdownValidation();
    } else {
      this.canActivate = false;
    }

    if (this.subsequentLogins) {
      this.checkDropdownValidation();
    }
  }

}
