import { AfterViewInit, Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { DataService } from '../../data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, FormArray, FormControl } from '@angular/forms';
import * as _ from 'lodash';
import { AngularMultiSelect } from 'angular2-multiselect-dropdown';
import { TooltipDirective } from 'ngx-bootstrap';
declare var jQuery: any;
declare var $: any;

@Component({
  selector: 'myobj-mbir_v2',
  templateUrl: './myobj-mbir_v2.component.html'
})
export class MBIRV2Component implements OnInit, AfterViewInit {
  pathwayId: number;
  mbirData: any = null;
  mbirId: string = null;
  isImpactOtherText = false;
  isResultOtherText = false;
  @Input() infoType: string;    // infoType = setting or review

  myMBIRV2: any = {
    scoreable: []
  };

  myMBIRForm: FormGroup;
  mindSetForm: FormGroup;
  behavioursForm: FormGroup;
  impactToList = [];
  resultToList = [];
  settings = {};
  rawObject = {
    from: '',
    to: '',
    initialScore: 0,
    initialScoreReevaluated: 0,
    reviewScore: 0
  };
  impactOtherTextForm: FormGroup;
  resultOtherTextForm: FormGroup;
  impactLazyContainer: any;
  resultLazyContainer: any;
  @ViewChild('impactRef', { static: false }) impactRef: AngularMultiSelect;
  @ViewChild('resultRef', { static: false }) resultRef: AngularMultiSelect;
  @ViewChild('poptip1', { static: false }) public tooltip1: TooltipDirective ;
  @ViewChild('poptip2', { static: false }) public tooltip2: TooltipDirective ;
  @ViewChild('poptip3', { static: false }) public tooltip3: TooltipDirective ;
  @ViewChild('poptip4', { static: false }) public tooltip4: TooltipDirective ;
  @ViewChild('poptip5', { static: false }) public tooltip5: TooltipDirective ;
  @ViewChild('poptip6', { static: false }) public tooltip6: TooltipDirective ;
  @ViewChild('poptip7', { static: false }) public tooltip7: TooltipDirective ;
  @ViewChild('poptip8', { static: false }) public tooltip8: TooltipDirective ;
  @ViewChild('poptip9', { static: false }) public tooltip9: TooltipDirective ;
  @HostListener('mouseup', ['$event']) onMouseUp(event: MouseEvent) {
    if (this.tooltip1) { this.tooltip1.hide(); }
    if (this.tooltip2) { this.tooltip2.hide(); }
    if (this.tooltip3) { this.tooltip3.hide(); }
    if (this.tooltip4) { this.tooltip4.hide(); }
    if (this.tooltip5) { this.tooltip5.hide(); }
    if (this.tooltip6) { this.tooltip6.hide(); }
    if (this.tooltip7) { this.tooltip7.hide(); }
    if (this.tooltip8) { this.tooltip8.hide(); }
    if (this.tooltip9) { this.tooltip9.hide(); }
  }

  constructor(
    private route: ActivatedRoute,
    private dataService: DataService,
    private router: Router,
    private fb: FormBuilder
  ) {
    const pathway = JSON.parse(localStorage.getItem('pathway'));
    this.mbirId = pathway.mbirV2Id;
  }

  ngOnInit() {
    this.createmyMBIRForm();
    this.createOtherTextForm();
    this.mbirv2_options();

    if (this.pathwayId !== this.dataService.pathwayId) {
      this.pathwayId = this.dataService.pathwayId;
      // this.getMBIR();
      setTimeout(()=> {this.getMBIR();}, 500);
    }

    this.settings = {
      text: 'Select Options',
      enableCheckAll: false,
      classes: 'myclass custom-class-example',
      limitSelection: 3,
      // badgeShowLimit: 2
    };

    this.impactOtherTextForm.get('otherText').valueChanges.subscribe(res => {
      this.impactToList.map(i => {
        if (i.itemDesc === 'Other') {
          i.itemName = 'Other - ' + res;
        }
      });
    });

    this.resultOtherTextForm.get('otherText').valueChanges.subscribe(res => {
      this.resultToList.map(i => {
        if (i.itemDesc === 'Other') {
          i.itemName = 'Other - ' + res;
        }
      });
    });
  }

  createmyMBIRForm() {
    this.myMBIRForm = this.fb.group({
      Shift: this.fb.group({
        note: [''],
        rating: this.fb.array([
          this.rawform()
        ])
      }),
      Mindset: this.fb.group({
        note: [''],
        rating: this.fb.array([
          this.rawform()
        ])
      }),
      Behaviour: this.fb.group({
        rating: this.fb.array([
          this.rawform()
        ])
      }),
      Impact: this.fb.group({
        rating: this.fb.array([
          this.fb.group({
            from: '',
            to: [[]],
            initialScore: '',
            initialScoreReevaluated: '',
            reviewScore: ''
          })
        ])
      }),
      Result: this.fb.group({
        note: [''],
        rating: this.fb.array([
          this.fb.group({
            from: '',
            to: [[]],
            initialScore: '',
            initialScoreReevaluated: '',
            reviewScore: ''
          })
        ])
      })
    });
  }

  createOtherTextForm() {
    this.impactOtherTextForm = this.fb.group({
      otherText: ''
    });
    this.resultOtherTextForm = this.fb.group({
      otherText: ''
    });
  }

  rawform() {
    return this.fb.group({
      from: '',
      to: '',
      initialScore: '',
      initialScoreReevaluated: '',
      reviewScore: ''
    });
  }

  addMindSet() {
    if ((<FormArray>this.myMBIRForm.get('Mindset.rating')).length < 2) {
      (<FormArray>this.myMBIRForm.get('Mindset.rating')).push(this.rawform());
    }
  }
  removeMindSet(_index: any) {
    if ((<FormArray>this.myMBIRForm.get('Mindset.rating')).length > 1) {
      (<FormArray>this.myMBIRForm.get('Mindset.rating')).removeAt(_index);
    }
  }

  addBehaviour() {
    if ((<FormArray>this.myMBIRForm.get('Behaviour.rating')).length < 5) {
      (<FormArray>this.myMBIRForm.get('Behaviour.rating')).push(this.rawform());
    }
  }

  removeBehaviour(_index: any) {
    if ((<FormArray>this.myMBIRForm.get('Behaviour.rating')).length > 1) {
      (<FormArray>this.myMBIRForm.get('Behaviour.rating')).removeAt(_index);
    }
  }
  mapAPIDataToForm(_data) {
    let obj = {};
    if (_data !== null) {
      _data.scoreable.map(elm => {
        if (elm.name === 'impact' || elm.name === 'result') {
          let newToArray = [];
          if (elm.name === 'impact') {
            const toArray = elm.rating.map(elm1 => {
              return elm1.to;
            });
            newToArray = this.impactToList.filter((item) => {
              return toArray.indexOf(item.itemName) >= 0;
            });
            const impactArray = this.impactToList.map(res => res.itemName);
            const otherText = _.difference(toArray, impactArray)[0];
            if (otherText !== null) {
              this.isImpactOtherText = true;
              const otherTextArray = this.impactToList.filter(item => {
                return item.itemName === 'Other';
              });
              newToArray = _.uniq(_.union(newToArray, otherTextArray), 'id');
              this.impactOtherTextForm.get('otherText').patchValue(otherText || '');
            }
          }

          if (elm.name === 'result') {
            const toArray = elm.rating.map(elm1 => {
              return elm1.to;
            });
            newToArray = this.resultToList.filter((item) => {
              return toArray.indexOf(item.itemName) >= 0;
            });
            const resultArray = this.resultToList.map(res => res.itemName);
            const otherText = _.difference(toArray, resultArray)[0];

            if (otherText !== null) {
              this.isResultOtherText = true;
              const otherTextArray = this.resultToList.filter(item => {
                return item.itemName === 'Other';
              });
              newToArray = _.uniq(_.union(newToArray, otherTextArray), 'id');
              this.resultOtherTextForm.get('otherText').patchValue(otherText || '');
            }
          }
          elm.rating = [elm.rating[0]];
          elm.rating[0].to = newToArray;
        }
        const newElm = _.cloneDeep(elm);
        delete newElm.name;
        obj[this.capitalize(elm.name)] = newElm;
      });
    } else {
      obj = this.myMBIRV2;
    }
    return obj;
  }

  capitalize(s) {
    return s[0].toUpperCase() + s.slice(1);
  }

  mapMBIRForm(_data) {
    const myMBIRdata = {
      scoreable: []
    };

    for (const key in _data) {
      let arrayData;
      if (key === 'Impact') {
        arrayData = _data[key].rating[0];
        const arrayToData = arrayData.to;
        if (arrayToData !== null && arrayToData.length) {
          arrayData = arrayToData.map(elm => {
            const returnData = _.cloneDeep(arrayData);
            if (elm.itemName === 'Other') {
              returnData.to = this.impactOtherTextForm.value.otherText;
            } else {
              returnData.to = elm.itemName;
            }
            return returnData;
          });
        } else {
          arrayData.to = null;
        }
        _data[key].rating = arrayData;
      }
      if (key === 'Result') {
        arrayData = _data[key].rating[0];
        const arrayToData = arrayData.to;
        if (arrayToData !== null && arrayToData.length) {
          arrayData = arrayToData.map(elm => {
            const returnData = _.cloneDeep(arrayData);
            if (elm.itemName === 'Other') {
              returnData.to = this.resultOtherTextForm.value.otherText;
            } else {
              returnData.to = elm.itemName;
            }
            return returnData;
          });
        } else {
          arrayData.to = null;
        }
        _data[key].rating = arrayData;
      }
    }

    for (const key in _data) {
      if (_data.hasOwnProperty(key)) {
        const element = _data[key];
        myMBIRdata.scoreable.push({
          name: key.toLocaleLowerCase(),
          ...element
        });
      }
    }
    return myMBIRdata;
  }

  getMBIR() {
    this.dataService.getMBIRV2(this.mbirId).subscribe(
      result => {
        const data = this.mapAPIDataToForm(result.mbir);
        // console.log('MBIR Data', data);
        this.patchFormData(data);
        // console.log('MY FORM: ', this.myMBIRForm.value);
      },
      error => {
        console.log(error);
      }
    );
  }

  patchFormData(_data) {
    this.myMBIRForm.reset();
    (<FormGroup>this.myMBIRForm.get('Mindset')).setControl('rating', this.fb.array([]));
    (<FormGroup>this.myMBIRForm.get('Behaviour')).setControl('rating', this.fb.array([]));
    if (_data.Mindset) {
      for (let i = 0; i < _data.Mindset.rating.length; i++) {
        (<FormArray>this.myMBIRForm.get('Mindset.rating')).push(this.rawform());
      }
    } else {
      (<FormArray>this.myMBIRForm.get('Mindset.rating')).push(this.rawform());
    }
    if (_data.Behaviour) {
      for (let i = 0; i < _data.Behaviour.rating.length; i++) {
        (<FormArray>this.myMBIRForm.get('Behaviour.rating')).push(this.rawform());
      }
    } else {
      (<FormArray>this.myMBIRForm.get('Behaviour.rating')).push(this.rawform());
    }

    this.myMBIRForm.patchValue(_data);
    const infoTypeClone = _.cloneDeep(this.infoType);
    this.infoType = '';
    setTimeout(() => {
      this.infoType = infoTypeClone;
    }, 100);
  }

  mbirv2_options() {
    this.dataService.getMBIRV2Options(this.mbirId).subscribe(
    result => {
      // console.log(result);
      this.impactToList = result['impact'].map((res, index) => {
        return { 'id': index, 'itemName': res, 'itemDesc': res };
      });
      this.resultToList = result['result'].map((res, index) => {
        return { 'id': index, 'itemName': res, 'itemDesc': res };
      });
    },
    error => {
      // console.log(error);
      this.dataService.saveStatus = 'error';
    });
  }

  cleanMBIRData(mbirData: any) {
    for (const key in mbirData) {
      if (mbirData.hasOwnProperty(key)) {
        const element = mbirData[key];
        for (const key1 in element) {
          if (element.hasOwnProperty(key1)) {
            let elm = element[key1];
            if (Array.isArray(elm)) {
              elm.map(res => {
                // console.log('res', res);
                return {
                  from: this.dataService.cleanInputs(res.from),
                  to: this.dataService.cleanInputs(res.to),
                  initialScore: this.dataService.cleanInputs(res.initialScore),
                  initialScoreReevaluated: this.dataService.cleanInputs(res.initialScoreReevaluated),
                  reviewScore: this.dataService.cleanInputs(res.reviewScore)
                };
              })
            } else {
              elm = this.dataService.cleanInputs(elm);
            }

          }
        }

      }
    }
    return mbirData;
  }

  saveMBIR() {
    if (!this.myMBIRForm.valid) {
      return false;
    } else {
      this.dataService.saveStatus = 'processing';
    }

    const _dataTobeSaved = this.mapMBIRForm(this.myMBIRForm.value);

    if (this.dataService.popup.data) {
      this.markSessionComplete(this.dataService.popup.data);
    }

    this.dataService.saveMBIRV2(this.mbirId, _dataTobeSaved).subscribe(
      result => {
        if (this.dataService.popup.data) {
          this.markSessionComplete(this.dataService.popup.data);
        }
        this.getMBIR();
        this.dataService.saveStatus = 'saved';
      },
      error => {
        // console.log(error);
        this.dataService.saveStatus = 'error';
      });
  }

  markSessionComplete(activity: any) {
    if (this.dataService.pulseType(activity.type, null)) return;

    if (!activity.complete) {
      this.dataService.unbookActivity(activity.id, true)
      .subscribe(result => {
        activity.complete = result.activity.complete;
      },
      error => {
        // console.log(error);
      });
    }
  }

  onItemSelectImpact(item: any) {
    console.log(item, this.myMBIRForm.value.Impact);
    if (item.itemDesc === 'Other') {
      this.isImpactOtherText = true;
      setTimeout(() => {
        this.scrollToBottom(this.impactLazyContainer);
      }, 50);
    }
  }
  OnItemDeSelectImpact(item: any) {
    if (item.itemDesc === 'Other') {
      this.isImpactOtherText = false;
    }
  }

  onItemSelectResult(item: any) {
    if (item.itemDesc === 'Other') {
      this.isResultOtherText = true;
      setTimeout(() => {
        this.scrollToBottom(this.resultLazyContainer);
      }, 50);
    }
  }
  OnItemDeSelectResult(item: any) {
    if (item.itemDesc === 'Other') {
      this.isResultOtherText = false;
    }
  }

  getControls(frmGrp: any, key: string) {
        if (frmGrp) {
            return (<FormArray>frmGrp.get(key)).controls;
        }
  }

  ngAfterViewInit() {
    this.impactLazyContainer = this.impactRef._elementRef.nativeElement.getElementsByClassName('lazyContainer')[0];
    this.resultLazyContainer = this.resultRef._elementRef.nativeElement.getElementsByClassName('lazyContainer')[0];
  }
  
  scrollToBottom(_element) {
    _element.lastChild.scrollIntoView()
  }

  ngDoCheck() {
    if (this.pathwayId !== this.dataService.pathwayId) {
      this.pathwayId = this.dataService.pathwayId;
      this.getMBIR();
    }

    this.dataService.getEmitter('save-mbirv2').subscribe((result) => {
      if (result === 'true' && !this.dataService.saveStatus) {
        this.dataService.emitData('save-mbirv2', null);
        this.saveMBIR();
      }
    });
  }
}
