import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { MasterGuard } from './guards/master.guard';
import { CoacheeAuthGuard } from './guards/coachee-auth.guard';
import { CoachAuthGuard } from './guards/coach-auth.guard';
import { ConfigLoaderGuard } from './guards/config-loader.guard';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { SlotsComponent } from './slots/slots.component';
import { LandingComponent } from './landing/landing.component';
import { MeComponent } from './landing/me-section/me.component';
import { CoachComponent } from './landing/coach-section/coach.component';
import { TimelineComponent } from './landing/timeline-section/timeline.component';
import { SessionLoaderComponent } from './landing/timeline-section/timeline.preloader';
import { EditProfileComponent } from './landing/edit-profile/edit-profile.component';
import { MyObjectivesComponent } from './landing/my-objectives/my-objectives.component';
import { WebRTCModule } from '@btsdigital/pulsewebrtc';
import { MBIRComponent } from './landing/myobj-mbir/myobj-mbir.component';
import { BookSessionComponent } from './landing/book-session/book-session.component';
import { SubmitButtonComponent } from './submit-button/submit-button.component';
import { BookingDetails } from './landing/book-details/book-details.component';
import { ResetpasswordComponent } from './resetpassword/resetpassword.component';
import { MyDropdownComponent } from './my-dropdown/my-dropdown.component';
import { TourtipsModule } from '@btsdigital/pulsetipsandtours';
import { ServiceModule } from './service.module';
import { TooltipModule, ModalModule } from 'ngx-bootstrap';
import { BenchFeedbackStatusPanel } from './bench-feedback/bfb-panel/status-panel.component';
import { BenchFeedback } from './bench-feedback/bench-feedback.component';
import { BehaviourPage } from './bench-feedback/step1/bp.component';
import { IdentifyRespondent } from './bench-feedback/step2/ir.component';
import { EmailPage } from './bench-feedback/step3/email.component';
import { SelfRating } from './bench-feedback/step4/rating.component';
import { ResultPage } from './bench-feedback/step5/result.component';
import { BoxplotChartComponent } from './charts/boxplot';
import { SurveyComponent } from './bench-feedback/survey.component';
import { ChartDefaults } from './charts/chartdefaults';
import { APP_ROUTES } from './app.routes';
import { SessionInfoComponent } from './landing/timeline-section/session-info/session-info.component';
import { HyperLinksComponent } from './landing/timeline-section/hyper-link/hyper-link.component';
import { TranslatePipe } from './translate.pipe';
import { LoggerService, PulseUtilitiesModule } from '@btsdigital/pulseutilities';
import { SyncCalendarComponent } from './landing/sync-calendar/sync-calendar.component';
import * as Raven from 'raven-js';
import { MyObjectivesv2Component } from './landing/my-objectives_v2/my-objectives_v2.component';
import { MBIRV2Component } from './landing/myobj-mbir_v2/myobj-mbir_v2.component';
import { MyCounterComponent } from './landing/my-counter/my-counter.component';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { CompleteYourDataComponent } from './landing/timeline-section/complete-your-data/complete-your-data.component';


const appRoutes = RouterModule.forRoot(APP_ROUTES, { useHash: true });

export class RavenErrorHandler implements ErrorHandler {
  handleError(err: any): void {
    try {
      Raven.captureException(err.originalError || err, {
        extra: {
          user: JSON.parse(localStorage.getItem("user")),
          Token: JSON.parse(localStorage.getItem("user")).authToken,
          TokenForPulse: JSON.parse(localStorage.getItem("user")).authorizationTokenForPulse
        }
      });
    } catch(e) {
      // console.log("Error localStorage");
    }
  }
}
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    SlotsComponent,
    LandingComponent,
    MeComponent,
    CoachComponent,
    TimelineComponent, SessionInfoComponent, SessionLoaderComponent, HyperLinksComponent,
    EditProfileComponent,
    MyObjectivesComponent,
    MBIRComponent,
    BookSessionComponent,
    MBIRComponent,
    SubmitButtonComponent,
    BookingDetails,
    ResetpasswordComponent,
    MyDropdownComponent,
    TranslatePipe,
    BenchFeedbackStatusPanel, BenchFeedback, BehaviourPage, IdentifyRespondent, EmailPage, SelfRating, ResultPage,
    SurveyComponent,
    BoxplotChartComponent,
    SyncCalendarComponent,
    MyObjectivesv2Component,
    MBIRV2Component,
    MyCounterComponent,
    CompleteYourDataComponent
  ],
  imports: [
    BrowserModule, FormsModule, ReactiveFormsModule,
    HttpClientModule,
    appRoutes, RouterModule,
    WebRTCModule,
    TourtipsModule,
    ModalModule.forRoot(),
    ServiceModule.forRoot(),
    TooltipModule.forRoot(),
    PulseUtilitiesModule,
    NgCircleProgressModule.forRoot({
      radius: 100,
      outerStrokeWidth: 15,
      innerStrokeWidth: 15,
      space: -15,
      outerStrokeColor: '#ff2452',
      innerStrokeColor: '#e7e8ea',
      subtitleFontSize: '50',
      animationDuration: 300,
      showTitle: false,
      showUnits: false,
      subtitleFontWeight: '400',
      animation: true,
      startFromZero: false,
      responsive: true,
    }),
    AngularMultiSelectModule
  ],
  providers: [
    MasterGuard,
    CoacheeAuthGuard,
    CoachAuthGuard,
    ConfigLoaderGuard,
    ChartDefaults,
    LoggerService,
    { provide: ErrorHandler, useClass: RavenErrorHandler }
  ],
  bootstrap: [AppComponent],
  exports: [RouterModule]
})
export class AppModule { }
