import {
    Component, Input, Output, EventEmitter,
    ChangeDetectorRef, ChangeDetectionStrategy,
    OnInit, DoCheck, OnDestroy
} from '@angular/core';

import * as $ from 'jquery';
declare var jQuery: any;
// declare var $: $;
window['$'] = window['jQuery'] = window['jquery'] = $;

@Component({
    selector: 'app-my-dropdown',
    templateUrl: './my-dropdown.component.html',
    styleUrls: ['./my-dropdown.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MyDropdownComponent implements OnInit, DoCheck, OnDestroy {
    @Input() values: any;
    @Input() default: any;
    @Input() placeholder: any;
    @Output() update = new EventEmitter<any>();

    nTowns: any = [];
    showResult = false;
    curIdx: number = null;

    constructor(private ref: ChangeDetectorRef) {}

    ngOnInit() {
        const self = this;
        this.curIdx = null;
        this.showResult = false;

        $(document).on('click', function(e) {
            if ($(e.target).hasClass('my-town-input') || $(e.target).hasClass('my-town-item')) {
                return;
            } else {
                self.getSelected(null);
                self.ref.detectChanges();
            }
        });
    }

    getSelected(town: string) {
        if (town) {
            this.default = town;
            this.showResult = false;
            this.update.emit(town);
        } else {
            this.showResult = null || false;
            return;
        }
    }

    filterList(event) {
        const upDnKey = event.which || event.keyCode;

        if (upDnKey === 38) {
            this.curIdx = this.curIdx - 1;
            if (this.curIdx < 0 || this.curIdx === null) {
                this.curIdx = 0;
            }

            this.getListItem();
        } else if (upDnKey === 40) {
            if (this.curIdx < 0 || this.curIdx === null) {
                this.curIdx = -1;
            }

            this.curIdx = this.curIdx + 1;

            if (this.curIdx >= this.nTowns.length) {
                this.curIdx = this.nTowns.length - 1;
            }

            this.getListItem();
        } else if (upDnKey === 13) {
            const myLst: any = document.getElementById('my_list');
            if (myLst) {
                this.getSelected(myLst.children[this.curIdx].innerText);
            }
        } else if (upDnKey === 27) {
            this.getSelected(null);
        } else {
            this.curIdx = null;
            const txt = event.target.value.toLowerCase();

            if (txt === '') {
                this.update.emit('');
            }

            if (txt.length > 0) {
                this.nTowns = [];
                // if(this.values.guid) this.values.remove('guid');
                this.nTowns = this.values.filter(c => c.substr(0, txt.length).toLowerCase() === txt);
                this.showResult = this.nTowns.length ? true : false;
                this.ref.detectChanges();
            } else {
                this.showResult = false;
            }
        }
    }

    getListItem() {
        if (this.curIdx >= 0 && this.nTowns.length > 0) {
            this.showResult = true;
            const myLst: any = document.getElementById('my_list');

            if (myLst) {
                for (let i = 0; i < myLst.children.length; i++) {
                    if ($(myLst.children[i]).hasClass('active')) {
                        $(myLst.children[i]).removeClass('active');
                    }
                }
                myLst.children.selectedIndex = this.curIdx;
                myLst.children[this.curIdx].selected = true;
                myLst.children[this.curIdx].classList.add('active');
                myLst.children[this.curIdx].scrollIntoView();
                // console.log(myLst.children[this.curIdx].innerText);
            }
        }
    }

    ngDoCheck() {
        if (this.showResult) {
            const iText: any = document.getElementsByClassName('my-town-input')[0];
            const iList: any = document.getElementById('my_list');

            if (iList) {
                iList.style.width = iText.offsetWidth + 'px';
            }
        }
    }

    ngOnDestroy() {
        $(document).unbind();
    }

}
