import { Component, Input, OnInit } from '@angular/core';
import { DataService } from '../../data.service';
import { BFService } from '../bench-feedback.service';

@Component({
  selector: 'behaviour-page',
  templateUrl: './bp.component.html'
})
export class BehaviourPage implements OnInit {
	@Input() locked;
	activity:any = null;
	behaviours:any = null;
	bList:any = [];
	reqRows: number = 1;
	defaultRows: number = 3;
	maxRow: number = 5;
	mbirBehaviour_from: string = "";
	mbirBehaviour_to: string = "";
	isPageLoaded: boolean = false;

	constructor(private dataService: DataService, public bfs: BFService) {
		//
	}

	ngOnInit() {
		var self = this;
		this.dataService.popup.bfbkStep = 1;
		this.dataService.saveStatus = null;
		
		if(this.bfs.mbirft.from || this.bfs.mbirft.to) {
			this.isPageLoaded = true;
		} else {
			this.getMBIR();
		}
		
		// this.dataService.popup.buttons.disabled = this.locked ? true : false;
		if(this.locked) {
			this.dataService.popup.buttons.show1 = false;
			this.dataService.popup.buttons.show2 = false;
		} else {
			this.dataService.popup.buttons.show1 = true;
			this.dataService.popup.buttons.show2 = true;
		}

		if(this.dataService.popup && this.dataService.popup.data) {
			this.activity = this.dataService.popup.data.activity;
			this.behaviours = this.activity.behaviours;
			if(this.behaviours) {
				this.bList = this.behaviours.list;
				this.bfs.bList = Object.assign([], this.bList);
			}
		}

		if(this.bList.length < this.defaultRows) {
			for(var i = this.bList.length; i < this.defaultRows; i++) {
				this.bList.push("");
			}
		}

		/*if(this.behaviours.coachApproved == "Rejected") {
			this.locked = false;
		}*/

		//if(!this.locked) {
			this.checkBehaviours();
		//}
	}

	checkBehaviours() {
		var inpCnt:number = 0;

		for(var i = 0; i < this.bfs.bList.length; i++) {
			if(this.bfs.bList[i]) {
				inpCnt++;
				this.bfs.bList[i] = this.dataService.cleanInputs(this.bfs.bList[i]);
			}
		}

		if(inpCnt >= this.reqRows) {
			this.dataService.popup.buttons.disabled = false;
			return true;
		} else {
			this.dataService.popup.buttons.disabled = true;
			return false;
		}
	}

	getMBIR() {
		if(this.bfs.mbirft.from || this.bfs.mbirft.to) return;

		this.dataService.getMBIR(this.dataService.pathwayId || 0).subscribe(
			result => {
				var mbirData:any = result.mbir;
				this.bfs.mbirft.from = mbirData.behaviour.from;
				this.bfs.mbirft.to = mbirData.behaviour.to;
				this.bfs.mbirft.cChoice = mbirData.behaviour.clientChoice;
				this.isPageLoaded = true;
			},
			error => {
				//console.log(error);
			}
		);
	}

}
