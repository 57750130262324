import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'my-counter',
  templateUrl: './my-counter.component.html',
  styleUrls: ['./my-counter.component.css']
})
export class MyCounterComponent implements OnInit {
  progress = 0;
  isEdit: boolean = false;
  @Input() editRequired: boolean = false;
  @Input() minval: number = 0;
  @Input() maxval: number = 10;
  @Input() countControl: FormControl;
  @Input() editControl: FormControl;
  constructor() { }

  ngOnInit() {
    this.isEdit = !this.editRequired;
    if (this.editRequired && (this.editControl && this.editControl.value)) {
      this.progress = this.editControl.value || 0;
    } else {
      this.progress = this.countControl.value || 0;
    }
  }

  updateProgress(_progress: number, _counterType: string) {
    if (_counterType === 'increase' && _progress < this.maxval) {
      // this.progress = Number(_progress) + this.calculateProgress();
      this.progress = _progress + 1;
    } else if (_counterType === 'decrease' && _progress > 0 && _progress > this.minval) {
      this.progress = _progress - 1;
    }
    if (this.editRequired) {
      this.editControl.setValue(this.progress);
    } else {
      this.countControl.setValue(this.progress);
    }
  }
  
  formatSubtitle = (percent: number) => {
    return `${this.progress}`;
  }

  // calculateProgress() {
  //   const incrementVal = 100 / this.maxval;
  //   return incrementVal;
  // }

}
