import { Component, Input, OnInit, HostListener, OnDestroy, DoCheck, ChangeDetectorRef } from '@angular/core';
import { DataService } from './../data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SignalRService } from '@btsdigital/pulsesignalr';
import { TranslateService } from '../translate.service';

import * as moment from 'moment';
import * as momentTimezone from 'moment-timezone';
import { Session } from 'selenium-webdriver';
import * as flowplayer from '../../assets/js/flowplayer.min.js';
import { environment } from '../../environments/environment';

declare var $: any;

const LOAD_NEW_PATHWAY = 'load-new-pathway';
const LOAD_NEW_PATHWAY_ON_TAB_CHANGE = 'load-new-pathway-on-tab-change';
const LOAD_NEXT_VIEW_ONLINE_SESSION = 'load-next-viewonline-session';
const LOAD_BACK_VIEW_ONLINE_SESSION = 'load-back-viewonline-session';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html'
})

export class LandingComponent implements OnInit, DoCheck {
  pathways: any = [];
  showPathways = false;
  tabWidth = 300;
  tabMargin = 45;
  selectedPathway: any;
  user: any = null;
  popup: any = {
    title: 'Content Title',
    view: false,
    type: null,
    data: null,
    class: null,
    footer: false,
    buttons: {
      'caption1': 'OK',
      'caption2': 'Cancel',
      'show1': false,
      'show2': false,
      'align': 'left'
    },
    closeBtn: false
  };

  ftabActive = 'all';    // me, timeline, coach and all
  curPage = 1;
  sidePanel = false;
  myOnlineView: any = '';
  meInSidePanel = false;
  prevScrollpos = 0;
  showIncomingCallBackdrop = false;
  tourData: any = null;
  tourJson: any = null;
  tourShow = false;
  tourButtons: any = {}
  isCompleteHidden: boolean = false;
  isLike: boolean;
  isRateLater: boolean = false;
  postComment: boolean = false;
  comments: Array<any>;
  comment: string;
  innerComment: string;
  isLikeorDislike: number = 0;
  isButtonfocus: boolean = false;
  isIE: boolean = false;
  isMobileView: boolean = false;
  customData: any = {
    "tourComplete": false,
    "redDot": []
  }

  tmpLat: any = 0;			// To get tabs scrolling on left/right
  tmpTabsW: any = 0;			// Tabs width
  tmpTabsAB: boolean = false;		// Tabs arrow buttons for left right movements
  tmpTabsCnt: number = 0;		// Count tabs for bubble popup message
  hideRedTip: boolean = false;		// One time show red tip on right arrow of tabs
  maxRedTip: boolean = false;		// Tiptext min/max text display toggle
  mbirMail: any = {
    show: false,
    sent: false,
    self: false,
    coach: false
  }
  mbirMail_v2: any = {
	show: false,
	sent: false,
	self: false,
	coach: false
  }
  flowPlayerLink = '';
  hideFooter: boolean = false;
  goNowClicked = false;
  tourStartIndex: number = 0;
  isFirstBooking: boolean = false;
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    const self = this;
    // event.target.innerWidth;
    const winWidth = window.innerWidth;
    if (winWidth <= 668) {
      this.isMobileView = true;
      this.ftabActive = 'timeline';
    } else {
      this.isMobileView = false;
      this.ftabActive = 'all';
    }

    this.pathwaysTabCounts();
  }

  @HostListener('window:keydown', ['$event']) onKeyDown(event) {
    if (event.ctrlKey == true && (event.which == '80')) { //cntrl + p
      event.preventDefault();
      this.printViewOnline();
    }
  }

  constructor(private route: ActivatedRoute, public dataService: DataService,
    private router: Router, private signalR: SignalRService,
    private translate: TranslateService, private cd: ChangeDetectorRef) {
    const winWidth = window.innerWidth;
    if (winWidth <= 668) {
      this.ftabActive = 'timeline';
    } else {
      this.ftabActive = 'all';
    }

    this.dataService.getEmitter('video_modalpopup').subscribe((res: any) => {
      this.flowPlayerLink = res.slink;
      if (res.isVideo) {
        this.removeHtml('100', 'hidden');

        setTimeout(() => {
          $('#player_container').attr('src', this.flowPlayerLink.toString());
          this.loadFlowplayer();
        }, 500)
      }
    });
  }

  ngOnInit() {
    const self = this;
    var ua = window.navigator.userAgent;
    var trident = ua.indexOf('Trident/');

    this.isLikeorDislike = 0;
    this.isIE = trident > 0 ? true : false;		// IE 11 => return version number
    this.isMobileView = window.innerWidth <= 767 ? true : false;
    this.user = JSON.parse(localStorage.getItem('user'));
    this.hideRedTip = localStorage.getItem("hideRedTip") == "true" ? true : false;
    this.dataService.curLang = this.user ? this.user.locale : 'en';
    this.translate.use(this.dataService.curLang);

    if (!this.tourData) {
      this.dataService.getTourJSON().subscribe(data => {
        this.tourData = data;
        this.tourButtons = {
          "exit": this.translate.translateMe('exit_tour'),
          "next": this.translate.translateMe('next_tour'),
          "done": this.translate.translateMe('done_tour'),
          "go_now": this.translate.translateMe('go_now')
        }

        this.tourJson = this.tourData[this.dataService.curLang];
      });
    }

    if (this.user && this.user.success) {
      setTimeout(function () {
        self.showTourTips(true);
      }, 2000);

      this.signalR.setUser(this.user.userId);
      // this.callerEmail = this.user.source.email;
      // this.callerName = this.user.source.email;
      this.dataService.getPathways().subscribe(
        result1 => {
          if (result1.pathways && result1.pathways.length) {
            var retPathways: any = result1.pathways.sort(this.sortByDate);
            var lnpw: any = null;
            this.pathways = this.sortByStatus(retPathways);

            for (let i = 0; i < this.pathways.length; i++) {
              this.pathways[i].selected = false;

              if (i === 0) {
                this.pathways[i].selected = true;
                this.selectedPathway = this.pathways[i];
              }
            }

            this.dataService.emitData(LOAD_NEW_PATHWAY, this.selectedPathway.id);
          } else {
            this.dataService.coach = null;
            this.dataService.coachee = null;
            this.dataService.saveStatus = null;
            this.popup.title = 'pwerr';			//Title name as per the language json key
            this.popup.type = 'no-pathways';
            this.popup.view = true;
            this.popup.class = 'bs-popup-size__noPathways';
            this.popup.footer = false;
            this.popup.buttons = null;
          }
        },
        error => {
          // console.log(error);
        }
      );
    } else {
      this.router.navigate(['login']);
    }
  }

  removeHtml(height, hidden) {
    const list = document.getElementById('videoPlayerContainer');
    list.style.height = height + '%';
    list.style.overflow = (hidden) ? 'hidden' : '';
    while (list.hasChildNodes()) {
      list.removeChild(list.firstChild);
    }
    const childElement = document.createElement('div');
    childElement.setAttribute('id', 'player_container');
    childElement.style.height = '100%';
    list.appendChild(childElement);
  }

  async loadFlowplayer() {
    const src = $('#player_container').attr('src');
    const token = JSON.parse(localStorage.getItem('user'));

    const obj = {
      key: this.dataService.flowPlayerKey,
      src,
      token: token.authorizationTokenForPulse
      // ui: flowplayer.ui.NO_VOLUME_CONTROL | flowplayer.ui.NO_MUTE | flowplayer.ui.NO_FULLSCREEN
    }

    flowplayer('#player_container', obj);
  }

  emailMBIR() {
    this.dataService.emailMbir(this.mbirMail.coach, this.mbirMail.self).subscribe(result => {
      this.mbirMail.show = true;
      this.mbirMail.sent = true;
      this.mbirMail.self = false;
      this.mbirMail.coach = false;

      setTimeout(function () {
        this.mbirMail.show = false;
        this.mbirMail.sent = false;
      }.bind(this), 3000);
      // console.log(result);
    },
      error => {
        this.mbirMail.show = false;
        this.mbirMail.sent = false;
        this.mbirMail.self = false;
        this.mbirMail.coach = false;
        // console.log(error);
      });

    // {"message":"Successfully emailed the MBIR","source":{"authToken":"dhGyqMXwPKHsmWCDNteQ","pathwayId":64,"coach":true,"coachee":true}}
  }

  emailMBIRv2() {
    const pathway: any      = JSON.parse(localStorage.getItem('pathway'));
    const coachEmail: any   = this.mbirMail_v2.coach ? this.dataService.coach[0].email : "";
    const coacheeEmail: any = this.mbirMail_v2.self ? this.dataService.coachee.email : "";

    this.dataService.emailMBIRV2(pathway.mbirV2Id, coacheeEmail, coachEmail, this.user.locale)
    .subscribe(result => {
      this.mbirMail_v2.show  = true;
      this.mbirMail_v2.sent  = true;
      this.mbirMail_v2.self  = false;
      this.mbirMail_v2.coach = false;

      setTimeout(function() {
        this.mbirMail_v2.show = false;
        this.mbirMail_v2.sent = false;
      }.bind(this), 5000);
    },
    error => {
      this.mbirMail_v2.show  = false;
      this.mbirMail_v2.sent  = false;
      this.mbirMail_v2.self  = false;
      this.mbirMail_v2.coach = false;
    });
  }

  printViewOnline() {
    if (!document.getElementsByClassName('modal-open')[0].hasAttribute('hidden')) {
      let frameElement = document.getElementById('frmViewOnline') as any;
      let tempArr = frameElement.getAttribute('src').split('/');
      let printDomain = tempArr[0] + '//' + tempArr[2];

      frameElement.contentWindow.postMessage('print', printDomain);
    } else {
      window.print();
    }
  }

  setLang(e) {
    this.dataService.setUserLocale(this.user.userId, e.target.value).subscribe();

    var lang: string = e.target.value;
    localStorage.setItem("curLang", lang);
    this.dataService.curLang = lang;
    if (this.dataService.loginResponse) {
      this.dataService.loginResponse.locale = lang;
      this.dataService.setLocalStorage();
    }

    this.translate.use(lang).then(() => {
      this.tourButtons = {
        "exit": this.translate.translateMe('exit_tour'),
        "next": this.translate.translateMe('next_tour'),
        "done": this.translate.translateMe('done_tour'),
        "go_now": this.translate.translateMe('go_now')
      }
      this.tourJson = this.tourData[this.dataService.curLang];
      this.dataService.emitData("update-reminders", "true");
    });
  }

  sortByStatus(result: any) {
    var tmpPathWay: any = [];

    for (let i = 0; i < result.length; i++) {
      if (result[i].status == 'Active') {
        tmpPathWay.push(result[i]);
      }
    }
    for (let i = 0; i < result.length; i++) {
      if (result[i].status == 'DNF') {
        tmpPathWay.push(result[i]);
      }
    }
    for (let i = 0; i < result.length; i++) {
      if (result[i].status == 'DNS') {
        tmpPathWay.push(result[i]);
      }
    }
    for (let i = 0; i < result.length; i++) {
      if (result[i].status == 'On Hold') {
        tmpPathWay.push(result[i]);
      }
    }
    for (let i = 0; i < result.length; i++) {
      if (result[i].status == 'Completed') {
        tmpPathWay.push(result[i]);
      }
    }

    return tmpPathWay;
  }

  sortByDate(a, b) {
    const aDate = new Date(a.startDate);
    const bDate = new Date(b.startDate);

    if (aDate < bDate) {
      return 1;
    }
    if (aDate > bDate) {
      return -1;
    } else {
      return 0;
    }
  }

  changeTab(event, p) {
    const tlc: any = document.getElementsByClassName('timeline-container')[0];
    let tabObj: any = null;

    // Move tab to center of the screen in mobile view
    if (tlc.offsetWidth <= 768) {
      if (event.target.offsetWidth === 300) {
        tabObj = event.target;
      } else {
        tabObj = event.target.parentElement;
      }

      let paEl = event.target.parentElement.parentElement.parentElement || event.target.parentElement.parentElement;
      paEl.scrollLeft = tabObj.offsetLeft - (((tlc.offsetWidth / 2) - 150) + tlc.offsetLeft);
    }

    for (let i = 0; i < this.pathways.length; i++) {
      this.pathways[i].selected = false;
    }

    p.selected = true;
    // p.redDot = false;
    this.selectedPathway = p;
    this.dataService.emitData(LOAD_NEW_PATHWAY, this.selectedPathway.id);
    this.dataService.emitData(LOAD_NEW_PATHWAY_ON_TAB_CHANGE, this.selectedPathway.id);
  }

  mobilePage(pg) {
    const self = this;
    this.ftabActive = pg;

    if (pg === 'timeline') {
      setTimeout(function () {
        self.dataService.emitData(LOAD_NEW_PATHWAY, self.selectedPathway.id);
      }, 500);
    }
  }

  viewPopup() {
    this.dataService.getEmitter('do-logout').subscribe((result) => {
      if (result === 'true') {
        this.logout();
      }
    });

    this.dataService.getEmitter('close-popup').subscribe((result) => {
      this.popup.view = false;
    });
  }

  closePopup() {
    let myFrame: any = document.getElementById('frmViewOnline');
    this.removeHtml('0', '');
    // let myVideo: any = document.getElementById('player_container');

    if (myFrame) myFrame.setAttribute("src", "about:blank");		// Reset Video after popup close
    // if (myVideo) myVideo.setAttribute('data-src', "");		// Reset Video after popup close

    if (this.dataService.popup && this.dataService.popup.data && this.dataService.popup.data.session) {
      let session: any = this.dataService.popup.data.session;
      if (session && session.rateable && (session.complete || session.complete == 'Completed') && !session.likeCommentData) {
        this.rateLater().then(res => {
          this.setSessionProperties();
        });
      }
      if (session) {
        this.dataService.popup.data.session.showLeftRating = false;
        this.dataService.popup.data.session.postComment = false;
        this.dataService.popup.data.session.showCommentBox = false;
        this.dataService.popup.data.session.isSubmit = false;
      }
    }

    this.isButtonfocus = false;
    this.dataService.saveStatus = null;
    this.isCompleteHidden = false;
    this.mbirMail.show = false;

    if (this.popup.type == "bench-feedback") {
      this.dataService.emitData(LOAD_NEW_PATHWAY, this.selectedPathway.id);
    }

    if (this.popup.type == 'complete-personal-data') {
      this.dataService.emitData("update-reminders", "first_booking");
    }

    if (this.popup.type == 'book-session' && this.isFirstBooking && localStorage.getItem('unapprovedTagsPresent') == 'true') {
      localStorage.removeItem('first_booking_present');
      localStorage.removeItem('unapprovedTagsPresent');
      this.dataService.saveStatus = null;
      this.dataService.popup.title = 'complete_personal_data';        //Title name as per the language json key
      this.dataService.popup.type = 'complete-personal-data';
      // this.dataService.popup.data = session;
      this.dataService.popup.view = true;
      this.dataService.popup.class = 'bs-popup-size__completeProfile';
      this.dataService.popup.footer = false;
      this.dataService.popup.buttons.caption1 = "";
      this.dataService.popup.buttons.caption2 = "";
      this.dataService.popup.buttons.show1 = false;
      this.dataService.popup.buttons.show2 = false;
      this.dataService.popup.buttons.align = "";
      this.dataService.popup.buttons.disabled = true;
      this.dataService.popup.viewSlots = false;
      return;
    }

    this.popup.view = false;
    this.popup.video = false;
    this.popup.type = null;
    this.popup.data = null;
    this.hideFooter = false;
    this.popup.viewSlots = false;
  }

  gotoBFBK(bfbkNo: number) {
    if (!this.popup.buttons.disabled) {
      this.dataService.emitData("bench_navi", bfbkNo);
    }
  }

  getClassStatus() {
    if ((this.dataService.pulseType(this.popup.data.session.type, null) &&
      (this.popup.data.session.hasOwnProperty('coName') && this.popup.data.session.coName != '')) ||
      this.popup.data.session.complete == 'Completed') {
      return 'half-circle__deactive';
    } else {
      return 'half-circle__active';
    }
  }

  saveRating(isOuterBox: any) {
    // this.dataService.pulseType(this.popup.data.session, null);
    if (this.comment) {
      this.comment = this.comment.trim();
    }
    if (isOuterBox == false) {
      this.dataService.postRequestContentRating("saveContentRating", {
        "contentName": this.popup.data.session.name,
        "likedStatus": this.isLikeorDislike || '',
        "comment": this.comment || [],
        "custom": { activityId: this.dataService.popup.data.session.id }
      }).subscribe(
        result => {
          if (result != null) {
            var data = result.data || null;
            if (!this.popup.data.session.likeCommentData) {
              if (data && data.comments) {
                for (let i = 0; i < data.comments.length; i++) {
                  data.comments[i].commentDate = momentTimezone.tz(data.comments[i].commentedOn,
                    this.dataService.coachee.timezone).format('Do MMMM YYYY');
                }
              }

              if (data && data.likedStatus) {
                this.popup.data.session.likeCommentData = {
                  'likeStatus': data.likedStatus || [],
                  'comments': data.comments
                };
              }
            }

            if (data && data.comments && (data.likedStatus == 1 || data.likedStatus == 2)) {
              this.dataService.popup.data.session.isSubmit = true;
              this.isCompleteHidden = false;
            }
          }

          this.comment = "";
          this.dataService.popup.data.session.showCommentBox = false;
        });
    }
    if (isOuterBox) {
      if (this.innerComment) {
        this.innerComment = this.innerComment.trim();
      }
      this.dataService.postRequestContentRating("saveContentRating", {
        "contentName": this.popup.data.session.name,
        "comment": this.innerComment,
        "custom": { activityId: this.dataService.popup.data.session.id }
      }).subscribe(result => {
        this.dataService.popup.data.session.postComment = false;
        let data = result.data;
        if (this.popup.data.session.likeCommentData) {
          if (this.popup.data.session.likeCommentData) {
            for (let i = 0; i < data.comments.length; i++) {
              data.comments[i].commentDate = momentTimezone.tz(data.comments[i].commentedOn,
                this.dataService.coachee.timezone).format('Do MMMM YYYY');
            }

            this.popup.data.session.likeCommentData.comments = data.comments;
          }
        }
        this.innerComment = "";
      });
    }
  }

  getLikeComments() {
    if (this.popup.data.session && ((this.dataService.pulseType(this.popup.data.session.type, null) &&
      (this.popup.data.session.hasOwnProperty('coName') && this.popup.data.session.coName !== '')) ||
      this.popup.data.session.complete !== 'Completed')) {
      if (!this.dataService.popup.data.session.likeCommentData && this.dataService.popup.data.rateable) {
        this.isCompleteHidden = true;
      }
    }
  }

  getLikeRating() {
    this.isCompleteHidden = true;
    this.comment = "";
  }

  getAllRating() {
    this.dataService.popup.data.session.showLeftRating = !this.dataService.popup.data.session.showLeftRating;
    this.isCompleteHidden = false;
    this.dataService.popup.data.session.showCommentBox = false;
    this.dataService.popup.data.session.postComment = false;
    this.isButtonfocus = !this.isButtonfocus;
    this.innerComment = "";
  }

  rateLater() {
    return new Promise(resolve => {
      this.dataService.pulseType(this.popup.data.session.type, null)
      this.dataService.postRequestContentRating("saveContentRating", {
        "contentName": this.popup.data.session.name,
        "likedStatus": this.isLikeorDislike,
        "comment": "",
        "custom": { activityId: this.dataService.popup.data.session.id }
      }).subscribe(
        result => {
          this.dataService.popup.data.session.postComment = false;
          this.isCompleteHidden = false;
          this.dataService.popup.data.session.showCommentBox = false;
          this.comment = "";

          if (!this.popup.data.session.likeCommentData) {
            this.popup.data.session.likeCommentData = {
              'likeStatus': this.isLikeorDislike,
              'comments': []
            };
          }

          resolve(true);
        });
    });

  }

  closeCommentBox() {
    this.dataService.popup.data.session.showCommentBox = false;
    this.dataService.popup.data.session.postComment = false;
    this.innerComment = "";
  }

  contentLikeDislike(isLikeDislike: any, onlySave: any) {
    this.isLikeorDislike = isLikeDislike;
    this.dataService.pulseType(this.popup.data.session, null);

    this.dataService.postRequestContentRating("saveContentRating", {
      "contentName": this.popup.data.session.name,
      "likedStatus": this.isLikeorDislike,
      "custom": { activityId: this.dataService.popup.data.session.id }
    }).subscribe(result => {
      if (onlySave) {
        if (this.dataService.popup.data.session &&
          this.dataService.popup.data.session.likeCommentData) {
          this.dataService.popup.data.session.likeCommentData.likeStatus = this.isLikeorDislike;
        } else {
          if (this.isLikeorDislike === 1) {
            this.isLike = true;
          } else if (this.isLikeorDislike === 2) {
            this.isLike = false;
          }
        }
      } else {
        this.isCompleteHidden = false;
        this.dataService.popup.data.session.showCommentBox = true;
      }
    });
  }

  getStatusTip(status: any) {
    var retVal: any = null;
    let langCode: string = "";

    switch (status) {
      case "DNS":
        retVal = "Did Not Start";
        langCode = "pw_dns";
        break;
      case "DNF":
        retVal = "Did Not Finish";
        langCode = "pw_dnf";
        break;
      case "On hold":
        retVal = "is On Hold";
        langCode = "pw_onhold";
        break;
      default:
        retVal = "is " + status;
        langCode = status == 'Active' ? "pw_active" : "pw_completed";
        break;
    }

    if (!retVal) retVal = status;

    // return "This Pathway " + retVal;
    return this.translate.translateMe(langCode);
  }


  saveClcik(event) {
    const isButton: boolean = event.target.localName == "button" ? true : false;
    const okPress: boolean = event.target.type === 'submit' ? true : false;

    if (!isButton || (okPress && this.popup.buttons && this.popup.buttons.disabled)) return;

    if (okPress && this.popup.type === 'edit-profile') {
      this.dataService.emitData('save-profile', 'true');
    } else if (okPress && this.popup.type === 'my-objectives') {
      this.dataService.emitData('save-mbir', 'true');
    } else if (okPress && this.popup.type === 'my-objectives_v2') {
      this.dataService.emitData('save-mbirv2', 'true');
    } else if (okPress && this.popup.type === 'confirm-booking-cancel') {
      this.dataService.emitData('confirm-booking-cancel', { 'success': true, 'data': this.popup.data.session });
    } else if (okPress && this.popup.type === 'bench-feedback') {
      if (!this.popup.buttons.disabled) {
        this.dataService.emitData('save-proceed', this.popup.data.activity);
      }
    } else if (!okPress && this.popup.type === 'bench-feedback') {
      this.dataService.emitData('save-draft', this.popup.data.activity);
    } else {
      this.popup.view = false;
    }
  }

  contactUs(opt: string) {
    if (opt == 'hlp') {
      // window.open('mailto:help-coach@bts.com', '_blank');
      location.href = 'mailto:help-coach@bts.com';
    } else if (opt == 'bug') {
      // window.open('mailto:ciab.bugs-coach@bts.com', '_blank');
      location.href = 'mailto:ciab.bugs-coach@bts.com';
    }

    this.sidePanel = false;
  }

  logout() {
    localStorage.removeItem('autoLogin');
    let user = localStorage.getItem('user');
    let domain = null;

    if (user)
      domain = JSON.parse(user).returnDomain;

    this.dataService.logout().subscribe(
      result => {
        if (result.message) {
          if (domain)
            window.location.href = domain;
          else
            this.router.navigate(['login']);
        }
      },
      error => {
        localStorage.removeItem('user');
        if (domain)
          window.location.href = domain;
        else
          this.router.navigate(['login']);
      }
    );
  }

  getScrollStatus(event) {
    const currentScrollPos = event.target.scrollTop;
    if (this.prevScrollpos > currentScrollPos) {
      document.getElementById('mobileFooter').style.bottom = '0';
    } else {
      document.getElementById('mobileFooter').style.bottom = '-55px';
    }

    this.prevScrollpos = currentScrollPos;

    setTimeout(function () {
      const tlbox: any = document.getElementsByClassName('tlbox');
      const tlview: any = document.getElementById('timelineView');
      const targetLimit: number = tlview ? tlview.offsetHeight - 100 : null;
      let status = null;

      for (let i = 0; i < tlbox.length; i++) {
        if (tlbox[i].offsetTop > 0 && (tlbox[i].offsetTop - tlview.scrollTop) > 0 && (tlbox[i].offsetTop - tlview.scrollTop) <= targetLimit) {
          status = 'in';
        } else {
          status = 'out';
        }
      }
    }, 1500);
  }

  showTourTips(autoLoad: boolean) {
    if (autoLoad) {
      this.dataService.getCustomData().subscribe(res => {
        if (res && res.customData) {
          this.customData.tourComplete = res.customData.tourComplete || false;

          // this.tourShow = true;
          if (this.customData && !this.customData.tourComplete) {
            // this.dataService.emitData("tour-tips", "true");
            this.tourShow = true;
          }
        } else {
          // this.dataService.emitData("tour-tips", "true");
          this.tourShow = true;
        }
      });
    } else {
      // this.dataService.emitData("tour-tips", "true");
      this.tourShow = true;
    }

    this.sidePanel = false;
  }

  onUpdate(retVal: any) {
    if (retVal.exit) this.tourShow = false;
    if (retVal.tipCount > this.tourJson.length) {
      this.customData.tourComplete = true;
      this.dataService.saveCustomData(JSON.stringify(this.customData)).subscribe();
      this.tourShow = false;
    }
  }

  markSessionComplete(index) {
    if (this.dataService.pulseType(index.type, null) && (index.hasOwnProperty('coName') && index.coName != '')) return;

    var markStatus: boolean = null;
    markStatus = !index.complete;

    this.dataService.unbookActivity(index.id, markStatus).subscribe(
      result => {
        index.complete = result.activity.complete;
      },
      error => {
        // console.log(error);
      }
    );
    this.getLikeComments();
  }

  setSessionProperties() {
    if (this.dataService.popup && this.dataService.popup.data && this.dataService.popup.data.session) {
      if (this.dataService.popup.data.session.showLeftRating) {
        this.dataService.popup.data.session.showLeftRating = false;
      }
      if (this.dataService.popup.data.session.isSubmit) {
        this.dataService.popup.data.session.isSubmit = false;
      }
      if (this.dataService.popup.data.session.postComment) {
        this.dataService.popup.data.session.postComment = false;
      }
      if (this.dataService.popup.data.session.showCommentBox) {
        this.dataService.popup.data.session.showCommentBox = false;
      }
    }
    this.isLikeorDislike = 0;
    this.isCompleteHidden = false;
    this.isButtonfocus = false;
  }

  viewOnlineBackClicked(session: any) {
    if (session.complete === 'Completed' && !session.likeCommentData && session.rateable) {
      this.rateLater().then(res => {
        this.setSessionProperties();
        this.dataService.emitData(LOAD_BACK_VIEW_ONLINE_SESSION, session);
      });
    } else {
      this.setSessionProperties();
      this.dataService.emitData(LOAD_BACK_VIEW_ONLINE_SESSION, session);
    }
  }

  viewOnlineNextClicked(session: any) {
    if (session.complete === 'Completed' && !session.likeCommentData && session.rateable) {
      this.rateLater().then(res => {
        this.setSessionProperties();
        this.dataService.emitData(LOAD_NEXT_VIEW_ONLINE_SESSION, session);
      });
    } else {
      this.setSessionProperties();
      this.dataService.emitData(LOAD_NEXT_VIEW_ONLINE_SESSION, session);
    }

  }

  onTabsArrowClick(opt) {
    this.tmpLat = document.getElementsByClassName("pathways")[0];
    opt == 'right' ? this.tmpLat.scrollLeft += 634 : this.tmpLat.scrollLeft -= 634;
  }

  pathwaysTabCounts() {
    this.tmpLat = document.getElementsByClassName("pathways")[0];
    this.tmpTabsW = document.getElementsByClassName("pathways-tabs-cont")[0];

    if (this.tmpTabsW && this.tmpTabsW.scrollWidth > 0 && this.tmpLat && this.tmpLat.clientWidth > 0) {
      this.tmpTabsAB = (this.tmpTabsW.scrollWidth > this.tmpLat.clientWidth) ? true : false;

      let tabWidth: number = 304;	// tab width + margin
      let totalTabs: number = this.tmpTabsW.scrollWidth / tabWidth;
      let visibleTabs: number = (this.tmpLat.clientWidth - -this.tmpLat.scrollLeft) / tabWidth;
      this.tmpTabsCnt = Math.round(totalTabs - visibleTabs);

      if (window.innerWidth <= 768) {
        this.tmpTabsAB = false;
      }

      if (this.tmpTabsCnt == 0 && this.tmpLat.scrollLeft > 0 && !this.hideRedTip) {
        this.hideRedTip = true;
        localStorage.setItem("hideRedTip", "true");
      }
    }
  }

  ngDoCheck() {
    var self = this;
    this.popup = this.dataService.popup;

    if (this.popup.type === 'view-online' && !this.popup.view) {
      const myFrame: any = document.getElementById('frmViewOnline');
      if (myFrame && myFrame.src == "about:blank") myFrame.setAttribute('src', 'about:blank');
    } else if (this.popup.type === 'iCardSort') {
      const myFrame: any = document.getElementById('frmViewOnline');
      if (myFrame && myFrame.src == "about:blank") myFrame.setAttribute('src', this.popup.data.cs_url);
    }

    this.dataService.getEmitter('first-bk').subscribe((result) => {
      if (result == 'true') {
        this.isFirstBooking = true;
      }
    });

    this.viewPopup();
    this.pathwaysTabCounts();
  }

  hideSubmitBtns(evt) {
    this.hideFooter = evt;
  }

  goNowClick() {
    document.getElementById('tourtag-2').click();
    setTimeout(() => {
      this.goNowClicked = true;
    }, 500);
    
  }

}
