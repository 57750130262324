import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { DataService } from '../data.service';

@Injectable()
export class ConfigLoaderGuard implements CanActivate {

    constructor(private service: DataService) { }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        return new Promise((resolve) => {
            if (this.service.configLoaded) {
                resolve(true);
            } else {
                this.service.setConfig().then(() => {
                    resolve(true);
                });
            }
        });
    }
}
