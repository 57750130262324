import { LoginComponent } from "./login/login.component";
import { MasterGuard, GUARDS } from "./guards/master.guard";
import { ResetpasswordComponent } from "./resetpassword/resetpassword.component";
import { LandingComponent } from "./landing/landing.component";
import { SlotsComponent } from "./slots/slots.component";

/*****************************************************************************
 * Any new guards used, need to be added in GUARDS constant                  *
 * and also to the switchCase in activateGuard function of master.guard.ts   *
 *                                                                           *
 * Adding MasterGuard to any new route is MANDATORY                          *
 *****************************************************************************/

export const APP_ROUTES = [
    {
        path: '',
        component: LoginComponent,
        canActivate: [MasterGuard]
    }, {
        path: 'login',
        component: LoginComponent,
        canActivate: [MasterGuard]
    }, {
        path: 'resetPassword',
        component: ResetpasswordComponent,
        canActivate: [MasterGuard]
    }, {
        path: 'ssoHandler',
        component: LoginComponent,
        canActivate: [MasterGuard]
    }, {
        path: 'landing',
        component: LandingComponent,
        canActivate: [MasterGuard],
        data: {
            guards: [GUARDS.COACHEEAUTH]
        }
    }, {
        path: 'slots/:sessionId/:pathwayId',
        component: SlotsComponent,
        canActivate: [MasterGuard],
        data: {
            guards: [GUARDS.COACHEEAUTH]
        }
    }
];