import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Observer } from 'rxjs';
//import { Http, Headers, Response, RequestOptions } from '@angular/http';
import {
  HttpResponse as Response, 
  HttpClient as Http, 
  HttpHeaders as Headers, 
  HttpRequest as RequestOptions
} from '@angular/common/http';

import { DataService } from '../data.service';
import { Router, ActivatedRoute } from '@angular/router';

@Injectable()
export class CoacheeAuthGuard implements CanActivate {

  constructor(private service: DataService, private router: Router) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return new Promise((resolve) => {
      if (this.service.authToken && localStorage.getItem('user')) {
        resolve(true);
      } else {
        this.router.navigate(['login']);
        resolve(false);
      }
    });
  }
}
