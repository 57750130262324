import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from '../../data.service';
import { TranslateService } from '../../translate.service';

import * as _ from 'lodash';
import * as moment from 'moment';
import * as momentTimezone from 'moment-timezone';

@Component({
  selector: 'app-book-session',
  templateUrl: './book-session.component.html',
  styleUrls: ['./book-session.component.css']
})
export class BookSessionComponent implements OnInit {
  @Input() session: any;
  coachee: any;
  slots: Array<any> = [];
  isLoaded: boolean = false;
  bookProcess: boolean = false;
  pulseAssessmentSlots: Array<any> = [];
  hasError: boolean = false;
  isGroupSession: boolean = false;
  popMessage: string = null;
  confirmMsg: boolean = false;
  confirmYes: boolean = false;
  confirmSlotId: any = null;
  curBookDateTime: any = null;

  constructor(
    private route: ActivatedRoute,
    public dataService: DataService,
    private router: Router,
    private ts: TranslateService,
    private chdRef: ChangeDetectorRef
  ) { }

  ngOnInit() {
    if(this.session && this.session.type == "PodSession" &&
      this.session.status == "Booked") {
      this.curBookDateTime = this.session.podDetails.booking.dateTime;
    }

    if (this.session && this.dataService.pulseType(this.session.type, null)) {
      let startDate = momentTimezone.tz(this.dataService.coachee.timezone).toISOString();

      if(!this.session.details) {
        this.session.details = {
          "coName": this.session.coName || null,
          "experienceName": this.session.experienceName || null,
          "pulseServer": this.session.pulseServer || null,
          "moduleName": this.session.moduleName
        }
      }

      this.dataService.authenticateUser(this.session.details).subscribe(res => {
        this.dataService.getAvailableSessions(startDate, this.session.details.moduleName).subscribe(result => {
          if (result.GetAvailableSessionsResult && result.GetAvailableSessionsResult.sessions && result.GetAvailableSessionsResult.sessions.length > 0) {
            this.pulseAssessmentSlots = result.GetAvailableSessionsResult.sessions;
            this.generateTimeSlot(result.GetAvailableSessionsResult.sessions.filter(session => session.assigned === false));
          } else {
            this.isLoaded = true;
            this.popMessage = null;
          }
        },
        error => {
          this.isLoaded = true;
          this.popMessage = null;
          //console.log(error);
        }
        );
      });
    } else {
      if((this.session.sessionType == "GroupCoachingSession" ||
        this.session.sessionType == "Group Coaching Session") || 
        (this.session.type == 'PodSession' && 
        (this.dataService.coachee && this.dataService.coachee.id) != (this.session.podDetails && this.session.podDetails.leaderUserId) && 
        this.session.sessionType == 'Closed')
      ) {
        this.isLoaded = true;
        this.isGroupSession = true;
        this.popMessage = this.ts.translateMe('podbk_by_leader');
      } else {
        this.dataService.getSlots(this.session.id).subscribe(
          result => {
            this.isLoaded = true;
            this.popMessage = null;

            if (result.error || !result.slots || (result.slots && result.slots.length==0)) {
              this.popMessage = this.ts.translateMe('no_slot');
              localStorage.setItem('first_booking_present', 'no_slot');
            } else {
              this.slots = result.slots;
              this.generateTimeSlot(result.slots);
            }
          },
          error => {
            this.isLoaded = true;

            if(!this.slots.length) {
              this.popMessage = this.ts.translateMe('no_slot');
              localStorage.setItem('first_booking_present', 'no_slot');
            }
          }
        );
      }
    }
  }

  checkIfFirstBookingPresent(sections: any) {
    localStorage.setItem('first_booking_present', 'false');
    for (const section of sections) {
      for (const activity of section.activities) {
        if (activity.type === 'CoachingSessionV2' && activity.status === 'Booked') {
          localStorage.setItem('first_booking_present', 'true');
          return true;
        }
      }
    }
    return false;
  }

  generateTimeSlot(slots: Array<any>) {
    let timeSlot = [];

    if(this.dataService.coachee && !this.dataService.coachee.timezone) {
      this.dataService.coachee.timezone = "Europe/London";
    }

    if(this.dataService.coach && this.dataService.coach.length>0) {
      if(!this.dataService.coach[0].timezone) {
        this.dataService.coach[0].timezone = "Europe/London";
      }
    } else if(this.dataService.coach && !this.dataService.coach.length) {
      if(!this.dataService.coach.timezone) {
        this.dataService.coach.timezone = "Europe/London";
      }
    }

    for (let i = 0; i < slots.length; i++) {
      let startTime = '';
      
      if (this.dataService.curLang == 'KO' || this.dataService.curLang == 'ko') {
        startTime = momentTimezone.tz(slots[i].startDateTime, this.dataService.coachee.timezone).format('YYYY/MM/DD');
      } else {
        startTime = momentTimezone.tz(slots[i].startDateTime, this.dataService.coachee.timezone).format('DD MMM, dddd');
      }
      
      let tmpTime = momentTimezone.tz(slots[i].startDateTime, this.dataService.coachee.timezone).format('YYYY/MM/DD');
      let foundIndex = _.findIndex(timeSlot, { dateString: startTime });
      
      if (foundIndex > -1) {
        let obj = {
          id: slots[i].id,
          time: momentTimezone.tz(slots[i].startDateTime, this.dataService.coachee.timezone).format('hh:mm A'),
          srtm: momentTimezone.tz(slots[i].startDateTime, this.dataService.coachee.timezone).format('HH:mm'),
        }
        timeSlot[foundIndex].timeSlots.push(obj);
      } else {
        let obj = {
          dateOrg: slots[i].startDateTime,
          dateString: startTime,
          dateSorted: tmpTime,
          timeSlots: [{
            id: slots[i].id,
            time: momentTimezone.tz(slots[i].startDateTime, this.dataService.coachee.timezone).format('hh:mm A'),
            srtm: momentTimezone.tz(slots[i].startDateTime, this.dataService.coachee.timezone).format('HH:mm'),
          }]
        }

        timeSlot.push(obj);
      }
    }

    timeSlot = _.each(timeSlot, (t) => {
      t.timeSlots = _.sortBy(t.timeSlots, ['srtm']);
      t.timeSlots = _.chunk(t.timeSlots);
    });

    timeSlot = _.sortBy(timeSlot, ['dateSorted']);

    this.slots = timeSlot;
    this.isLoaded = true;
    this.popMessage = null;

    if(this.chdRef) {
      this.chdRef.detectChanges();
    }
  }

  btnConfirmClick(opt: any) {
    if(opt=='yes') {
      this.confirmMsg = false;
      this.confirmYes = true;
      this.selectSessionSlot(this.confirmSlotId);
    } else {
      this.confirmMsg = false;
      this.confirmYes = false;
    }

    this.chdRef.detectChanges();
  }

  selectSessionSlot(slotId) {
    if(this.bookProcess) return;

    if (this.session.type=="PodSession" && !this.confirmYes) {
      this.confirmSlotId = slotId;
      this.confirmMsg = true;
      // this.dataService.popup.view = false;

      this.chdRef.detectChanges();
      return;
    }

    this.bookProcess = true;

    if (this.session && this.dataService.pulseType(this.session.type, null)) {
      this.dataService.authenticateUser(this.session.details).subscribe(res => {
        this.dataService.scheduleCoachParticipant(this.session.details.coName, slotId, this.session.details.moduleName).subscribe(
          result => {
            if (result.ScheduleCoachParticipantResult && result.ScheduleCoachParticipantResult.success) {
              let slotTime = this.pulseAssessmentSlots.find(slot => slot.id === slotId);
              if(!slotTime) {
                slotTime = {
                  "startDateTime": "0000-00-000"
                }
              }
              this.session.booking = { id: slotId, dateTime: slotTime.startDateTime, moduleName: this.session.details.moduleName, experienceName: this.session.details.experienceName };
              this.session.status = 'Booked';
              this.bookProcess = false;
              // this.dataService.popup.view = false;
              this.dataService.emitData("update-reminders", "true");
            } else {
              this.popMessage = result.ScheduleCoachParticipantResult.errorMessage
            }
          },
          error => {
            this.bookProcess = false;
            this.popMessage = error;
          }
        )
      });
    } else {
      //get pathway
      const sections = JSON.parse(localStorage.getItem('pathway')).sections;
      this.dataService.bookSlot(this.session.id, slotId).subscribe(
        result => {
          if (result && (result.id == this.session.id || result.status == "Booked")) {
            this.session.booking = result.booking;
            this.session.status = result.status;
            // this.dataService.popup.view = false;
            this.popMessage = this.ts.translateMe('session_booked');
            //this.popMessage = this.ts.translateMe('pod_book_confirm');
            this.bookProcess = false;
            this.confirmYes = false;

            this.dataService.emitData("update-reminders", "true");
            if (this.checkIfFirstBookingPresent(sections)) {
              //this is beyond 1st booking
              setTimeout(() => {
                this.checkForUnapprovedTags();
              }, 1000);
            } else {
              //suppose after first booking user closed modal by clicking close icon on right top before going to complete your personal data model.
              this.dataService.emitData("first-bk", "true");
            }
          }
        },
        error => {
          this.bookProcess = false;
          this.confirmYes = false;
          //console.log(error);
        }
      );
    }

    this.chdRef.detectChanges();
  }

  checkForUnapprovedTags() {
    let url = 'api/v1/tagging/list_for?source=Coach&model=PathwayV2&unapprovedOnly=true';
    const pathwayId: any = localStorage.getItem('pathways') ? JSON.parse(localStorage.getItem('pathways')) : [];
    if (pathwayId.length > 0) {
      let modelIds = '';
      pathwayId.forEach(obj => {
        modelIds += `&modelIds[]=${obj.id}`;
      });
      url += modelIds;
    }
    this.dataService.getTaggingData(url).subscribe(async (res: any) => {
      if (res.models && res.models.length > 0) {
        const data = res.models;
        const arr = [];
        for (const item of data) {
          if (item.tagGroups.length > 0) {
            for (const tag of item.tagGroups) {
              if (tag.tags[0].name == '') {
                const text = this.ts.translateMe('reminder_text');
                if(!this.isReminderAlready(this.ts.translateMe('reminder_compare_text'))) {
                  this.dataService.reminders.push(text);
                }
              }
            }
          }
        }
      }
    })
  }

  isReminderAlready(txt: string) {
    if (!this.dataService.reminders || !this.dataService.reminders.length) {
      return false;
    }

    for (let i = 0; i < this.dataService.reminders.length; i++) {
      const n = this.dataService.reminders[i].indexOf(txt);

      if (n >= 0) {
        return true;
      }
    }

    return false;
  }

  ngDoCheck() {
    if(!this.isLoaded) {
      this.popMessage = this.ts.translateMe('loading');
    }

    if(this.popMessage && this.popMessage != this.ts.translateMe('loading')) {
      this.dataService.popup.footer = true;
      this.dataService.popup.buttons.caption1 = ""
      this.dataService.popup.buttons.caption2 = this.ts.translateMe('ok');
      this.dataService.popup.buttons.show1 = false;
      this.dataService.popup.buttons.show2 = true;
      this.dataService.popup.buttons.align = "center"
    } else {
      this.dataService.popup.footer = false;
      this.dataService.popup.buttons.caption1 = ""
      this.dataService.popup.buttons.caption2 = ""
      this.dataService.popup.buttons.show1 = false;
      this.dataService.popup.buttons.show2 = false;
      this.dataService.popup.buttons.align = ""
    }
    this.dataService.popup.buttons.disabled = false;

  }

}
