import { Component, Input, OnInit } from '@angular/core';
import { DataService } from '../../data.service';
import { BFService } from '../bench-feedback.service';

@Component({
  selector: 'email-page',
  templateUrl: './email.component.html'
})
export class EmailPage implements OnInit {
	activity: any = null;
	perEmail: any = null;

	@Input() locked;

	constructor(private dataService: DataService, public bfs:BFService) {
		//
	}

	ngOnInit() {
		this.dataService.popup.bfbkStep = 3;
		this.dataService.saveStatus = null;
		
		if(this.locked) {
			this.dataService.popup.buttons.show1 = false;
			this.dataService.popup.buttons.show2 = false;
		} else {
			this.dataService.popup.buttons.show1 = true;
			this.dataService.popup.buttons.show2 = true;
		}

		if(this.dataService.popup && this.dataService.popup.data) {
			this.activity = this.dataService.popup.data.activity;
			this.perEmail = this.activity.personalisedEmail;

			if(this.perEmail) {
				this.bfs.email_subject = this.perEmail.subject;
				this.bfs.email_body = this.perEmail.body;
				this.bfs.email_fixedBody1 = this.perEmail.fixedBody1;
				this.bfs.email_fixedBody2 = this.perEmail.fixedBody2;

				this.checkValid();
			}
		}
	}

	checkValid() {
		if(!this.bfs.email_subject || this.bfs.email_subject == '') {
			this.dataService.popup.buttons.disabled = true;
		} else {
			this.bfs.email_subject = this.dataService.cleanInputs(this.bfs.email_subject);
			this.bfs.email_body = this.dataService.cleanInputs(this.bfs.email_body);
			this.dataService.popup.buttons.disabled = false;
		}
	}
}