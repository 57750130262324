import { Component, Input, OnInit, AfterViewInit } from '@angular/core';
import { DataService } from '../data.service';
import { BFService } from './bench-feedback.service';
import { TranslateService } from '../translate.service';

declare var renderSurveyPage: any;
declare var surveyError: any;
declare var jQuery: any;
declare var $: any;

@Component({
	selector: 'survey',
	templateUrl: './survey.component.html'
})
export class SurveyComponent implements OnInit, AfterViewInit {
	@Input() locked;

	isError: boolean = false;
	errNo: number = 0;
	inProcess: boolean = false;
	isReset: boolean = false;

	constructor(private dataService: DataService, public bfs: BFService, private translate: TranslateService) {
		//
	}

	ngOnInit() {
		//
	}

	reGenSurvey() {
		this.dataService.reGenSurveyData(this.dataService.popup.data.activity.id).subscribe(retVal=> {
			this.isReset = true;
			this.isError = false;
			this.inProcess = false;
			this.dataService.popup.buttons.disabled = true;
			this.dataService.popup.footer = false;
			this.dataService.popup.buttons = [];
		});
	}

	ngAfterViewInit() {
		this.inProcess = true;
		this.bfs.loadSurveyHTML(this.dataService.popup.data.activity.round, true).then(result => {
			this.inProcess = false;

			const retVal: any = result;
			const retMsg: any = (retVal.msg && retVal.msg != 'Error occurred') ? JSON.parse(retVal.msg) : null;
			
			if(!retVal.success) {
				if(retMsg && retMsg.name == "TokenExpiredError") {
					this.errNo = 1;
					this.isError = true;
				} else {
					this.errNo = 2;
					this.isError = true;
				}
			} else {
				var self: any = this;

				this.isError = false;
				this.errNo = 0;

				surveyError().then(retVal => {
					self.dataService.popup.buttons.disabled = !retVal.success;
				});

				// let svRow: any = document.getElementsByClassName("sv_row");
				// console.log('svRow -> ', svRow.length);

				/*if(svRow.length == 1) {
					this.errNo = 3;
					this.isError = true;
				}*/
			}
		});
	}

	ngDoCheck() {
		let self: any = this;
		var n:number = parseInt($(".ratingBox").html());
		$(".ratingBox").html("");
		$(".ratingBox").removeClass("ratingBox").addClass("lockedSurvey ratingVal-" + n);

		if(this.isError || this.inProcess) {
			this.dataService.popup.buttons.show1 = false;
			this.dataService.popup.buttons.show2 = false;
			this.dataService.popup.buttons.disabled = true;
		}

		$(".sv_p_root > div > span")
		.each(function(i, j) {
			var text = $(this).text();
			$(this).text(text.replace('Behaviour', self.translate.translateMe("sr_behavior")));
		});

		$(".sv_main .sv_container .sv_body .sv_p_root .sv_q_title")
		.each(function(i, j) {
			var text = $(this).text();
			i==1 ? $(this).text(self.translate.translateMe("comments")) : '';
		});

		$(".sv_main .sv_container .sv_body .sv_progress .sv_progress_bar > span")
		.each(function(i, j) {
			if(self.dataService.curLang == "ja") {
				var text = $(this).text().replace("Page", "").replace("of", "/");
				$(this).text(
					text.substr(0,7) + " " + self.translate.translateMe("page")
				);
			} else {
				var text = $(this).text();
				$(this).text(
					text.replace("Page", self.translate.translateMe("page"))
					.replace("of", self.translate.translateMe("of"))
				);
			}
		});

		$('.sv-sada').each(function(i, j) {
			var text = $(this).text();
			if(self.dataService.curLang == "ja") {
				$(this).html(i==0 ? self.translate.translateMe("dagree") : self.translate.translateMe("agree"));
			} else {
				$(this).html(self.translate.translateMe("strongly") + "<br>" + (i==0 ? self.translate.translateMe("dagree") : self.translate.translateMe("agree")));
			}
		});

		$(".sv_main .sv_q_erbox > div > span")
		.each(function(i, j) {
			var text = $(this).text();
			i==1 ? $(this).text(self.translate.translateMe("aws_quest")) : '';
		});
	}
}