import { Injectable } from "@angular/core";
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from "@angular/router";

// import all the guards in the application
import { ConfigLoaderGuard } from "./config-loader.guard";
import { CoachAuthGuard } from "./coach-auth.guard";
import { CoacheeAuthGuard } from "./coachee-auth.guard";

import { DataService } from "../data.service";
import { LoggerService } from "@btsdigital/pulseutilities";

@Injectable()
export class MasterGuard implements CanActivate {

    // you may need to include dependencies of individual guards if specified in guard constructor
    constructor(private dataService: DataService, private router: Router, private logger: LoggerService) { }

    private route: ActivatedRouteSnapshot;
    private state: RouterStateSnapshot;

    // This method gets triggered when the route is hit
    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {

        this.route = route;
        this.state = state;

        if (!route.data.guards) {
            this.route.data = {
                guards: [GUARDS.CONFIGLOADER]
            };
        } else if (route.data.guards) {
            this.route.data.guards.unshift(GUARDS.CONFIGLOADER);
        }

        return this.executeGuards();
    }

    // Execute the guards sent in the route data 
    private executeGuards(guardIndex: number = 0): Promise<boolean> {
        return this.activateGuard(this.route.data.guards[guardIndex])
            .then(() => {
                if (guardIndex < this.route.data.guards.length - 1) {
                    return this.executeGuards(guardIndex + 1);
                } else {
                    return Promise.resolve(true);
                }
            })
            .catch(() => {
                return Promise.reject(false);
            });
    }

    // Create an instance of the guard and fire canActivate method returning a promise
    private activateGuard(guardKey: string): Promise<boolean> {

        let guard: ConfigLoaderGuard | CoachAuthGuard | CoacheeAuthGuard;

        switch (guardKey) {
            case GUARDS.CONFIGLOADER:
                guard = new ConfigLoaderGuard(this.dataService);
                break;
            case GUARDS.COACHAUTH:
                guard = new CoachAuthGuard(this.dataService, this.router, this.logger);
                break;
            case GUARDS.COACHEEAUTH:
                guard = new CoacheeAuthGuard(this.dataService, this.router);
                break;
            default:
                break;
        }
        return guard.canActivate(this.route, this.state);
    }
}

export const GUARDS = {
    CONFIGLOADER: "CONFIGLOADER",
    COACHAUTH: "COACHAUTH",
    COACHEEAUTH: "COACHEEAUTH"
}
