import { Component, Input, OnInit } from '@angular/core';
import { DataService } from '../../data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '../../translate.service';

import * as moment from 'moment';
import * as momentTimezone from 'moment-timezone';

@Component({
  selector: 'book-details',
  templateUrl: './book-details.component.html'
})
export class BookingDetails implements OnInit {
	popup = this.dataService.popup;
	
	constructor(private route: ActivatedRoute, private dataService: DataService, private router: Router, private ts: TranslateService) {
		//
	}

	ngOnInit() {
		if(this.popup.data.details && this.popup.data.details.sessionType == "Workshop") {
			this.popup.data.details = this.popup.data.details.eventDetails;
		} else if(this.popup.data.details && this.popup.data.details.type == "PodSession" && 
			this.popup.data.details.sessionType == 'Open') {
			this.popup.data.details = this.popup.data.details.podDetails;
		}

	    if(!this.popup.data.details) {
	      this.dataService.popup.footer = true;
	      this.dataService.popup.buttons.caption1 = ""
	      this.dataService.popup.buttons.caption2 = this.ts.translateMe('ok');
	      this.dataService.popup.buttons.show1 = false;
	      this.dataService.popup.buttons.show2 = true;
	      this.dataService.popup.buttons.align = "center"
	    } else {
	      this.dataService.popup.footer = false;
	      this.dataService.popup.buttons.caption1 = ""
	      this.dataService.popup.buttons.caption2 = ""
	      this.dataService.popup.buttons.show1 = false;
	      this.dataService.popup.buttons.show2 = false;
	      this.dataService.popup.buttons.align = ""
	    }
	    this.dataService.popup.buttons.disabled = false;
	}
	
	gotIt() {
		this.dataService.popup.view = false;
	}

	dateFormat(bookingDate) {
		// return moment.utc(bookingDate).format("DD MMM, YYYY");
		return momentTimezone.tz(bookingDate, this.dataService.coachee.timezone).format('DD MMM, YYYY');
	}

	ngDoCheck() {
		//
	}
}
