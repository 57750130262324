import { Component, Input, OnInit, ChangeDetectorRef } from '@angular/core';
import { DataService } from '../data.service';
import { BFService } from './bench-feedback.service';
import { TranslateService } from '../translate.service';

declare var triggerSaveSurvey: any;
declare var renderSurveyPage: any;
declare var require;

@Component({
	selector: 'bench-feedback',
	templateUrl: './bench-feedback.component.html'
})
export class BenchFeedback implements OnInit {
	curStep: number = 0;
	lastStep: number = 0;
	activity:any = null;
	round: number = 0;
	rStatus: any = {};
	bfb_intro:boolean = false;
	bfbIntroContent:any = null;
	steps: any = null;
	showWarning: boolean = false;

	tmpButtons: any = this.dataService.popup.buttons;	// show hide popup footer for warning message

	constructor(private dataService: DataService, public bfs: BFService, private chdRef: ChangeDetectorRef, private translate: TranslateService) {
		//
	}

	ngOnInit() {
		const langData:any = require('../../assets/lang/coach-'+ this.dataService.curLang +'.json');

		this.steps = langData.bfb_steps;
		this.bfbIntroContent = this.translate.translateMe("bfb_intro");

		this.bfs.bList = [];
		this.bfs.mbirft = { "from": null, "to": null }
		this.bfs.rList_name = [];
		this.bfs.rList_email = [];
		this.bfs.rList_role = [];
		// this.bfs.rList_link:any = [];
		this.bfs.email_subject = "";
		this.bfs.email_body = "";

		if(!this.activity) {
			this.activity = this.dataService.popup.data.activity;
		}

		this.round = this.activity.round;

		this.bfs.getActivityDetails(this.activity).then((res) => {
			this.rStatus = res;
			this.curStep = this.rStatus.stepNo;
			this.lastStep = this.rStatus.stepNo;
			// this.rStatus.statusName = this.rStatus.stepNo <=1 ? langData.bfb_status[0] : langData.bfb_status[parseInt(this.rStatus.stepNo)-1];

			if(this.lastStep == 1) {
				this.bfb_intro = true;
				this.dataService.bfbIntroPage = true;
			} else {
				this.dataService.bfbIntroPage = false;
			}

			this.dataService.getEmitter("bench_navi").subscribe((result1) => {
				if (result1) {
					this.curStep = result1;
				}
			});

			this.dataService.getEmitter("bfb_intro").subscribe((result2) => {
				if (result2 == true) {
					this.bfb_intro = true;
				} else {
					this.bfb_intro = false;
				}
			});

			if(this.round >= 2) {
				let isPrevUnlocked: boolean = false;
				let pathway: any = JSON.parse(localStorage.getItem('pathway'));

				for(let i=0; i<pathway.sections.length; i++) {
					let activities: any = pathway.sections[i].activities;

					for(let j=0; j<activities.length; j++) {
						let act: any = activities[j];

						if(act.type == "BenchFeedback" && act.round < this.round && !act.locked) {
							this.showWarning =  true;
							this.dataService.popup.buttons.disabled = true;
							this.dataService.popup.footer = false;
							this.dataService.popup.buttons = [];
							isPrevUnlocked = true;
						}

						if(isPrevUnlocked) break;
					}
					
					if(isPrevUnlocked) break;
				}
			} else {
				this.iagree_click();
			}

			this.chdRef.detectChanges();
		});
	}

	iagree_click() {
		this.showWarning = false;
		this.dataService.popup.buttons.disabled = false;
		this.dataService.popup.footer = true;
		this.dataService.popup.buttons = this.tmpButtons;
	}

	gotoStep(step:any) {
		let retVal = step.step_no <= this.lastStep ? this.curStep = step.step_no : this.curStep = this.lastStep;
		return retVal == 0 ? this.curStep = 1 : this.curStep = retVal;
	}

	openIntro() {
		this.bfb_intro = true;
		this.dataService.bfbIntroPage = true;
	}

	closeIntro() {
		this.bfb_intro = false;
		this.dataService.bfbIntroPage = false;
	}

	save_details(actId:number, proceed:boolean) {
		if (this.curStep == 1) {
			this.saveBehaviours(actId, proceed);
		} else if (this.curStep == 2) {
			this.saveRespondent(actId, proceed)
		} else if (this.curStep == 3) {
			this.saveEmail(actId, proceed);
		} else if (this.curStep == 4) {
			this.dataService.saveStatus = "processing";
			triggerSaveSurvey(proceed).then(result => {
				if(result && result.success) {
					if(proceed) {
						this.curStep++;
						this.lastStep++;
					} else {
						this.bfs.loadSurveyHTML(this.round, true);
					}

					this.dataService.saveStatus = "saved";
				} else {
					this.dataService.saveStatus = "error";
				}
			});
		} else if (this.curStep == 5) {
			//
		}
	}

	saveBehaviours(actId: number, proceed:boolean) {
		var jsonData = {
			"activityId": actId,
			"authToken": this.dataService.authToken,
			"submitted": proceed,
			"behaviours": this.bfs.bList
		}
		this.dataService.saveStatus = "processing";

		this.dataService.saveBehaviours(jsonData).subscribe((result) => {
			this.dataService.popup.data.activity = result;
			this.dataService.saveStatus = "saved";

			if(proceed) {
				this.curStep++;
				this.lastStep++;
			}
		},
		error => {
			// console.log(error);
			this.dataService.saveStatus = "error";
		});
	}

	saveRespondent(actId: number, proceed:boolean) {
		var resp:any = [];
		this.dataService.saveStatus = "processing";

		for(var i = 0; i < this.bfs.rList_name.length; i++) {
			if(this.bfs.rList_name[i] && this.bfs.rList_email[i] && this.bfs.rList_role[i]) {
				resp.push({
					"name": this.bfs.rList_name[i], 
					"email": this.bfs.rList_email[i], 
					"role": this.bfs.rList_role[i],
					"link": null
				});
			}
		}

		var jsonData:any = {
			"activityId": actId,
			"authToken": this.dataService.authToken,
			"submitted": proceed,
			"respondents": resp
		}

		// console.log(jsonData);
		this.dataService.saveRespondents(jsonData).subscribe((result) => {
			this.dataService.popup.data.activity = result;
			this.dataService.saveStatus = "saved";

			if(proceed) {
				this.curStep++;
				this.lastStep++;
			}
		},
		error => {
			// console.log(error);
			this.dataService.saveStatus = "error";
		});
	}

	saveEmail(actId: number, proceed:boolean) {
		var fixedBody1 = "This email was automatically generated by the BTS Coach system. This email was automatically generated by the BTS Coach system.";
		var fixedBody2 = "Thank you going through this email, we expect your 100% contribution.";
		var jsonData:any = {
			"activityId": actId,
			"authToken": this.dataService.authToken,
			"submitted": proceed,
			"subject": this.bfs.email_subject,
			"body": this.bfs.email_body,
			"fixedBody1": fixedBody1,
			"fixedBody2": fixedBody2
		}
		this.dataService.saveStatus = "processing";

		// console.log(jsonData);
		this.dataService.saveEmail(jsonData).subscribe((result) => {
			this.dataService.popup.data.activity = result;
			this.dataService.saveStatus = "saved";

			if(proceed) {
				this.curStep++;
				this.lastStep++;
			}
		},
		error => {
			// console.log(error);
			this.dataService.saveStatus = "error";
		});
	}

	ngDoCheck() {
		this.chdRef.detectChanges();

		this.dataService.popup.bfbkStepLst = this.lastStep;

		this.dataService.getEmitter("save-proceed").subscribe((result) => {
			if (result && !this.dataService.saveStatus) {
				// Save and Proceed
				this.save_details(result.id, true);
			}
		});
		this.dataService.getEmitter("save-draft").subscribe((result) => {
			if (result && !this.dataService.saveStatus) {
				// Save as Draft
				this.save_details(result.id, false);
			}
		});
	}
}
