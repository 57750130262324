import { Component, OnInit, Input, ViewChild, DoCheck } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SessionLoaderComponent } from './timeline.preloader';
import { DataService } from '../../data.service';
import { TLService } from './timeline.service';
import { TranslateService } from '../../translate.service';

declare var jQuery: any;
declare var $: any;
export let cordova;

// import * as _ from 'lodash';
import * as moment from 'moment';
import * as momentTimezone from 'moment-timezone';
import * as _ from 'lodash';

export const LOAD_NEW_PATHWAY = 'load-new-pathway';

@Component({
  selector: 'app-timeline-section',
  templateUrl: './timeline.component.html'
})
export class TimelineComponent implements OnInit, DoCheck {
  pathwayId: number;
  pathway: any;
  sections: any;
  activityId: any;
  showbookSession = false;
  session: any;
  displaySessions = false;
  doRefresh: boolean = null;
  grpNo: number = null;       // Group Expand Session Count Number

  constructor(
    private route: ActivatedRoute,
    public dataService: DataService,
    private router: Router,
    public tls: TLService,
    private ts: TranslateService
  ) { }

  ngOnInit() {
    if (this.route && this.route.params) {
      this.route.params.subscribe(params => {
        if (params['id']) {
          this.pathwayId = params['id'];
          this.getPathwayDetails(false);
        }
      });
    }

    // console.log(this.dataService.reminders);
  }

  generateDataForService(sessions): any {
    const getBookedSessionsFor = [];
    const getContentFor = [];
    let authenticationObj: any = null;
    const hasActivites = (sessions.length > 0 && sessions[0].activities != null);
    let loopFor = hasActivites ? sessions.map(s => s.activities) : sessions;
    loopFor = _.flatten(loopFor);
    for (let i = 0; i < loopFor.length; i++) {
      const currentPathway = loopFor[i];

      if (currentPathway && (currentPathway.pulseServer && currentPathway.moduleName && currentPathway.experienceName)
        && this.dataService.pulseType(currentPathway.type, null)) {

        if (!authenticationObj) {
          authenticationObj = currentPathway;
        }

        const foundIndex = _.findIndex(getBookedSessionsFor, { experienceName: currentPathway.experienceName });

        if (foundIndex > -1 && currentPathway && currentPathway.moduleName) {
          if (getBookedSessionsFor[foundIndex].modules && getBookedSessionsFor[foundIndex].modules.length > 0) {
            if (getBookedSessionsFor[foundIndex].modules.indexOf(currentPathway.moduleName) === -1) {
              getBookedSessionsFor[foundIndex].modules.push(currentPathway.moduleName);
            }
          } else {
            const modulesArray = [];
            if (currentPathway.moduleName) {
              modulesArray.push(currentPathway.moduleName);
            }
            getBookedSessionsFor[foundIndex].modules = modulesArray;
          }
        } else {
          const modulesArray = [];
          if (currentPathway.moduleName) {
            modulesArray.push(currentPathway.moduleName);
          }
          getBookedSessionsFor.push({
            experienceName: currentPathway.experienceName,
            modules: modulesArray
          });
        }
      }

      if ((currentPathway && currentPathway.pulseServer && currentPathway.experienceName) &&
        (this.dataService.pulseType(currentPathway.type, 'ps') || this.dataService.pulseType(currentPathway.type, 'pa')
          && currentPathway.moduleName && currentPathway.linkCoName)
        || (this.dataService.pulseType(currentPathway.type, 'pc') && currentPathway.coName)
      ) {

        if (!authenticationObj) {
          authenticationObj = currentPathway;
        }

        const foundIndex = _.findIndex(getContentFor, { experienceName: currentPathway.experienceName });
        const coName = currentPathway.linkCoName || currentPathway.coName;

        if (foundIndex > -1 && currentPathway && coName) {
          if (getContentFor[foundIndex].coNames && getContentFor[foundIndex].coNames.length > 0) {
            if (getContentFor[foundIndex].coNames.indexOf(coName) === -1) {
              getContentFor[foundIndex].coNames.push(coName);
            }
          } else {
            const coNamesArray = [];
            if (coName) {
              coNamesArray.push(coName);
            }
            getContentFor[foundIndex].coNames = coNamesArray;
          }
        } else {
          const coNamesArray = [];
          if (coName) {
            coNamesArray.push(coName);
          }
          getContentFor.push({
            experienceName: currentPathway.experienceName,
            coNames: coNamesArray
          });
        }
      }
    }

    return {
      param: getBookedSessionsFor,
      coParams: getContentFor,
      authenticationObj: authenticationObj
    };
  }

  getPathwayDetails(dataIds: any) {
    this.pathway = null;
    this.tls.oneGroupExpand = false;
    // this.dataService.bfbShowPanel = false;

    this.dataService.getPathwayDetails(this.pathwayId).subscribe(
      result => {
        this.dataService.emitTabChange(result.pathway);
        // result.pathway.sections = _.sortBy(result.pathway.sections, [function(o) { return o.booking.dateTime; }]);

        const sections = result.pathway.sections;
        const primary = result.pathway.coaches ? result.pathway.coaches.filter(function (c) { return c.primary; }) : [];

        localStorage.removeItem('pathway');
        localStorage.setItem('pathway', JSON.stringify(result.pathway));

        this.dataService.pathwayId = this.pathwayId;
        this.pathway = result.pathway;
        // this.dataService.coach = result.pathway.coaches ? (primary.length > 0 ? primary[0] : result.pathway.coaches[0]) :
        // result.pathway.coach;
        this.dataService.coach = result.pathway.coaches.length > 0 ? result.pathway.coaches : [result.pathway.coaches];
        this.dataService.coachee = result.pathway.coachee;
        this.dataService.respondentList = [];

        this.doWatchStatusChange(sections);

        for (let i = 0; i < sections.length; i++) {
          const act = sections[i].activities;

          for (let a = 0; a < act.length; a++) {
            if (act[a].type === 'BenchFeedback') {
              // this.dataService.bfbShowPanel = true;
              this.dataService.respondentList.push({
                'pathwayId': this.pathway.id,
                'round': act[a].round,
                'respondents': act[a].respondents.list,
                'lineManager': act[a].respondents.list.filter((res: any) => {
                  return res.role === 'LineManager';
                })[0]
              });
            }
          }
        }

        const refEXS = result.pathway.reflectiveExerciseSettings;
        const refExp = refEXS ? refEXS.experience : null;
        const refSrvr = refEXS ? refEXS.server : null;

        if (refExp && refSrvr) {
          const refExercise = {
            'coName': '',
            'experienceName': refExp,
            'pulseServer': refSrvr
          };

          // localStorage.removeItem('pulseAuthentication');
          this.dataService.authenticateUser(refExercise).subscribe(r => {
            this.postAuthenticate(result);
          }, err => {
            console.error(err);
          });
        } else {
          this.postAuthenticate(result);
        }
      },
      error => {
        // console.log(error);
      }
    );
  }

  doWatchStatusChange(sections: any) {
    const self = this;
    const iVal = setInterval(function () {
      for (let i = 0; i < sections.length; i++) {
        const act = sections[i].activities;

        for (let a = 0; a < act.length; a++) {
          if (self.tls.callStatus(act[a], self.dataService) === 'active') {
            act[a].enableCall = true;
          }
        }
      }
    }, 5000);
  }

  /*
  // This function moves the timeline items on bottom for the last item view automatically.
  autoScrollTimeline() {
    let xSession: any = null;
    const tlbox: any = document.getElementsByClassName('tlbox');
    const tlcY: any = document.getElementsByClassName('timeline-cont')[0];

    if (tlbox.length > 2) {
      for (let prop in tlbox) {
        const xNo: number = parseInt(prop);
        if (xNo) {
          // const sid = this.pathway.sections[xNo].id || null;

          xSession = this.pathway.sections[xNo];

          if ((xSession.status === 'Not Yet Booked' || xSession.status === 'Missed') ||
            (!xSession.complete && !xSession.hasOwnProperty('booking'))) {
            const tlbox2: any = document.getElementsByClassName('tlbox')[xNo];

            tlcY.scrollTop = tlbox2.offsetTop;
            return;
          }
        }
      }
    }
  }
  */

  postAuthenticate(result) {
    if (result.pathway.sections && result.pathway.sections.length > 0) {
      this.sections = result.pathway.sections;
      this.dataService.reminders = [];

      this.doRefreshChanges(result.pathway.sections);
      this.checkSessionReminders(result.pathway.sections);
    }

    this.checkScheduledCalls();
  }

  doRefreshChanges(sessions) {
    return new Promise((resolve, reject) => {
      const body = this.generateDataForService(sessions);

      if (body.coParams.length > 0 || body.param.length > 0) {
        return this.dataService.authenticateUser(body.authenticationObj).subscribe(result => {
          if (result) {
            delete body.authenticationObj;
            // this.dataService.reminders = [];

            return this.dataService.getBookedSession(body).subscribe(res => {
              if (res && res.GetBookedSessionsResult && res.GetBookedSessionsResult.success) {
                const hasActivities = (this.pathway.sections.length > 0 && this.pathway.sections[0].activities !== null);

                if (res.GetBookedSessionsResult.sessions && res.GetBookedSessionsResult.sessions.length > 0) {
                  for (let j = 0; j < res.GetBookedSessionsResult.sessions.length; j++) {
                    const currentSession = res.GetBookedSessionsResult.sessions[j];

                    if (hasActivities) {
                      for (let i = 0; i < this.pathway.sections.length; i++) {
                        if (this.pathway.sections[i].activities) {
                          const foundIndex = _.findIndex(this.pathway.sections[i].activities, (session) => {
                            const retVal = session &&
                            session.experienceName === currentSession.experienceName && session.moduleName === currentSession.moduleName;
                            resolve(retVal);
                            return;
                          });
                          if (foundIndex > -1) {
                            this.pathway.sections[i].activities[foundIndex].booking = {
                              id: currentSession.sessionId,
                              dateTime: currentSession.startDateTime,
                              moduleName: currentSession.moduleName,
                              experienceName: currentSession.experienceName,
                              closeDateTime: currentSession.closeDateTime
                            };
                            this.pathway.sections[i].activities[foundIndex].status = 'booked';
                          }
                        }
                      }
                    } else {
                      const foundIndex = _.findIndex(this.pathway.sections, (session) => {
                        const retVal = session &&
                        session.experienceName === currentSession.experienceName &&
                        session.moduleName === currentSession.moduleName;
                        resolve(retVal);
                        return;
                      });
                      if (foundIndex > -1) {
                        this.pathway.sections[foundIndex].booking = {
                          id: currentSession.sessionId,
                          dateTime: currentSession.startDateTime,
                          moduleName: currentSession.moduleName,
                          experienceName: currentSession.experienceName,
                          closeDateTime: currentSession.closeDateTime
                        };
                        this.pathway.sections[foundIndex].status = 'booked';
                      }
                    }
                  }
                }

                if (res.GetBookedSessionsResult.contentObjects && res.GetBookedSessionsResult.contentObjects.length > 0) {
                  for (let j = 0; j < res.GetBookedSessionsResult.contentObjects.length; j++) {
                    const currentCo = res.GetBookedSessionsResult.contentObjects[j];

                    if (hasActivities) {
                      for (let i = 0; i < this.pathway.sections.length; i++) {
                        if (this.pathway.sections[i].activities) {
                          const foundIndex = _.findIndex(this.pathway.sections[i].activities, (session) => {
                            const retVal = session &&
                            session.experienceName === currentCo.experienceName &&
                            session.coName === currentCo.coName;
                            resolve(retVal);
                            return retVal;
                          });
                          if (foundIndex > -1) {
                            const actComplete: any = this.pathway.sections[i].activities[foundIndex].complete || null;
                            this.pathway.sections[i].activities[foundIndex].complete = currentCo.completed ? 'Completed' : actComplete;
                          }
                        }
                      }
                    } else {
                      const foundIndex = _.findIndex(this.pathway.sections, (session) => {
                        const retVal = session &&
                        session.experienceName === currentCo.experienceName &&
                        session.moduleName === currentCo.moduleName;
                        resolve(retVal);
                        return retVal;
                      });
                      if (foundIndex > -1) {
                        const actComplete: any = this.pathway.sections[foundIndex].complete || null;
                        this.pathway.sections[foundIndex].complete = currentCo.completed ? 'Completed' : actComplete;
                      }
                    }
                  }
                }
              }

              this.displaySessions = true;
              resolve(true);
            },
            error => {
              // console.log(error);
              this.displaySessions = true;
              resolve(true);
            });
          } else {
            this.displaySessions = true;
            resolve(true);
          }
        });
      } else {
        this.displaySessions = true;
        resolve(true);
      }
    });
  }

  /*markSessionComplete(index) {
    const markStatus: boolean = !this.pathway.sections[index].complete;
    this.activityId = this.pathway.sections[index].id;

    this.dataService.unbookActivity(this.activityId, markStatus).subscribe(
      result => {
        this.pathway.sections[index].complete = result.activity.complete;

        // Refresh the reminders in coach section
        this.dataService.reminders = [];
        this.checkSessionReminders(this.pathway.sections);
      },
      error => {
        console.log(error);
      }
    );
  }*/

  timelineTips(cnt: number, tf: boolean) {
    const tipBox = document.getElementById('timelineTips_' + cnt);
    tipBox.style.visibility = tf ? 'visible' : 'hidden';
  }

  checkSessionReminders(sessions: any) {
    const tDate = new Date();
    let dDiff: number = null;
    let bookDate: any = null;
    let bookTime: any = null;
    const remMsg1Temp: any = [];
    let reminderMsg1: string = null;
    let reminderMsg2: string = null;
    let reminderMsg3: string = null;
    let reminderMsg4: string = null;
    let totDuration = 0;
    let index = 0;
    // const innerIndex = 0

    if (!sessions) {
      this.getPathwayDetails(false);
      sessions = this.sections;
      this.checkSessionReminders(sessions);
      return;
    }

    // My Code
    sessions.forEach((activitiesEle) => {
      index = 0;
      activitiesEle.activities.forEach((activity) => {
        // Reminder 1:
        if ((typeof activity.booking !== 'undefined') && (activity.booking !== null) && activity.booking.dateTime) {
          const bDate = activity.booking.dateTime;

          dDiff = this.dataService.dateDiff_inDays(tDate, bDate);

          if (dDiff >= 0 || this.tls.getSessionID(sessions, this.dataService) === activity.id) {
            bookDate = this.tls.dateFormat(bDate, false, this.dataService);
            bookTime = this.tls.dateFormat(bDate, true, this.dataService);
            const dt4Me = moment(bDate).format('YYYY-MM-DD');

            const uhav = this.ts.translateMe('youhv') + ` ` + this.ts.translateMe('the_session');

            if (activity.name && !reminderMsg1) {
              // if(activity.name === 'Coaching Session Five') debugger
              reminderMsg1 = uhav + ` '<b rel='${activity.id}'>${activity.name}</b>' on ${bookDate} at ${bookTime}.`;
              remMsg1Temp.push({ 'date': dt4Me, 'msg': reminderMsg1 });
              totDuration = 0;
              // this.dataService.reminders.push(reminderMsg1);

              for (let j = index - 1; j >= 0; j--) {
                if (!activitiesEle.activities[j].booking && activitiesEle.activities[j].complete !== 'Completed' &&
                  activitiesEle.activities[j].status !== 'Completed' &&
                  !this.dataService.pulseType(activitiesEle.activities[j].type, null) &&
                  activitiesEle.activities[j].type !== 'Event' &&
                  activitiesEle.activities[j].type !== 'PodSession' && activitiesEle.activities[j].type !== 'CoachingSessionV2'
                ) {
                  totDuration += activitiesEle.activities[j].estimatedTime || activitiesEle.activities[j].duration;
                } else {
                  break;
                }
              }

              if (totDuration > 0 && !reminderMsg2) {
                reminderMsg2 = this.ts.translateMe('youhv') +
                ` <b>${totDuration} min.</b> ` + this.ts.translateMe('remaining') + ` '<b>${activity.name}</b>'.`;
              }
            }
          }
        }

        // Reminder 3:
        // if(activity.bookingWindowUntil)
        // 	const ddiff: number = this.dataService.dateDiff_inDays(activity.bookingWindowUntil, tDate);
        // if(activity.name === 'Coaching Session Five') debugger
        if ((activity.status === 'Not Yet Booked' || activity.status === 'Missed') &&
          activity.type === 'CoachingSessionV2') {
          const click_here = this.ts.translateMe('click_here');
          const to_book = this.ts.translateMe('tobook_session');

          if (activity.name && !reminderMsg3 && !this.isReminderAlready('Click here')) {
            reminderMsg3 = `<a href='#' rel='${activity.id}'>` + click_here + `</a>` + to_book + ` '<b>${activity.name}</b>'.`;
          }
        }

        // Reminder 4:
        if (activity.type === 'PodSession' &&
          (activity.status !== 'Booked' && activity.status !== 'Missed' && activity.status !== 'Completed') &&
          ((this.dataService.isPodLeader('coachee', this.pathwayId) && activity.sessionType === 'Closed') ||
          (!this.dataService.isPodLeader('coachee', this.pathwayId) && activity.sessionType === 'Open'))
        ) {
          const click_here = this.ts.translateMe('click_here');
          // const to_book = this.ts.translateMe('tobook_session');
          const to_book4: any = this.ts.translateMe('to_book4');
          const on_behalf: any = this.ts.translateMe('on_behalf');

          if (activity.name && !reminderMsg4) {
            reminderMsg4 = `<a href='#' rel='${activity.id}'>` + click_here + `</a> ` +
            to_book4 + ` '<b>${activity.name}</b>' ` + on_behalf + `.`;
          }
        }

        index = index + 1;
      });
    });

    // Reminder 2: Will display First
    // estimatedTime is for pre-work activities and
    // duration is for session
    if (totDuration > 0) {
      if (!this.isReminderAlready('min.')) {
        this.dataService.reminders.push(reminderMsg2);
      }
    }

    // Reminder 1: Will display Second
    if (remMsg1Temp.length > 0) {
      const sortedArray: any = _.orderBy(remMsg1Temp, (obj: any) => {
        return obj.date;
      }, ['asc']);

      if (!this.isReminderAlready(' on ')) {
        this.dataService.reminders.push(sortedArray[0].msg);
      }
    }

    // Reminder 3: Will display third
    if (reminderMsg3) {
      this.dataService.reminders.push(reminderMsg3);
    }

    // Reminder 4: Will display fourth
    if (reminderMsg4) {
      this.dataService.reminders.push(reminderMsg4);
    }
  }

  checkReminderForTags(sections) {
    sections.forEach(section => {
      section.activities.forEach(activity => {
        if (activity.type === 'CoachingSessionV2' && activity.status === 'Booked') {
          this.checkForUnapprovedTags();
          return;
        }
      });
    });
  }

  checkForUnapprovedTags() {
    let url = 'api/v1/tagging/list_for?source=Coach&model=PathwayV2&unapprovedOnly=true';
    const pathwayId: any = localStorage.getItem('pathways') ? JSON.parse(localStorage.getItem('pathways')) : [];
    if (pathwayId.length > 0) {
      let modelIds = '';
      pathwayId.forEach(obj => {
        modelIds += `&modelIds[]=${obj.id}`;
      });
      url += modelIds;
    }
    this.dataService.getTaggingData(url).subscribe(async (res: any) => {
      if (res.models && res.models.length > 0) {
        const data = res.models;
        const arr = [];
        for (const item of data) {
          if (item.tagGroups.length > 0) {
            for (const tag of item.tagGroups) {
              if (tag.tags[0].name === '') {
                const text = this.ts.translateMe('reminder_text');
                if (!this.isReminderAlready(this.ts.translateMe('reminder_compare_text'))) {
                  this.dataService.reminders.push(text);
                }
              }
            }
          }
        }
      }
    });
  }

  isReminderAlready(txt: string) {
    if (!this.dataService.reminders || !this.dataService.reminders.length) {
      return false;
    }

    for (let i = 0; i < this.dataService.reminders.length; i++) {
      const n = this.dataService.reminders[i].indexOf(txt);

      if (n >= 0) {
        return true;
      }
    }

    return false;
  }

  doBookingCancel(session: any) {
    this.session = session;

    if (this.dataService.pulseType(session.type, null)) {
      this.dataService.authenticateUser(session).subscribe(res => {
        this.dataService.deleteScheduledCoachParticipant(session.coName, session.booking.id, session.moduleName).subscribe(result => {
          if (result.DeleteScheduledCoachParticipantResult && result.DeleteScheduledCoachParticipantResult.success) {
            this.closePopUp(true);
          } else {
            this.closePopUp(false);
          }
        });
      });
    } else {
      const self = this;

      this.dataService.unbookSession(session.id).subscribe(
        result => {
          if (result && result.id === session.id && (result.status === 'Not Yet Booked' ||
            result.status === 'Missed' || result.status === null)) {
            session.booking = result.booking;
            session.status = result.status;

            // Refresh the reminders in coach section
            this.dataService.reminders = [];
            this.checkSessionReminders(this.pathway.sections);
            this.checkReminderForTags(this.pathway.sections);
          }

          this.dataService.popup.view = false;

          // Refresh timeline
          // this.dataService.getPathwayDetails(this.pathwayId).subscribe(
          //   result => {
          //       localStorage.removeItem('pathway');
          //       localStorage.setItem('pathway', JSON.stringify(result.pathway));
          // });

          setTimeout(function() {
            self.dataService.emitData(LOAD_NEW_PATHWAY, self.pathway.id);
          });
        },
        error => {
          // console.log(error);
          this.dataService.popup.view = false;
        }
      );
    }
  }

  closePopUp(unBook: boolean) {
    if (unBook) {
      this.session.booking = null;
      this.session.status = 'Not Yet Booked';
      this.dataService.popup.view = false;

      // Refresh the reminders in coach section
      this.dataService.reminders = [];
      this.checkSessionReminders(this.pathway.sections);
    } else {
      // console.log('Session booking could\'t be cancel in past date');
      this.dataService.popup.view = false;
      /*setTimeout(function () {
        alert('Session booking could\'t be cancel in past date');
      });*/
    }
  }

  checkScheduledCalls() {
    const s = this.pathway.sections;

    for (let i = 0; i < s.length; i++) {
      // if(s[i].sessionType === 'Virtual Phone Call Coaching Session') {
      //   this.joinCall(s[i], this.dataService, true);
      //   console.log(s[i]);
      // }
      if (s[i].sessionType === 'Virtual Phone Call Coaching Session' && s[i].enableCall) {
        this.tls.joinCall(s[i], this.dataService, false);
      }
    }
  }

  addSerialNumber() {
    const i = 1;

    $('.srno').each(function (index) {
      $(this).html(index + 1);
    });
  }

  ngDoCheck() {
    this.addSerialNumber();

    if (this.dataService.popup.view && this.dataService.popup.type === 'view-online' && this.doRefresh === null) {
      this.doRefresh = false;
    }
    if (!this.dataService.popup.view && this.dataService.popup.type === 'view-online' && this.doRefresh === false) {
      this.doRefresh = true;
    }

    this.dataService.getEmitter('load-new-pathway').subscribe((result) => {
      if (result) {
        this.pathwayId = result;
        this.getPathwayDetails(result);
        this.dataService.getPathwayDetails(this.pathwayId).subscribe(
          result => {
            setTimeout(() => {
              this.checkReminderForTags(result.pathway.sections);
            }, 1000);
          });
      }
    });

    this.dataService.getEmitter('confirm-booking-cancel').subscribe((result) => {
      if (result) {
        this.doBookingCancel(result.data);
      }
    });

    this.dataService.getEmitter('update-reminders').subscribe((result) => {
      if (result === 'true') {
        // Refresh the reminders in coach section
        this.dataService.emitData('update-reminders', null);
        this.dataService.reminders = [];
        this.checkSessionReminders(this.pathway.sections);
        this.getPathwayDetails(false);
      } else if (result === 'first_booking') {
        this.checkReminderForTags(this.pathway.sections);
      }
    });

    if (this.doRefresh) {
      this.doRefresh = null;
      this.doRefreshChanges(this.pathway.sections);
      this.checkSessionReminders(this.pathway.sections);
      this.checkReminderForTags(this.pathway.sections);
    }
  }
}
