import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { DataService } from '../../../data.service';
import { TranslateService } from '../../../translate.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-complete-your-data',
  templateUrl: './complete-your-data.component.html',
  styleUrls: ['./complete-your-data.component.styl']
})
export class CompleteYourDataComponent implements OnInit {
  personalDataArray = [];
  aboutYourRoleArray = [];
  aboutYourBusinessArray = [];
  othersArray = [];
  unapprovedTags = [];
  approveRejectArray = [];
  isDataAvailabel = false;
  @Output() showTaggsEditProfile = new EventEmitter<any>();

  constructor(public dataService: DataService, private ts: TranslateService) { }

  ngOnInit() {
    this.getUnapprovedTags();
  }

  review() {
    this.showTaggsEditProfile.emit();
  }

  getUnapprovedTags() {
    let url = 'api/v1/tagging/list_for?source=Coach&model=PathwayV2&unapprovedOnly=true';
    const pathwayId: any = localStorage.getItem('pathways') ? JSON.parse(localStorage.getItem('pathways')) : [];
    if (pathwayId.length > 0) {
      let modelIds = '';
      pathwayId.forEach(obj => {
        modelIds += `&modelIds[]=${obj.id}`;
      });
      url += modelIds;
    }
    this.unapprovedTags = [];
    this.dataService.getTaggingData(url).subscribe(async (res: any) => {
      if (res.models && res.models.length > 0) {
        const data = res.models;
        const arr = [];
        for (const item of data) {
          if (item.tagGroups.length > 0) {
            for (const tag of item.tagGroups) {
              tag.class = '';
              tag.action = 'select';
              tag.taggingBackgroundColor = '#f0f3f2';
              tag.selectedID = (tag.tags && tag.tags.length > 0 && tag.tags[0].tagId > 0) ? tag.tags[0].tagId : 0;
              tag.selectedName = (tag.tags && tag.tags.length > 0 && tag.tags[0].tagId > 0) ? tag.tags[0].locales ?
                tag.tags[0].locales[this.dataService.curLang] ? tag.tags[0].locales[this.dataService.curLang] :
                tag.tags[0].name : tag.tags[0].name : tag.tags[0].name;
              tag.getTagList = {};
              tag.mode = '';
              tag.sourceId = item.sourceId;
              tag.isCustomTagAdded = false;
              tag.getTagList = await this.getTagList2(tag.id);
              tag.getTagList = tag.getTagList['tagGroups'][0];
              tag.isCustomTagAdded = await this.isCustomTagAdded(tag.getTagList.templates, tag.tags[0].name);
              this.unapprovedTags.push(tag);
            }
          }
        }
        this.unapprovedTags = _.shuffle(this.unapprovedTags);
        this.unapprovedTags = this.unapprovedTags.slice(0, 2);
        this.isDataAvailabel = true;
      }
    });
  }

  saveSingleTag(group) {
    if (this.approveRejectArray.length > 0) {
      const arr = [];
      const obj: any = {
        id: group.tags[0].taggingId,
        modelId: group.sourceId,
        modelType: 'PathwayV2',
        tagId: (group.selectedID > 0) ? group.selectedID : '',
        tagName: (group.selectedName !== '') ? group.selectedName : group.tags[0].name,
        status: (group.mode === '') ? 'Approved' : group.mode
      };
      const compList = localStorage.getItem('pathways') ? JSON.parse(localStorage.getItem('pathways')) : [];
      if (compList.length > 0) {
        const findRestrict = compList.find((f) => f.id === obj.modelId);
        if (findRestrict && findRestrict.id) {
          obj.restrictedModelId = findRestrict.companyId;
          obj.restrictedModelName = 'Company';
          arr.push(obj);
        }
      }
      for (const item of arr) {
        if (item.tagName === '' || item.tagName === null) {
          if (item.status !== 'Rejected') {
            this.dataService.emitData('tagging_validation', true);
            return false;
          }
        }
      }
      this.dataService.emitData('tagging_validation', false);
      this.dataService.saveStatus = 'processing';

      const data = {
        source: 'Coach',
        taggings: arr
      };
      const url = 'api/v1/tagging/approve';
      this.dataService.postTaggingData('api/v1/tagging/approve', data).subscribe((res: any) => {
        if (res.models.length > 0) {
          this.dataService.saveStatus = 'saved';
        } else {
          this.dataService.saveStatus = 'error';
        }
      });
    }

  }

  isCustomTagAdded(templates, val) {
    if (!_.includes(_.map(templates, 'name'), val)) {
      return true;
    } else {
      return false;
    }
  }

  getTagList2(id) {
    const url = `api/v1/tag_groups/template_tags?source=Coach&tagGroupId=${id}`;
    return new Promise(resolve => {
      this.dataService.getTaggingData(url)
        .subscribe(response => {
          resolve(response);
        });
    });
  }

  selectedTag2(e, arr, index, tagGroup) {
    if (e.target.value === 'addNew') {
      arr[index].action = 'addNew';
    } else {
      arr[index].action = 'select';
      if (e.target.options[e.target.options.selectedIndex].label === '') {
        return;
      }
      arr[index].selectedID = Number(e.target.value);
      arr[index].selectedName = (e.target.options) ? e.target.options[e.target.options.selectedIndex].label : '';
      arr[index].isCustomTagAdded = false;
      arr[index].tags[0].name = arr[index].selectedName;
      arr[index].tags[0].tagId = Number(e.target.value);
      if (arr[index].tags[0].locales != null && arr[index].tags[0].locales[this.dataService.curLang]) {
        arr[index].tags[0].locales[this.dataService.curLang] = arr[index].selectedName;
      }
      this.approve(tagGroup);
    }
  }

  approve(tagGroup) {
    this.approveRejectArray.push(tagGroup);
    this.saveSingleTag(tagGroup);
  }

  reject(tagGroup) {
    this.approveRejectArray.push(tagGroup);
    this.saveSingleTag(tagGroup);
  }

  confirmDelete(tagGroup) {
    const tagName = (tagGroup.locales !== null && tagGroup.locales[this.dataService.curLang]) ?
      tagGroup.locales[this.dataService.curLang] : tagGroup.name;
    const val = confirm(this.ts.translateMe('are_you_sure') + ': ' + tagName + ' ?');

    if (val) {
      tagGroup.mode = 'Rejected';
      this.reject(tagGroup);
    }
  }

  back(tagGroup, curLang) {
    tagGroup.selectedID = tagGroup.tags[0].tagId;
    tagGroup.selectedName = tagGroup.tags[0].locales !== null ? tagGroup.tags[0].locales[curLang] ?
      tagGroup.tags[0].locales[curLang] : tagGroup.tags[0].name : tagGroup.tags[0].name;
    tagGroup.action = 'select';
    tagGroup.mode = '';
  }
}
