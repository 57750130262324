import { Component, Input, OnInit } from '@angular/core';
import { DataService } from '../../data.service';
import { BFService } from '../bench-feedback.service';
declare var surveyError: any;

@Component({
  selector: 'rating-page',
  templateUrl: './rating.component.html'
})
export class SelfRating implements OnInit {
	activity:any = [];
	behaviours:any = [];
	coachApprovedBehaviours:string = "Pending";
	bList:any = [];
	doChk:any = null;

	@Input() locked;

	constructor(private dataService: DataService, public bfs:BFService) {
		//
	}

	ngOnInit() {
		var self = this;
		this.dataService.popup.bfbkStep = 4;
		this.dataService.saveStatus = null;
		// this.dataService.popup.buttons.disabled = false;

		if(this.dataService.popup && this.dataService.popup.data && this.dataService.popup.data.activity) {
			this.activity = this.dataService.popup.data.activity;
			this.behaviours = this.activity.behaviours;

			if(this.behaviours) {
				this.bList = this.behaviours.list;
				this.coachApprovedBehaviours = this.behaviours.coachApproved;
			}
		}

		let doChk: any = setInterval(function(){
			surveyError().then(retVal => {
				// console.log(retVal.success);
				self.dataService.popup.buttons.disabled = !retVal.success;

				if(retVal.success && !self.dataService.popup.buttons.disabled) {
					clearInterval(doChk);
				}
			});
		}, 1000);
	}

	ngDoCheck() {
		if(this.locked || this.coachApprovedBehaviours != 'Approved') {
			this.dataService.popup.buttons.show1 = false;
			this.dataService.popup.buttons.show2 = false;
		} else {
			this.dataService.popup.buttons.show1 = true;
			this.dataService.popup.buttons.show2 = true;
		}
	}

	ngOnDestroy() {
		clearInterval(this.doChk);
	}
}