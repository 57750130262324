import { Component, HostListener, OnInit } from '@angular/core';
import { DataService } from './data.service';

declare var require;
import cssVars from 'css-vars-ponyfill';
declare var navigator;

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
	@HostListener('window:resize', ['$event'])
	onResize(event) {
		var ori: any = window.orientation || event.target.screen.orientation.angle;
		var winH = event.target.innerHeight;
		var winW = event.target.innerWidth;

		if ((winW <= 680 || winH <= 680) && winW > winH && Math.abs(ori) === 90) {
			this.winOrient = 'landscape';
		} else {
			this.winOrient = 'portrait';
		}
	}

	title: string = 'app works!';
	winOrient: any = 'portrait';

	constructor(private dataService: DataService) {
		//cssVars();
		cssVars({
			onlyLegacy: true
		});
	}

	ngOnInit() {
		if (this.dataService.isApp) {
			document.addEventListener('deviceready', () => {
				if (navigator && navigator.splashscreen) {
					navigator.splashscreen.hide();
				}
			}, false);
		}
	}
}
