import { Component, OnInit, DoCheck, ChangeDetectorRef } from '@angular/core';
import { DataService } from '../../data.service';
import { TLService } from '../timeline-section/timeline.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '../../translate.service';
import { TranslatePipe } from '../../translate.pipe';

const LOAD_NEW_PATHWAY_ON_TAB_CHANGE = 'load-new-pathway-on-tab-change';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'coach-section',
    templateUrl: './coach.component.html'
})
export class CoachComponent implements OnInit, DoCheck {
    coach: any = {
        name: '',
        timezone: '',
        email: '',
        phone1: ''
    };
    coachContact: any = {
        timezone: '',
        email: '',
        phone1: ''
    };
    reminders: any = null;
    documents: Array<any> = [];  // Temp data, cab be deleted later
    isMobileView = false;
    pathwayId: any;
    message: any = null;
    docLoading = false;
    showInfo = false;
    diBox: any = { 'top': 0, 'left': 0 };
    ttpAlign = 'left';
    curCno: number = 1;
    totCno: number = 3;

    constructor(private route: ActivatedRoute, public dataService: DataService, public tls: TLService, private router: Router, private ts: TranslateService, private cd: ChangeDetectorRef) { }

    ngOnInit() {
        const winWidth = window.innerWidth;

        if (winWidth <= 668) {
            this.isMobileView = true;
            this.ttpAlign = 'top';
        }

        this.dataService.getEmitter(LOAD_NEW_PATHWAY_ON_TAB_CHANGE).subscribe((result) => {
            if (result) {
                this.pathwayId = result;
                this.docLoading = true;
                this.getPathwayDocuments();
            }
        });
    }

    leftArrowClick() {
        this.curCno--;
        if(this.curCno<=0) this.curCno=0;
    }

    rightArrowClick() {
        this.curCno++;
        if(this.curCno>this.totCno) this.curCno=this.totCno;
    }

    getPathwayDocuments() {
        if (this.pathwayId) {
            this.dataService.getPathwayDocuments(this.pathwayId).subscribe(result => {
                if(result) {
                    this.documents = result.files.length > 0 ? result.files : [];
                    this.docLoading = false;
                }
            });
        }
    }

    aboutCoach(coachData: any) {
        this.dataService.saveStatus = null;
        this.dataService.popup.title = 'about_coach';    //Title name as per the language json key
        this.dataService.popup.type = 'about-coach';
        this.dataService.popup.data = coachData;
        this.dataService.popup.view = true;
        this.dataService.popup.class = 'bs-popup-size__aboutCoach';
        this.dataService.popup.footer = false;
        this.dataService.popup.buttons.caption1 = ""
        this.dataService.popup.buttons.caption2 = ""
        this.dataService.popup.buttons.show1 = false;
        this.dataService.popup.buttons.show2 = false;
        this.dataService.popup.buttons.align = ""
        this.dataService.popup.buttons.disabled = true;
    }

    uploadDocuments(event) {
        const self = this;

        self.message = {
            'status': 'processing'
        };

        self.dataService.uploadDocuments(self.dataService.coachee, event.target.files[0])
        .subscribe(response => {
            self.getPathwayDocuments();
            self.message = {
                'type': 'success',
                'text': self.ts.translateMe('doc_uploaded'),
                'status': 'saved'
            };
            self.hideDocMessage();
        }, error => {
            if (error.status === 422) {
                self.message = {
                    'type': 'error',
                    'text': self.ts.translateMe('file_not'),
                    'status': 'failed'
                };
            }
            self.hideDocMessage();
        });
    }

    downloadDocuments(docLink) {
        return docLink ? window.open(docLink, '_blank') : false;
    }

    deleteDocument(doc) {
        const self = this;
        const yn = confirm(self.ts.translateMe('quest7'));

        if (!yn) {
            return;
        }

        self.message = {
            'status': 'processing'
        };

        self.dataService.deleteDocument(doc).subscribe(response => {
            self.getPathwayDocuments();
            self.message = {
                'type': 'success',
                'text': self.ts.translateMe('doc_deleted'),
                'status': 'saved'
            };
            self.hideDocMessage();
        }, error => {
            if (error.status === 422) {
                self.message = {
                    'type': 'error',
                    'text': self.ts.translateMe('no_deleted'),
                    'status': 'failed'
                };
            }
            self.hideDocMessage();
        });
    }

    hideDocMessage() {
        const self = this;
        // setTimeout(() => {
            if (self.message) {
                self.message = null;
            }
        // }, 5000);
    }

    /*toggleTips(tf: boolean) {
        const docInfo: any = document.getElementById('docInfo');
        if (!tf) {
            docInfo.style.display = 'none';
            return;
        }

        if (this.diBox.top && this.diBox.left) {
            docInfo.style.top = this.diBox.top;
            docInfo.style.left = this.diBox.left;
            docInfo.style.display = 'block';
        } else {
            const infoIcon: any = document.getElementById('infoIcon');
            const iiBox: any = infoIcon.getBoundingClientRect();

            if (iiBox) {
                docInfo.style.display = 'block';

                this.diBox.top = iiBox.top - docInfo.offsetHeight + 'px';
                const dibLeft = (iiBox.left + iiBox.width / 2) - (docInfo.offsetWidth / 2);

                if (dibLeft + docInfo.offsetWidth + 10 > window.innerWidth) {
                    this.diBox.left = (iiBox.left + iiBox.width / 2) - (docInfo.offsetWidth * 85 / 100) + 'px';
                    docInfo.className = docInfo.className.replace('--tips', '--tips85');
                } else {
                    this.diBox.left = dibLeft + 'px';
                    docInfo.className = docInfo.className.replace('--tips85', '--tips');
                }

                docInfo.style.top = this.diBox.top;
                docInfo.style.left = this.diBox.left;
            }
        }
    }*/

    coachPicSRC(coach) {
        if (coach.photo) {
            return this.dataService.coachServerUrl + this.coach.photo;
        } else {
            return './assets/images/no-image.png';
        }        
    }

    ngDoCheck() {
        if (this.dataService.coach) {
            this.totCno = this.dataService.coach.length;
            // this.coach = this.dataService.coach;
            // this.coachContact.timezone = '<i class="icon globe"></i> ' + this.coach.timezone;
            // this.coachContact.email = '<i class="icon email-01"></i> <a href="mailto:' + this.coach.email + '"> ' + this.coach.email + '</a>';
            // this.coachContact.phone1 = '<i class="icon phone-calling2"></i> ' + (this.coach.phone1 || this.coach.phoneOne);

            /*if (this.coach.photo) {
                this.coach.pic = this.dataService.coachServerUrl + this.coach.photo;
            } else {
                this.coach.pic = './assets/images/no-image.png';
            }*/
        }

        if (!this.pathwayId && this.dataService.pathwayId) {
            this.pathwayId = this.dataService.pathwayId;
            this.getPathwayDocuments();
        }
        this.cd.detectChanges();
        this.reminders = this.dataService.reminders;
        this.cd.detectChanges();
    }
}
