import { Component, Input, OnInit } from '@angular/core';
import { DataService } from '../../data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '../../translate.service';

@Component({
  selector: 'app-sync-calendar',
  templateUrl: './sync-calendar.component.html'
})

export class SyncCalendarComponent implements OnInit {
  isCopied = false;
  errSync: string = null;
  scLinks: any = [];

  constructor(
    private route: ActivatedRoute,
    private dataService: DataService,
    private router: Router,
    private translate: TranslateService
  ) {
    // 
  }

  ngOnInit() {
    this.scLinks = [];

    if (this.dataService.coachee && this.dataService.coachee.calendarSync) {
      this.scLinks.push({'link': this.dataService.coachee.calendarSync});
      // console.log('coachee => ', this.dataService.coachee.calendarSync);
    }
  }

  copyLink() {
    /* Get the text field */
    const copyText: any = document.getElementById('ics_link');

    if (copyText) {
      /* Select the text field */
      copyText.select();
      copyText.setSelectionRange(0, 99999); /*For mobile devices*/

      /* Copy the text inside the text field */
      const status = document.execCommand('copy');

      if (!status) {
        // console.error('Cannot copy text');
        this.isCopied = false;
      } else {
        // console.log('The text is now on the clipboard');
        this.isCopied = true;
        copyText.setSelectionRange(0, 0);
      }
    }
  }

  getLinkText() {
    setTimeout(function() {
      this.isCopied = false;
    }.bind(this), 10000);

    return this.isCopied ? this.translate.translateMe('copied') : this.translate.translateMe('copy_link');
  }

  doSyncCalander(isStart: boolean) {
    this.scLinks = [];

    if (isStart) {
      this.dataService.startSyncCalendar().subscribe((result) => {
        if (result.calendarSyncLink) {
          // console.log(result);
          this.dataService.coachee['calendarSync'] = result.calendarSyncLink || null;
          this.scLinks.push({'link': result.calendarSyncLink});
        }
      },
      error => {
        // console.log('API Error! ', error.statusText);
        this.errSync = error.statusText;
      });
    } else {
      this.dataService.stopSyncCalendar().subscribe((result) => {
        this.dataService.coachee['calendarSync'] = null;
        // console.log(result);
      });
    }
  }
}
