import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from './../data.service';

@Component({
  selector: 'app-slots',
  templateUrl: './slots.component.html'
})
export class SlotsComponent implements OnInit {
  sessionId: number;
  pathwayId: number;

  slots: any = [];

  constructor(private route: ActivatedRoute, private dataService: DataService, private router: Router) { }

  ngOnInit() {
     this.route.params.subscribe(params => {
        this.sessionId = params['sessionId'];
        this.pathwayId = params['pathwayId'];
        this.dataService.getSlots(this.sessionId).subscribe(
          result => {
            if (result.error) {
              // console.log("Error! " + result.error.status + " : " + result.error.statusText );
            } else {
              this.slots = result.slots;
            }
          },
          error => {
            // console.log(error);
          }
        );
     });
  }

  bookSlot(slotId) {
    this.dataService.bookSlot(this.sessionId, slotId).subscribe(
      result => {
        if (result && result.session && result.session.id === this.sessionId && result.session.status === 'Booked') {
          alert('Session with Id ' + this.sessionId + ' & Slot with Id ' + slotId + ' booked.' );
          this.router.navigate(['newPathway', this.pathwayId]);
        }
      },
      error => {
        // console.log(error);
      }
    );
  }

}
