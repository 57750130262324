import { NgModule, ModuleWithProviders, APP_INITIALIZER } from '@angular/core';
import { LoggerService } from '@btsdigital/pulseutilities';
import { VideoCallManagerService } from '@btsdigital/pulsewebrtc';
import { DataService } from './data.service';
import { TLService } from './landing/timeline-section/timeline.service';
import { TranslateService } from './translate.service';
import { BFService } from './bench-feedback/bench-feedback.service';

@NgModule({})
export class ServiceModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: ServiceModule,
      providers: [
        VideoCallManagerService,
        LoggerService,
        DataService,
        TLService,
        TranslateService,
        BFService,
        {
          provide: APP_INITIALIZER,
          useFactory: setupTranslateFactory,
          deps: [ TranslateService ],
          multi: true
        }

      ]
    };
  }
}

export function setupTranslateFactory(
  service: TranslateService): Function {
  return () => service.use('en');
}
