import { Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { TranslateService } from './translate.service';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'translate',
  pure: false
})
export class TranslatePipe implements PipeTransform {
  constructor(private translate: TranslateService) {}

  transform(key: any): any {
    return this.translate.data[key] || key;
  }
}

/*@Pipe({
  name: 'sanitizeHtml'
})
export class SanitizeHtmlPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) { }
  transform(value: any): any {
    return this.sanitizer.bypassSecurityTrustHtml(value);
  }
}*/
